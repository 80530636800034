(function () {
	angular.module('Plania').directive('plKpiWidget', ['TranslationService', function (translationService) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-widget-message', "Modulen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-widget-confirm', 'Ja, fjern modulen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-widget-success', 'Modulen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/dashboard/directives/widgets/kpiWidget.html',
			controller: ['$scope', 'TranslationService', 'IconService', 'Repository', '$modal', '$rootScope', 'ListCompressionService', '$state', safeController],
		};
	}]);

	function safeController($scope, translationService, iconService, repository, modal, $rootScope, listCompressionService, $state) {
		try {
			controller($scope, translationService, iconService, repository, modal, $rootScope, listCompressionService, $state);
		} catch (error) {
			console.log(error);
		}
	}

	function controller($scope, translationService, iconService, repository, modal, $rootScope, listCompressionService, $state) {
		$scope.model = $scope.widget;
		$scope.color = 'black';
		$scope.errorMessage = '';

		$scope.iconMap = iconService.GetIconMap();
		$scope.getIcon = iconService.getIcon;

		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false).then(function () {
					getKpi();
				});
			}, function () {
				//Dismissed
			});
		};

		if (typeof ($scope.model.WidgetData) === "string") {
			$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
		}

		var setColor = function () {
			var widgetData = $scope.model.WidgetData;
			if (widgetData.thresholds && widgetData.thresholds.length > 0 && $scope.model.Value) {
				var colorChanged = false;
				widgetData.thresholds.forEach(function (threshold) {
					var value = $scope.model.Value;
					if (typeof (value) === "string") value = Number(value.replace('%', ''));
					if (value >= threshold.value) {
						$scope.color = threshold.color;
						colorChanged = true;
					}
				});
				if (!colorChanged)
					$scope.color = widgetData.color;
			} else {
				$scope.color = widgetData.color;
			}
		};

		var findApiData = function (widgetData) {
			var apiDataList = _.filter(repository.apiData,
				function (obj) {
					return obj.prefix === widgetData.prefix;
				});

			if (apiDataList.length === 0) return null;

			if (apiDataList.length > 1 && widgetData.SelectedListSetup) {
				var contextId = widgetData.SelectedListSetup.ContextId;
				if (contextId) {
					var apiDataWithContext = _.find(apiDataList, function (obj) { return obj.contextId === contextId; });
					if (apiDataWithContext)
						return apiDataWithContext;
				}
			}

			return apiDataList[0];
		};

		var getKpiNavState = function () {
			var state = null;
			var apiData = findApiData($scope.model.WidgetData);
			if (apiData && apiData.navigationState) {
				var mainState = apiData.navigationState.split('.')[0];
				if (mainState === 'document' && apiData.prefix === 'DocumentXEntity')
					mainState = 'documentXEntity';
				state = mainState + '.list';
			}

			return state;
		};

		function getPropertyFilters() {
			var filters = [];
			// Add raw filters from configuration
			if ($scope.model.WidgetData.columns && $scope.model.WidgetData.columns.length > 0) {
				$scope.model.WidgetData.columns.forEach(function (col) {
					if (col.Filter) {
						filters.push(angular.copy(col.Filter[0]));
					}
				});
			}
			return repository.GetKeywordFilters(filters);
		}

		function getKpiObject() {
			var kpi = {
				Prefix: $scope.model.WidgetData.prefix,
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: getPropertyFilters(),
				Properties: []
			};
			if ($scope.model.WidgetData.interval) {
				var today = new Date();
				today.setDate(today.getDate() - $scope.model.WidgetData.interval.daysBack);
				kpi.FromDate = today.toISOString();
				today = new Date();
				today.setDate(today.getDate() + $scope.model.WidgetData.interval.daysForward);
				kpi.ToDate = today.toISOString();
				kpi.DateProperty = $scope.model.WidgetData.interval.property;
			}
			if ($scope.model.WidgetData.kpiProperty) {
				kpi.Properties = [
					{
						Property: $scope.model.WidgetData.kpiProperty.property,
						Function: $scope.model.WidgetData.kpiProperty.operation,
						Value: $scope.model.WidgetData.kpiProperty.value
					}
				];
			}

			var filter = repository.commonService.getFilterData();
			if (filter.selectedBuilding && filter.selectedBuilding.Guid)
				kpi.GuidBuilding = filter.selectedBuilding.Guid;
			if (filter.selectedEstate && filter.selectedEstate.Guid)
				kpi.GuidEstate = filter.selectedEstate.Guid;
			if (filter.selectedSelection && filter.selectedSelection.Guid)
				kpi.GuidSelection = filter.selectedSelection.Guid;

			var propertyFilter = angular.copy(kpi.PropertyFilter);

			// Use comparisonvalue for percent as filter. This should not filter on the kpi call, but only be used when navigating.
			if ($scope.model.WidgetData.kpiProperty && $scope.model.WidgetData.kpiProperty.operation === 'percent') {
				propertyFilter.push({
					Property: $scope.model.WidgetData.kpiProperty.property,
					Operator: "=",
					Value: $scope.model.WidgetData.kpiProperty.value
				});
			}

			$scope.kpiPrefixParams = listCompressionService.encodeListUri({ filter: { PropertyFilter: propertyFilter }, selectedListSetup: $scope.model.WidgetData.SelectedListSetup });
			$scope.kpiPrefixState = getKpiNavState();

			return kpi;
		}

		var getKpi = function () {
			if ($scope.model.WidgetData.color) $scope.color = $scope.model.WidgetData.color;

			var kpi = getKpiObject();
			$scope.errorMessage = '';
			repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {
				$scope.model.Value = result[0].Result[0].Value;
				setColor();

				var convertedValue = Number($scope.model.Value);

				if (!isNaN(convertedValue)) {
					$scope.model.Value = convertedValue;
				}
			}, function (error) {
				$scope.errorMessage = error;
				$scope.kpiPrefixState = null;
				$scope.color = 'gray';
			});
		};

		$scope.getHref = function () {
			return $state.href($scope.kpiPrefixState, { filter: $scope.kpiPrefixParams });
		};

		if ($scope.model.WidgetData) getKpi();

		$scope.isNumber = function (value) {
			return typeof (value) === "number";
		};

		$scope.$on($rootScope.events.newSelection, function () {
			if ($scope.model.WidgetData) getKpi();
		});
	}
})();
