(function () {
	angular.module('Plania').controller('GlobalFilterController', ['$scope', '$rootScope', 'Repository', 'CommonService', '$localStorage', '$q', controller]);
	function controller($scope, $rootScope, repository, commonService, $localStorage, $q) {
		$scope.dataOwnerInfo = new function () {
			var me = this;

			this.columns = [
				'Id', 'Description', 'Dimension1', 'DefaultDocumentType.Id', 'WoXEqDocumentCategory.Description',
			];

			this.count = 10;
			this.totalCount = 0;
			this.filter = {};
			this.selected = commonService.getFilterData().selectedDataOwner || {};
			this.entities = [];

			this.loadList = function () {
				me.filter.includeAllDataOwners = false;
				var deferred = $q.defer();

				repository.GetPaginated(repository.apiData.dataOwner.url, 0, me.count, { id: "asc" }, me.filter, me.selected.Guid, JSON.stringify(me.columns)).then(function (result) {
					if (result && result.List) {
						me.totalCount = result.TotalCount;
						me.entities = result.List;
					}

					deferred.resolve();
				}, function (error) {
					repository.showError(error);
					deferred.reject();
				});

				return deferred.promise;
			};

			this.init = function () {
				// Use init only on first load - For now we update the stored version i localStorage with updated data from API.
				me.loadList().then(function () {
					if (!me.selected.Guid) return;
					var selected = _.find(me.entities, function (o) { return o.Guid === me.selected.Guid; });
					if (selected) {
						setInternal(selected);
					}
				});
			};

			this.loadMore = function () {
				if (me.count < me.totalCount) {
					me.count += 100;
					me.loadList();
				}
			};

			this.set = function (selected) {
				if (!selected || selected.Guid === me.selected.Guid) return;
				setInternal(selected);

				// BuildingSelection might be dependent on access.
				// Broadcast change of access to sidebarController via updateMenu,
				// and listen to the finished event on updatedAccess.
				var deregister = $scope.$on($rootScope.events.updatedAccess, function () {
					deregister();

					// Change in dataOwner should reload all other GlobalFilter lists to check if they are still available.
					Promise.allSettled([
						$scope.buildingSelectionInfo.onDataOwnerSelect(),
						$scope.estateInfo.onDataOwnerSelect(),
						$scope.buildingInfo.onDataOwnerSelect(),
					]).then(function () {
						$rootScope.$broadcast($rootScope.events.newSelection);
					});
				});
				// Trigger updateMenu, and we should get a response via updatedAccess
				$rootScope.$broadcast($rootScope.events.updateMenu, true);
			};

			this.reloadFromStorage = function () {
				me.selected = commonService.getFilterData().selectedDataOwner || {};
			};

			function setInternal(selected) {
				commonService.setDataOwner(selected);
				me.selected = commonService.getFilterData().selectedDataOwner || {};
			}
		}();

		$scope.buildingSelectionInfo = new function () {
			var me = this;

			this.columns = [
				"Description"
			];

			this.count = 20;
			this.totalCount = 0;
			this.filter = {};
			this.selected = commonService.getFilterData().selectedSelection || {};
			this.entities = [];

			this.canChange = $scope.hasReadAccess(repository.commonService.prefix.BuildingSelection);

			this.loadList = function () {
				var deferred = $q.defer();
				me.canChange = $scope.hasReadAccess(repository.commonService.prefix.BuildingSelection);
				if (!me.canChange) {
					return $.Deferred().reject().promise();
				}

				me.filter.excludeEstate = true;
				me.filter.excludeBuilding = true;

				repository.GetPaginated(repository.apiData.buildingSelection.url, 0, me.count, { description: "asc" }, me.filter, me.selected.Guid, JSON.stringify(me.columns)).then(function (result) {
					if (result && result.List) {
						me.totalCount = result.TotalCount;
						me.entities = result.List;
					}
					deferred.resolve();
				}, function (error) {
					repository.showError(error);
					deferred.reject();
				});

				return deferred.promise;
			};

			this.init = function () {
				// Initialize with stored value 
				if (!me.canChange) {
					if (repository.authService.getUserData().defaultSelection) {
						setInternal({
							Guid: repository.authService.getUserData().defaultSelection,
							Description: repository.authService.getUserData().defaultSelectionDescription
						});
					} else {
						setEmptyInternal();
					}
				}

				// Use init only on first load - For now we update the stored version i localStorage with updated data from API.
				me.loadList().then(function () {
					if (!me.selected.Guid) return;
					var selected = _.find(me.entities, function (o) { return o.Guid === me.selected.Guid; });
					if (selected) {
						setInternal(selected);
					}
				});
			};

			this.onDataOwnerSelect = function () {
				var deferred = $q.defer();
				// Changing DataOwner requires to refresh list and check if the value is still available.
				me.loadList().then(function () {
					try {
						if (!me.selected.Guid) return;
						var selected = _.find(me.entities, function (o) { return o.Guid === me.selected.Guid; });
						if (!selected) {
							setEmptyInternal();
						}
					} finally {
						deferred.resolve();
					}
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.loadMore = function () {
				if (me.count < me.totalCount) {
					me.count += 100;
					me.loadList();
				}
			};

			this.set = function (selected) {
				if (!selected || selected.Guid === me.selected.Guid) return;
				setInternal(selected);

				Promise.allSettled([
					$scope.estateInfo.onBuildingSelectionSelect(),
					$scope.buildingInfo.onBuildingSelectionSelect(),
				]).then(function () {
					$rootScope.$broadcast($rootScope.events.newSelection);
				});
			};

			this.setEmpty = function () {
				if (!me.selected.Guid) return;
				if (me.filter.searchString) {
					me.filter.searchString = '';
					me.loadList();
				}
				setEmptyInternal();
				$scope.estateInfo.onBuildingSelectionSelectEmpty();
				$scope.buildingInfo.onBuildingSelectionSelectEmpty();
				$rootScope.$broadcast($rootScope.events.newSelection);
			};

			this.reloadFromStorage = function () {
				me.selected = commonService.getFilterData().selectedSelection || {};
			};

			function setInternal(selected) {
				commonService.setSelectedSelection(selected);
				me.selected = commonService.getFilterData().selectedSelection || {};
			}

			function setEmptyInternal() {
				commonService.setEmptySelection();
				me.selected = commonService.getFilterData().selectedSelection || {};
			}
		}();

		$scope.estateInfo = new function () {
			var me = this;

			this.columns = [
				'Id', 'Description'
			];

			this.count = 30;
			this.totalCount = 0;
			this.filter = {};
			this.selected = commonService.getFilterData().selectedEstate || {};
			this.entities = [];

			this.active = !!$localStorage.generalOptions.WebEstateHeaderSelection;

			this.loadList = function () {
				var deferred = $q.defer();

				me.active = !!$localStorage.generalOptions.WebEstateHeaderSelection;
				if (!me.active) {
					return $.Deferred().reject().promise();
				}

				me.filter.LimitEstatesOnBuildingSelection = $scope.buildingSelectionInfo.selected;
				me.filter.excludeEstate = true;
				me.filter.excludeBuilding = true;

				repository.GetPaginated(repository.apiData.estate.url, 0, me.count, { id: "asc" }, me.filter, me.selected.Guid, JSON.stringify(me.columns)).then(function (result) {
					if (result && result.List) {
						me.totalCount = result.TotalCount;
						me.entities = result.List;
					}
					deferred.resolve();
				}, function (error) {
					repository.showError(error);
				});

				return deferred.promise;
			};

			this.init = function () {
				// Use init only on first load - For now we update the stored version i localStorage with updated data from API.
				me.loadList().then(function () {
					if (!me.selected.Guid) return;
					var selected = _.find(me.entities, function (o) { return o.Guid === me.selected.Guid; });
					if (selected) {
						setInternal(selected);
					}
				});
			};

			this.onDataOwnerSelect = function () {
				var deferred = $q.defer();
				// Changing DataOwner requires to refresh list and check if the value is still available.
				me.loadList().then(function () {
					try {
						if (!me.selected.Guid) return;
						var selected = _.find(me.entities, function (o) { return o.Guid === me.selected.Guid; });
						if (!selected) {
							setEmptyInternal();
						}
					} finally {
						deferred.resolve();
					}
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.onBuildingSelectionSelect = function () {
				var deferred = $q.defer();
				// Changing BuildingSelection requires to refresh list and show building if it is only one selected.
				setEmptyInternal();

				me.loadList().then(function () {
					try {
						if (me.entities.length === 1) {
							setInternal(me.entities[0]);
						}
					} finally {
						deferred.resolve();
					}
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.onBuildingSelectionSelectEmpty = function () {
				var deferred = $q.defer();
				setEmptyInternal();
				me.loadList().then(function () {
					deferred.resolve();
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.loadMore = function () {
				if (me.count < me.totalCount) {
					me.count += 100;
					me.loadList();
				}
			};

			this.set = function (selected) {
				if (!selected || selected.Guid === me.selected.Guid) return;
				setInternal(selected);

				$scope.buildingInfo.onEstateSelect().then(function () {
					$rootScope.$broadcast($rootScope.events.newSelection);
				});
			};

			this.setEmpty = function () {
				if (!me.selected.Guid) return;
				if (me.filter.dropdown) {
					me.filter.dropdown = '';
					me.loadList();
				}
				setEmptyInternal();

				$scope.buildingInfo.onEstateSelectEmpty();
				$rootScope.$broadcast($rootScope.events.newSelection);
			};

			this.reloadFromStorage = function () {
				me.selected = commonService.getFilterData().selectedEstate || {};
			};

			function setInternal(selected) {
				commonService.setEstate(selected);
				me.selected = commonService.getFilterData().selectedEstate || {};
			}

			function setEmptyInternal() {
				commonService.setEmptyEstate();
				me.selected = commonService.getFilterData().selectedEstate || {};
			}
		}();

		$scope.buildingInfo = new function () {
			var me = this;

			this.columns = [
				'Id', 'Description', 'Estate.Guid', 'Estate.Id', 'Estate.Description',
			];

			this.count = 30;
			this.totalCount = 0;
			this.filter = {};
			this.selected = commonService.getFilterData().selectedBuilding || {};
			this.entities = [];

			// LoadList should be as simple as possible for fetching data - wrap this in another function to extend functionalities.
			this.loadList = function (init) {
				var deferred = $q.defer();

				me.filter.PropertyFilter = [
					{ Property: "IsTemplate", Operator: "=", Value: false }
				];
				me.filter.excludeBuilding = true;

				repository.GetPaginated(repository.apiData.building.url, 0, me.count, { id: "asc" }, me.filter, me.selected.Guid, JSON.stringify(me.columns)).then(function (result) {
					if (result && result.List) {
						me.totalCount = result.TotalCount;
						me.entities = result.List;
					}
					deferred.resolve();
				}, function (error) {
					repository.showError(error);
				});

				return deferred.promise;
			};

			this.init = function () {
				// Use init only on first load - For now we update the stored version i localStorage with updated data from API.
				me.loadList().then(function () {
					if (!me.selected.Guid) return;
					var selected = _.find(me.entities, function (o) { return o.Guid === me.selected.Guid; });
					if (selected) {
						setInternal(selected);
					}
				});
			};

			this.onDataOwnerSelect = function () {
				var deferred = $q.defer();
				// Changing DataOwner requires to refresh list and check if the value is still available.
				me.loadList().then(function () {
					try {
						if (!me.selected.Guid) return;
						var selected = _.find(me.entities, function (o) { return o.Guid === me.selected.Guid; });
						if (selected) {
							setInternal(selected);
						} else {
							setEmptyInternal();
						}
					} finally {
						deferred.resolve();
					}
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.onBuildingSelectionSelect = function () {
				var deferred = $q.defer();
				// Changing BuildingSelection requires to refresh list and show building if it is only one selected.
				setEmptyInternal();

				me.loadList().then(function () {
					try {
						if (me.entities.length === 1) {
							setInternal(me.entities[0]);
						}
					} finally {
						deferred.resolve();
					}
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.onBuildingSelectionSelectEmpty = function () {
				var deferred = $q.defer();
				setEmptyInternal();
				me.loadList().then(function () {
					deferred.resolve();
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.onEstateSelect = function () {
				var deferred = $q.defer();
				// Changing BuildingSelection requires to refresh list and show building if it is only one selected.
				setEmptyInternal();

				me.loadList().then(function () {
					try {
						var isSVV = $localStorage.generalOptions.CustomerId === 'SvvTunnel' || $localStorage.generalOptions.CustomerId === 'Fylkeskommuner';
						if (!isSVV && me.entities.length === 1) {
							setInternal(me.entities[0]);
						}
					} finally {
						deferred.resolve();
					}
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.onEstateSelectEmpty = function () {
				var deferred = $q.defer();
				setEmptyInternal();
				me.loadList().then(function () {
					deferred.resolve();
				}, function () {
					deferred.reject();
				});
				return deferred.promise;
			};

			this.loadMore = function () {
				if (me.count < me.totalCount) {
					me.count += 100;
					me.loadList();
				}
			};

			this.set = function (selected) {
				if (!selected || selected.Guid === me.selected.Guid) return;
				setInternal(selected);
				$rootScope.$broadcast($rootScope.events.newSelection);
			};

			this.setEmpty = function () {
				if (!me.selected.Guid) return;
				if (me.filter.dropdown) {
					me.filter.dropdown = '';
					me.loadList();
				}

				setEmptyInternal();
				$rootScope.$broadcast($rootScope.events.newSelection);
			};

			this.reloadFromStorage = function () {
				me.selected = commonService.getFilterData().selectedBuilding || {};
			};

			function setInternal(selected) {
				commonService.setBuilding(selected);
				me.selected = commonService.getFilterData().selectedBuilding || {};
			}

			function setEmptyInternal() {
				commonService.setEmptyBuilding();
				me.selected = commonService.getFilterData().selectedBuilding || {};
			}
		}();

		$scope.dataOwnerInfo.init();
		$scope.buildingSelectionInfo.init();
		$scope.estateInfo.init();
		$scope.buildingInfo.init();

		$scope.$on($scope.events.newSelection, function () {
			// Since we have two areas that use this controller - update selected when newSelection has happened.
			$scope.dataOwnerInfo.reloadFromStorage();
			$scope.buildingSelectionInfo.reloadFromStorage();
			$scope.estateInfo.reloadFromStorage();
			$scope.buildingInfo.reloadFromStorage();
		});
	}
})();
