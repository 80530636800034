(function () {
	var app = angular.module('Plania');
	app.directive('controlListSetupDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab',
				selectedFormItem: '=selectedFormItem',
				copyOfSelectedItem: '=copyOfSelectedItem',
				saveCurrentItem: '=saveCurrentItem'
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$modal', '$rootScope', '$q', 'ScrollService', controller],
			templateUrl: 'app/controlList/views/editControlListViews/controlListSetup.html',
		};
	});

	function controller($scope, repository, translationService, $modal, $rootScope, $q, scrollService) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;

		$scope.isInvalidRule = function (rule) {
			return !rule.IsValid;
		};

		var scrollToItem = function (item) {
			if (!item) return;
			scrollService.scrollToElement(item.IndexPosition);
		};

		var defaultRadioOptions = [
			{ Value: translationService.translate('web-controllist-answer-Yes', 'Ja') },
			{ Value: translationService.translate('web-controllist-answer-No', 'Nei') },
			{ Value: translationService.translate('web-controllist-answer-NotApplicable', 'Ikke aktuelt') }
		];

		var defaultChecklistOptions = [
			{ Value: translationService.translate('web-controllist-answer-Ok', 'Ok') },
			{ Value: translationService.translate('web-controllist-answer-NotOk', 'Ikke Ok') },
			{ Value: translationService.translate('web-controllist-answer-NotApplicable', 'Ikke aktuelt') }
		];

		$scope.fieldTypeItems = [];

		var setFieldTypeItems = function () {
			var fieldTypeItems = [
				{
					Guid: null,
					Name: translationService.translate('web-enum-controllistitemtype-SectionHeader', 'Seksjonstittel'),
					Type: 'SectionHeader',
					icon: 'font',
					disabled: !$scope.hasModule('ControlList')
				},
				{
					Guid: null,
					Name: translationService.translate('web-enum-controllistitemtype-Info', 'Beskrivelse'),
					Type: 'Info',
					icon: 'info',
					Data: { Description: 'Informasjonstekst' },
					disabled: !$scope.hasModule('ControlList')
				}
			];

			if ($scope.hasModule('Deviation')) {
				fieldTypeItems.push(
					{
						Guid: null,
						Name: translationService.translate('web-enum-controllistitemtype-Checklist', 'Sjekklistepunkt'),
						Type: 'Checklist',
						icon: 'list-ul',
						disabled: false,
						IsMandatory: !$scope.hasModule('ControlList'),
						Data: { Options: defaultChecklistOptions }
					}
				);
			}

			fieldTypeItems.push(
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-RadioButton', 'Radioknapper'), Type: 'RadioButton', Data: { Options: defaultRadioOptions }, icon: 'dot-circle', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Combo', 'Nedtrekksfelt'), Type: 'Combo', Data: { Options: defaultRadioOptions }, icon: 'circle-chevron-down', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Text', 'Kort svar'), Type: 'Text', icon: 'text', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-TextArea', 'Utfyllende svar'), Type: 'TextArea', icon: 'text', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Numeric', 'Nummer'), Type: 'Numeric', icon: 'input-numeric', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-CheckBox', 'Sjekkboks'), Type: 'CheckBox', icon: 'square-check', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Date', 'Dato'), Type: 'Date', icon: 'calendar', disabled: !$scope.hasModule('ControlList') }
			);

			$scope.fieldTypeItems = fieldTypeItems;
		};

		setFieldTypeItems();

		$scope.setSelectedFormItem = function (item) {
			$scope.saveCurrentItem();
			$scope.selectedFormItem = item;
			$scope.reloadFormElements = true;
			$scope.copyOfSelectedItem = angular.copy($scope.selectedFormItem);
		};

		$scope.setSelectedFormItemFromGuid = function (guidControlListItem) {
			$scope.saveCurrentItem();
			$scope.selectedFormItem = _.find($scope.model.ControlListItems, function (o) {
				return o.Guid.toLowerCase() === guidControlListItem.toLowerCase();
			});
			scrollToItem($scope.selectedFormItem);
			$scope.copyOfSelectedItem = angular.copy($scope.selectedFormItem);
		};

		$scope.isRelatedToSelectedItem = function (item) {
			if (!$scope.selectedFormItem || item.Guid === $scope.selectedFormItem.Guid) return false;

			var itemRules = _.filter($scope.model.ControlListRules, { GuidControlListItem: item.Guid });
			var selectedItemRules = _.filter($scope.model.ControlListRules, { GuidControlListItem: $scope.selectedFormItem.Guid });

			if (!selectedItemRules && !itemRules) return false;

			var result = false;

			itemRules.forEach(function (rule) {
				if (_.filter(rule.Conditions.Filter, { GuidControlListItem: $scope.selectedFormItem.Guid }).length > 0) result = true;
			});

			selectedItemRules.forEach(function (rule) {
				if (_.filter(rule.Conditions.Filter, { GuidControlListItem: item.Guid }).length > 0) result = true;
			});

			return result;
		};

		$scope.getControlListItem = function (guidControlListItem) {
			if (!guidControlListItem) return;

			var controlListItem = _.find($scope.model.ControlListItems, function (o) {
				if (!o.Guid) return false;
				return o.Guid.toLowerCase() === guidControlListItem.toLowerCase();
			});

			if (!controlListItem) return;

			return controlListItem;
		};

		$scope.newControlListLogItemHandler = {
			templateUrl: 'app/controlList/views/editControlListLogItemModal.html',
		};

		$scope.treeOptions = {
			accept: function (sourceNodeScope, destNodeScope, destIndex) {
				return $scope.model.VersionStatus === 'Draft';
			},
			dropped: function (event) {
				if (event.dest.nodesScope.$parent.nodropEnabled) return;

				if (event.source.cloneModel && !event.source.cloneModel.Guid) {
					$scope.setSelectedFormItem(event.source.cloneModel);
					var postData = angular.copy(event.source.cloneModel);

					postData.IndexPosition = event.dest.index;
					postData.GuidControlList = $scope.model.Guid;
					delete postData.icon;
					delete postData.Guid;

					if (postData.Data) postData.Data = JSON.stringify(postData.Data);
					createControlListItem(postData).then(function (response) {
						if (response.Data && typeof (response.Data) === "string") response.Data = JSON.parse(response.Data);
						$scope.model.ControlListItems[event.dest.index] = response;
						$scope.selectedFormItem = $scope.model.ControlListItems[event.dest.index];
						repository.growl('Kontrollpunktet ble lagt til', 'success');
						updateIndexPositions(event.dest.index, $scope.model.ControlListItems.length - 1);
					}, function (error) {
					});
				} else {
					var draggedDown = event.source.index > event.dest.index;
					var startIndex = draggedDown ? event.dest.index : event.source.index;
					var endIndex = draggedDown ? event.source.index : event.dest.index;

					setTimeout(function () {
						updateIndexPositions(startIndex, endIndex);
					}, 0);
				}
			}
		};

		var createControlListItem = function (postData) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			var deferred = $q.defer();
			repository.createSingle(repository.apiData.controlListItem.url, postData).then(function (response) {
				deferred.resolve(response);
			}, function (error) {
				deferred.reject(error);
			});
			return deferred.promise;
		};

		$scope.addControlListItem = function (selectedType) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			var postData = angular.copy(selectedType);
			postData.IndexPosition = $scope.model.ControlListItems.length === 0 ? 0 : $scope.model.ControlListItems[$scope.model.ControlListItems.length - 1].IndexPosition + 1;
			postData.GuidControlList = $scope.model.Guid;
			postData.isSaving = true;
			$scope.model.ControlListItems.push(postData);

			delete postData.icon;
			delete postData.Guid;

			if (postData.Data) postData.Data = JSON.stringify(postData.Data);

			createControlListItem(postData).then(function (response) {
				if (response.Data && typeof (response.Data) === "string") response.Data = JSON.parse(response.Data);
				var index = _.findIndex($scope.model.ControlListItems, { IndexPosition: response.IndexPosition });
				response.isSaving = false;
				$scope.model.ControlListItems[index] = response;
				$scope.setSelectedFormItem($scope.model.ControlListItems[index]);
				scrollToItem($scope.selectedFormItem);
			}, function (error) {
				swal('Error', error.Message, "error");
			});
		};

		$scope.copyFormItem = function (item, index) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			var copiedItem = angular.copy(item);
			copiedItem.IndexPosition = item.IndexPosition + 1;
			delete copiedItem.Guid;

			if (copiedItem.Data) copiedItem.Data = JSON.stringify(copiedItem.Data);
			repository.createSingle(repository.apiData.controlListItem.url, copiedItem).then(function (response) {
				if (response.Data && typeof (response.Data) === "string")
					response.Data = JSON.parse(response.Data);
				$scope.model.ControlListItems.splice(index + 1, 0, response);
				$scope.selectedFormItem = $scope.model.ControlListItems[index + 1];
				updateIndexPositions(index + 1, $scope.model.ControlListItems.length - 1);
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.removeFormItem = function (item) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlListItem-message', "Kontrollpunktet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlListItem-button-confirm', 'Ja, fjern kontrollpunktet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;
				repository.deleteSingle(repository.apiData.controlListItem.url, item.Guid).then(function (response) {
					swal.close();
					repository.growl('Kontrollpunktet har blitt fjernet', 'success');

					if (item === $scope.selectedFormItem)
						$scope.selectedFormItem = null;

					var foundInFormItems = _.find($scope.model.ControlListItems, item);
					if (foundInFormItems) {
						var index = _.findIndex($scope.model.ControlListItems, foundInFormItems);
						$scope.model.ControlListItems.splice(index, 1);
						updateIndexPositions(index, $scope.model.ControlListItems.length - 1);
					}
					else {
						$scope.model.ControlListItems.forEach(function (formItem) {
							if (formItem.items) {
								if (_.find(formItem.items, item)) {
									formItem.items.splice(_.findIndex(formItem.items, item), 1);
								}
							}
						});
					}

					var affectedRules = _.filter($scope.model.ControlListRules, function (o) { return o.GuidControlListItem === item.Guid; });

					if (affectedRules.length > 0) {
						affectedRules.forEach(function (rule) {
							var index = _.findIndex($scope.model.ControlListRules, { Guid: rule.Guid });
							$scope.model.ControlListRules.splice(index, 1);
						});
					}
				}, function (error) {
					swal('Error', error.Message, "error");
				});
			});
		};

		var updateIndexPositions = function (startIndex, endIndex) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			var objectsToUpdate = [];

			for (var i = startIndex; i <= endIndex; i++) {
				var item = $scope.model.ControlListItems[i];
				objectsToUpdate.push({
					Guid: item.Guid,
					IndexPosition: i
				});
			}

			repository.patch(repository.apiData.controlListItem.url, null, objectsToUpdate);
		};

		//Add new item to array for form items that have several answer options
		$scope.addOption = function (index) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			if ($scope.selectedFormItem.Data.Options) {
				$scope.selectedFormItem.Data.Options.splice(index + 1, 0, { value: '' });
			}
		};

		$scope.removeOption = function (index) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			if ($scope.selectedFormItem.Data.Options) {
				if ($scope.selectedFormItem.Data.Options.length === 1) {
					swal('Du kan ikke slette siste alternativ', '', 'error');
				} else {
					$scope.selectedFormItem.Data.Options.splice(index, 1);
				}
			}
		};

		var reloadControlListRules = function () {
			if ($scope.model.VersionStatus !== 'Draft') return;

			repository.GetPaginated(repository.apiData.controlListRule.url, 0, -1, {}, { PropertyFilter: [{ Property: 'GuidControlList', Operator: '=', Value: $scope.model.Guid }] }, '').then(function (response) {
				$scope.model.ControlListRules = response.List;
				$scope.model.ControlListRules.forEach(function (item) {
					if (item.Conditions) item.Conditions = JSON.parse(item.Conditions);
					if (item.Parameters) item.Parameters = JSON.parse(item.Parameters);
				});
			});
		};

		$scope.addRule = function (selectedFormItem) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			$modal.open({
				templateUrl: 'app/controlList/views/ruleModal.html',
				size: 'lg',
				controller: 'RuleModalController',
				resolve: {
					params: function () {
						return {
							selectedFormItem: selectedFormItem,
							controlListItems: $scope.model.ControlListItems
						};
					}
				}
			}).result.then(function (newRule) {
				newRule.GuidControlList = $scope.model.Guid;
				createNewRule(newRule);
			});
		};

		$scope.editRule = function (rule, selectedFormItem) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			$modal.open({
				templateUrl: 'app/controlList/views/ruleModal.html',
				size: 'lg',
				controller: 'RuleModalController',
				resolve: {
					params: function () {
						return {
							selectedFormItem: selectedFormItem,
							controlListItems: $scope.model.ControlListItems,
							rule: rule
						};
					}
				}
			}).result.then(function (editedRule) {
				var postData = angular.copy(editedRule);
				postData.Conditions = JSON.stringify(postData.Conditions);
				postData.Parameters = JSON.stringify(postData.Parameters);

				repository.updateSingle(repository.apiData.controlListRule.url, postData).then(function (response) {
					if (response.Conditions) response.Conditions = JSON.parse(response.Conditions);
					if (response.Parameters) response.Parameters = JSON.parse(response.Parameters);

					editedRule = response;
					reloadControlListRules();
				}, function (error) {
					swal('Error', error.Message, 'error');
				});
			});
		};

		var createNewRule = function (newRule) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			if (typeof (newRule.Conditions) === 'object') newRule.Conditions = angular.toJson(newRule.Conditions);
			if (typeof (newRule.Parameters) === 'object') newRule.Parameters = angular.toJson(newRule.Parameters);

			repository.createSingle(repository.apiData.controlListRule.url, newRule).then(function (response) {
				if (!$scope.model.ControlListRules) $scope.model.ControlListRules = [];

				if (typeof (response.Conditions) === 'string') response.Conditions = JSON.parse(response.Conditions);
				if (typeof (response.Parameters) === 'string') response.Parameters = JSON.parse(response.Parameters);

				$scope.model.ControlListRules.push(response);
			}, function (error) {
				swal('Error', error, 'error');
			});
		};

		$scope.removeRule = function (rule, index) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlListRule-message', "Regelen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlListRule-button-confirm', 'Ja, fjern regelen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.controlListRule.url, rule.Guid).then(function (result) {
					repository.growl(translationService.translate('web-swal-controlListRule-success', 'Regelen ble fjernet!'), 'success');

					if (!index) index = _.findIndex($scope.model.ControlListRules, { Guid: rule.Guid });

					$scope.model.ControlListRules.splice(index, 1);
				}, function (error) {
					swal('Error', error.Message, 'error');
				});
			});
		};

		$scope.controlListRuleContextMenuOptions = function (item, index) {
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-controlList-setupTab-controlListRuleContextMenuOptions-editRule', 'Rediger regel') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.editRule(item);
				}
			});

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-controlList-setupTab-controlListRuleContextMenuOptions-removeRule', 'Slett regel') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.removeRule(item, index);
				}
			});

			return options;
		};

		$scope.$watch('selectedFormItem', function (newVal, oldVal) {
			if (newVal === oldVal) return;

			$scope.activateAutocomplete = false;
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		});

		$scope.$watchCollection('model.ControlListItems', function (newVal, oldVal) {
			if (newVal === oldVal || !newVal) return;
			$scope.checkListItems = _.filter(newVal, function (item) { return item.Type === 'Checklist'; });
		});
	}
})();
