(function () {
	angular.module('Plania').controller('ListSetupModalController', ['$scope', '$modalInstance', '$modal', 'params', 'Repository', 'TranslationService', '$localStorage', controller]);

	function controller($scope, $modalInstance, $modal, params, repository, translationService, $localStorage) {
		$scope.model = params.listSetup;
		$scope.hideFilters = params.hideFilters;
		$scope.keywords = repository.filterKeyWords;
		$scope.user = repository.authService.getUserData();

		$scope.advancedWebListFiltering = $localStorage.generalOptions.AdvancedWebListFiltering;

		var originalColumnSetup = params.listSetup.Columns.slice();
		var originalObject = _.clone(params.listSetup);

		$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebListView, false);
		$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebListView, false);

		$scope.sortingParams = [
			{ name: 'Stigende', value: 'asc' },
			{ name: 'Synkende', value: 'desc' }
		];

		for (var i in $scope.model.Sorting) {
			$scope.sortingProperty = i;
			$scope.sortingParam = $scope.model.Sorting[i];
		}

		$scope.addFilter = function (column) {
			if (!column.Filter) {
				column.Filter = [{ Operator: '=', Property: column.Property }];
			} else {
				column.Filter.push({ Operator: '=', Property: column.Property });
			}
		};

		$scope.addNewColumn = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () {
						return {
							prefix: $scope.model.EntityName || $scope.model.EntityType,
							existingColumns: $scope.model.Columns,
							showAdvancedMode: $scope.advancedWebListFiltering
						};
					}
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				selectedColumns.forEach(function (column) {
					$scope.model.Columns.push(column);
				});
			});
		};

		//ADD properties that should not be selectable in sorting dropdown
		$scope.filterSortingColumns = function () {
			return function (column) {
				if (column.Property === 'CleaningEstimateYear' || column.Property === 'EstimatedTimeYear' || column.Property === 'NextCleaning') {
					return false;
				}

				return true;
			};
		};

        $scope.removeColumn = function (index) {
            var setNewSorting = false;
            if ($scope.model.Columns[index].Property === $scope.sortingProperty) setNewSorting = true;

			$scope.model.Columns.splice(index, 1);

			if (setNewSorting) $scope.sortingProperty = $scope.model.Columns[0].Property;
		};

		var handleDateFilter = function (filter) {
			if (filter.Property.indexOf("Date") >= 0 && filter.Value && Object.prototype.toString.call(filter.Value) === '[object Date]')
				filter.Value = new Date(filter.Value).toISOString();
		};

		var handleEnumFilter = function (filter) {
			if (Array.isArray($scope.model.Columns)) {
				$scope.model.Columns.forEach(function (col) {
					if (col.Property && col.PropertyType && col.PropertyType.startsWith('enum.')) {
						if (col.Property === filter.Property) {
							if (filter.Operator === "in" || filter.Operator === "notin") {
								if (!Array.isArray(filter.Value)) {
									filter.Value = [];
								}
							} else {
								if (Array.isArray(filter.Value)) {
									filter.Value = filter.Value[0];
								}
							}
						}
					}
				});
			}
		};

		var setColumnPositionOnModel = function (model) {
			for (var i = 0; i < model.Columns.length; i++) {
				var filter = model.Columns[i].Filter;
				if (filter) {
					filter.forEach(handleDateFilter);
					filter.forEach(handleEnumFilter);
					model.Columns[i].Filter = JSON.stringify(filter);
				}

				model.Columns[i].Position = i + 1;
			}
		};

		var setSorting = function (doStringify, model) {
			model.Sorting = {};
			model.Sorting[$scope.sortingProperty] = $scope.sortingParam;
			if (doStringify) {
				model.Sorting = JSON.stringify(model.Sorting);
			}
		};

		var getModelForSaving = function () {
			var copy = _.cloneDeep($scope.model);
			setSorting(true, copy);

			setColumnPositionOnModel(copy);

			// For now we add the correct DTO model for columns directly to the reference, since the whole controller (and outside caller) expects that the direct reference is modified.
			// Should later change to this does not modify the direct reference from params.
			copy.WebColumnsToSynchronize = copy.Columns;
			return copy;
		};

		var applyChangesToBindedModel = function (changes) {
			if (!changes) return;
			Object.keys(changes).forEach(function(key) {
				$scope.model[key] = changes[key];
			});
		};

		$scope.ok = function () {
			setSorting(false, $scope.model);
			for (var i = 0; i < $scope.model.Columns.length; i++) {
				var filter = $scope.model.Columns[i].Filter;
				if (filter) {
					filter.forEach(handleDateFilter);
					filter.forEach(handleEnumFilter);
					$scope.model.Columns[i].Filter = filter;
				}
			}
			$modalInstance.close('ok');
		};

		$scope.cancel = function () {
			$scope.model.Columns = originalColumnSetup;
			$scope.model.Id = originalObject.Id;
			$scope.model.IsPublicView = originalObject.IsPublicView;
			$scope.model.IsDefaultView = originalObject.IsDefaultView;
			$modalInstance.dismiss('canceled');
		};

		$scope.save = function () {
			var copy = getModelForSaving();

			repository.updateSingle(repository.apiData.webListView.url, copy).then(function (result) {
				repository.growl('Listeoppsettet har blitt lagret', 'success');
				delete copy.WebColumnsToSynchronize;
				copy.Columns.forEach(function (col) {
					if (col.Filter)
						col.Filter = JSON.parse(col.Filter);
				});
				copy.Sorting = JSON.parse(copy.Sorting);
				applyChangesToBindedModel(copy);
				$modalInstance.close('saved');
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.saveAs = function () {
			var text = '';
			if (!$scope.user.isSystemAdministrator) {
				text = '<span class="text-info"><i class="fa fa-circle-info-circle"></i> Listeoppsettet blir lagret som ett privat listeoppsett.</span>';
			}

			swal({
				title: translationService.translate('listSetup-swal-saveas-title', 'Lagre som...'),
				type: "input",
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				text: text,
				html: true,
				showCancelButton: true,
				closeOnConfirm: false,
				animation: "slide-from-top",
				inputValue: $scope.model.Id,
				inputPlaceholder: translationService.translate('listSetup-label-name', 'Navn')
			}, function (inputValue) {
				if (inputValue === false)
					return false;
				if (inputValue === "") {
					swal.showInputError("Du må skrive inn et navn for å lagre");
					return false;
				}
				if (inputValue.toLowerCase() === 'plania standard') {
					swal.showInputError(translationService.translate('listsetup-saveas-changename', 'Du må velge et annet navn enn Plania standard for å få lagret listen.'));
					return false;
				}

				$scope.model.Id = inputValue;
				if (!$scope.user.isSystemAdministrator) {
					$scope.model.IsPublicView = false;
				}
				$scope.model.EntityType = $scope.model.EntityName || $scope.model.EntityType;
				var copy = getModelForSaving();

				repository.createSingle(repository.apiData.webListView.url, copy).then(function (result) {
					swal("Success!", translationService.translate('listsetup-saveas-success', 'Listeoppsettet ble opprettet'), "success");
					copy.Guid = result.Guid;
					delete copy.WebColumnsToSynchronize;
					copy.Columns.forEach(function (col) {
						if (col.Filter)
							col.Filter = JSON.parse(col.Filter);
					});

					copy.Sorting = JSON.parse(copy.Sorting);

					var original = originalObject;
					original.Columns = originalColumnSetup;
					applyChangesToBindedModel(copy);
					$modalInstance.close('savedNew');
				}, function (error) {
					repository.showError(error);
				});

			});
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-listsetup-message', 'Listeoppsettet vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-listsetup-button-confirm', 'Ja, slett listeoppsettet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.webListView.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-listsetup-success', 'Listeoppsettet ble fjernet!'), result, "success");
					$modalInstance.close('deleted');
				});
			});

		};

		$scope.toggleHide = function (column) {
			if (column.Filter) {
				column.Filter[0].Hidden = !column.Filter[0].Hidden;
			}
		};
	}
})();
