(function () {
	// Custom select to allow keeping of deprecated values.
	// This will also convert between an internal value and external value (Ex multiple: ExternalValue is a string, InternalValue is an array)
	angular.module('Plania').directive('dynamicPropertySelect', [function () {
		return {
			restrict: 'E',
			require: '^dynamicProperties',
			scope: {
				ngModel: '=',
				ngDisabled: '=',
				item: '=',
				multiple: '='
			},
			compile: function compile(element, attrs) {
				// chosen does not read the value inside multiple attribute, so we can write multiple="false", and it will still be multiple="true".
				// Fix by moving into the compile step and manually adding the attribute.
				var select = element.find('select');
				if (select) {
					if (attrs.multiple) {
						select.attr('multiple', 'true');
					}
				}
			},
			controller: ['$scope', 'TranslationService', controller],
			template: '<select chosen data-placeholder="Velg fra listen..." class="form-control overflow-visible" disable-search="true" ng-model="selected" ng-disabled="ngDisabled" ng-options="item.value as item.name for item in values" ng-change="modelValueChanged()"><option id="empty-{{item.PropertyName}}" ng-if="!multiple && !item.Mandatory" value="" pl-translate="web-dynamic-property-combo-empty">Ikke valgt</option></select>'
		};
	}]);

	function controller($scope, translationService) {
		// Keep local value just in case multiple is changed in runtime.
		var multiple = !!$scope.multiple;

		$scope.values = [];
		$scope.selected = multiple ? [] : "";

		$scope.modelValueChanged = function () {
			if (multiple) {
				if (Array.isArray($scope.selected))
					$scope.ngModel = JSON.stringify($scope.selected);
			} else {
				$scope.ngModel = $scope.selected;
			}
		};

		var updateSelectedValue = function () {
			if (multiple) {
				if ($scope.ngModel && $scope.ngModel.endsWith(']'))
					$scope.selected = JSON.parse($scope.ngModel);
				else
					$scope.selected = [];
			} else {
				$scope.selected = $scope.ngModel || "";
			}
		};

		var setValues = function () {
			var values = $scope.item.NamedSelectionOptions.map(function (val) {
				return {
					value: val,
					name: val
				};
			});

			var existingValues = [];
			if (multiple) {
				if ($scope.ngModel && $scope.ngModel.endsWith(']'))
					existingValues = JSON.parse($scope.ngModel);
			} else {
				if ($scope.ngModel)
					existingValues = [$scope.ngModel];
			}

			if (Array.isArray(existingValues)) {
				existingValues.forEach(function (exValue) {
					if (values.findIndex(function (v) { return v.value === exValue; }) === -1)
						values.push({ value: exValue, name: exValue + " (" + translationService.translate('web-dynamicPropertyMultiselect-value-deprecated', 'utdatert') + ")" });
				});
			}

			$scope.values = values;
		};

		setValues();
		updateSelectedValue();

		$scope.$watchGroup(["item.NamedSelectionOptions", "ngModel"], function () {
			updateSelectedValue();
			setValues();
		});
	}
})();
