(function () {
	var app = angular.module('Plania');

	app.directive('plCategoryGrid', [function () {
		return {
			restrict: 'E',
			scope: {
				dynamicProperty: '=dynamicProperty',
				isAbstract: '=isAbstract'
			},
			templateUrl: 'app/common/directives/dynamicProperty/plCategoryGrid.html',
			controller: ['$scope', '$element', 'TranslationService', '$modal', 'Repository', 'Constants', '$timeout', controller],
		};

	}]);

	var controller = function ($scope, $element, translationService, $modal, repository, constants, $timeout) {

		$scope.grid = {};

		//todo, this should be reflected by categorytype instead of hardcoded
		var projectNumbers = constants.dynamicProperties.projectCategory;

		$scope.numberOfFields = {
			Text: [],
			Numeric: [],
			Date: [],
			Combo: []
		};

		for (var i = 0; i < projectNumbers.max; i++) {
			if (i < projectNumbers.text)
				$scope.numberOfFields.Text.push({ number: i, isAvailable: true });
			if (i < projectNumbers.numeric)
				$scope.numberOfFields.Numeric.push({ number: i, isAvailable: true });
			if (i < projectNumbers.date)
				$scope.numberOfFields.Date.push({ number: i, isAvailable: true });
			if (i < projectNumbers.combo)
				$scope.numberOfFields.Combo.push({ number: i, isAvailable: true });
		}

		$scope.filterObject = function () {
			return function (item) {
				if (item.isAvailable)
					return true;
				if (item.number === $scope.selectedProperty.Field)
					return true;
				return false;
			};
		};

		$scope.typeHasAvailableNumber = function (type) {
			return _.findIndex($scope.numberOfFields[type],
				function (i) {
					return i.isAvailable;
				}) > -1;
		};

		$scope.setNumberedFieldsAvailability = function (newValue, oldValue, propertyType) {
			var oldItem = _.find($scope.numberOfFields[propertyType],
				function (n) {
					return n.number === oldValue;
				});
			if (oldItem)
				oldItem.isAvailable = true;

			var newItem = _.find($scope.numberOfFields[propertyType],
				function (n) {
					return n.number === newValue;
				});
			if (newItem)
				newItem.isAvailable = false;
		};


		$scope.fieldTypeItems = [
			{
				Guid: null,
				Name: translationService.translate('web-enum-dynamicProperty-Text', 'Text'),
				Type: 'Text',
				icon: 'text',
				LabelValue: 'Feltnavn'
			},
			{
				Guid: null,
				Name: translationService.translate('web-enum-dynamicProperty-Numeric', 'Nummer'),
				Type: 'Numeric',
				icon: 'input-numeric',
				LabelValue: 'Feltnavn'
			},
			{
				Guid: null,
				Name: translationService.translate('web-enum-dynamicProperty-Date', 'Dato'),
				Type: 'Date',
				icon: 'calendar',
				LabelValue: 'Feltnavn'
			},
			{
				Guid: null,
				Name: translationService.translate('web-enum-dynamicProperty-Combo', 'Nedtrekksfelt'),
				Type: 'Combo',
				icon: 'list-dropdown',
				LabelValue: 'Feltnavn'
			}
		];

		$scope.selectedGroup = {};
		$scope.selectedProperty = {}; //DynamicProperty.cs

		$timeout(function () {
			$('.grid-stack').on('change',
				function (e, nodes) {
					for (var i = ($scope.dynamicProperty.Groups.length - 1); i >= 0; i--) {
						var group = $scope.dynamicProperty.Groups[i];

						for (var j = (group.Items.length - 1); j >= 0; j--) {
							var property = group.Items[j];
							var node = _.find(nodes, { id: property.Guid });
							if (node) {
								property.WebLayout.Height = node.height;
								property.WebLayout.Width = node.width;
								property.WebLayout.PointY = node.y;
								property.WebLayout.PointX = node.x;
							}
						}
					}
				});
		}, 500);

		//Function used to check where the new widget should be placed
		var isAvailable = function (property, gridId, x, y) {
			if (!$scope.grid[gridId])
				return true;
			return !_.find($scope.grid[gridId].grid.nodes,
				function (b) {
					var a = { x: x, y: y, width: property.WebLayout.Width, height: property.WebLayout.Height };
					return !(a.x + a.width <= b.x ||
						b.x + b.width <= a.x ||
						a.y + a.height <= b.y ||
						b.y + b.height <= a.y);
				});
		};

		$scope.selectGroup = function (group) {
			$scope.selectedGroup = group;
			$scope.selectedProperty = null;
		};

		$scope.removeSelectedGroup = function () {
			_.remove($scope.dynamicProperty.Groups, { Guid: $scope.selectedGroup.Guid });
			if ($scope.dynamicProperty.Groups[0])
				$scope.selectGroup($scope.dynamicProperty.Groups[0]);
		};

		$scope.removeSelectedProperty = function () {

			_.remove($scope.selectedGroup.Items, { Guid: $scope.selectedProperty.Guid });
			var node = _.find($scope.grid[$scope.selectedGroup.Guid].grid.nodes, function (node) {
				return node.id === $scope.selectedProperty.Guid;
			});
			if (node) {
				$scope.grid[$scope.selectedGroup.Guid].remove_widget(node.el);
				$scope.selectedProperty = null;
			}
		};

		$scope.addNewGroup = function () {
			var indexPosition = $scope.dynamicProperty.Groups.length;
			var newGroup = {
				Guid: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var d = new Date().getTime();
					var r = (d + Math.random() * 16) % 16 | 0;
					d = Math.floor(d / 16);
					return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
				}),
				Title: 'tab: ' + indexPosition,
				IndexPosition: indexPosition,
				Items: [],
			};
			$scope.dynamicProperty.Groups.push(newGroup);
			$scope.selectedGroup = newGroup;
		};

		var gridOptions = {
			cellHeight: 75,
			animate: true,
			disableResize: true
		};

		$scope.addNewProperty = function (property) {
			var width = 12;
			var positionY = $scope.selectedGroup.Items.length % 2 ? 0 : 1;

			var gridId = $scope.selectedGroup.Guid;
			if ($scope.grid[gridId] === undefined) {
				$scope.grid[gridId] = $('#' + gridId).gridstack(gridOptions).data('gridstack');
			}
			if ($scope.grid[gridId]) {
				GridStackUI.Utils.sort($scope.grid[gridId].grid.nodes, 1, width);
			}
			var newProperty = {
				Guid: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
					function (c) {
						var d = new Date().getTime();
						var r = (d + Math.random() * 16) % 16 | 0;
						d = Math.floor(d / 16);
						return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
					}),
				WebLayout: { Width: 4, Height: 1, PointY: positionY },
				auto_position: false,
				Type: property.Type,
				LabelPropertyValue: translationService.translate('web-dynamicProperties-labelPlaceholder', 'Angi ledetekst'),
				NotEditableInListView: true
			};

			var field = _.find($scope.numberOfFields[newProperty.Type], function (v) { return v.isAvailable; });
			if (field) {
				newProperty.Field = field.number;
			}
			for (var i = 0; ; ++i) {
				var x = i % width, y = Math.floor(i / width);
				if (x + newProperty.WebLayout.Width > width) {
					continue;
				}

				if (isAvailable(newProperty, gridId, x, y)) {
					newProperty.WebLayout.PointX = x;
					newProperty.WebLayout.PointY = y;
					break;
				}
			}

			$scope.selectedGroup.Items.push(newProperty);
			$scope.selectedProperty = newProperty;
		};

		$scope.selectNamedSelection = function () {
			$modal.open({
				templateUrl: 'app/common/views/singleSelectModal.html',
				controller: 'SingleSelectModalController',
				size: 'md',
				resolve: {
					modalParams: function () {
						return {
							url: repository.apiData.namedSelection.url,
							sorting: {},
							title: 'Nedtrekksfelt',
							filter: {},
							columns: [
								{ title: 'Nedtrekksfelt', property: 'Value' }
							]
						};
					}
				}
			})
				.result.then(function (item) {
					$scope.selectedProperty.GuidNamedSelection = item.Guid;
					$scope.selectedProperty.NamedSelection = item;
				},
					function (msg) {
					});
		};

		$scope.selectProperty = function (property) {
			$scope.selectedProperty = property;
		};

		$scope.attachWidget = function (element, attrs, property) {
			$scope.setNumberedFieldsAvailability(property.Field, -1, property.Type);
			var gridId = attrs.groupGuid;
			if ($scope.grid[gridId] === undefined) {
				$scope.grid[gridId] = $('#' + gridId).gridstack(gridOptions).data('gridstack');
			}
			if ($scope.grid[gridId]) {
				$scope.grid[gridId].addWidget(element,
					attrs.gsX,
					attrs.gsY,
					attrs.gsWidth,
					attrs.gsHeight,
					attrs.gsAutoPosition, null, null, null, null,
					attrs.gsId);
			}
		};

		$scope.$watch('dynamicProperty',
			function (newValue, oldValue) {
				if (newValue === oldValue) return;

				$scope.selectedGroup = newValue.Groups[0];
			});

		if (!$scope.dynamicProperty || !$scope.dynamicProperty.Groups) {
			var defaultGroup = {
				Guid: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var d = new Date().getTime();
					var r = (d + Math.random() * 16) % 16 | 0;
					d = Math.floor(d / 16);
					return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
				}),
				Title: 'Egendefinerte felter',
				IndexPosition: 0,
				Items: [],
			};

			$scope.dynamicProperty = {
				Groups: [
					defaultGroup
				]
			};
			$scope.selectedGroup = defaultGroup;
		}

	};

})();
