(function () {
	angular.module('Plania').controller('CreateScheduledJobModalController', ['$scope', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, $modalInstance, translationService) {

		$scope.scheduledJobTypes = [
			{
				icon: 'square-sliders',
				colour: 'bluegray',
				title: translationService.translate('web-scheduledJob-type-MedicalClassificationExport', 'Rom klassifisering'),
				description: translationService.translate('web-scheduledJob-type-MedicalClassificationExport-description', 'Eksport av romklassifikasjon - testmiljø'),
				model: {
					Description: 'Eksport av romklassifikasjon - testmiljø',
					Type: 'MedicalClassificationExport',
					Params: {
						Url: 'https://apitest.klassifikasjonssystemet.no',
						Version: '3.1.4'
					}
				}
			},
			{
				icon: 'download',
				colour: 'blue',
				title: translationService.translate('web-scheduledJob-type-ftpConnector', 'Overfør fil fra SFTP katalog'),
				description: translationService.translate('web-scheduledJob-type-ftpConnector-description', 'Import/eksport av data med SFTP'),
				model: {
					Description: 'Import eller eksport av data ved hjelp av SFTP server',
					Type: 'FtpConnector',
					Params: { Port: 22, JobId: -1 }
				}
			},
			{
				icon: 'folder',
				colour: 'lightblue',
				title: translationService.translate('web-scheduledJob-type-fileConnector', 'Overfør fil fra server'),
				description: translationService.translate('web-scheduledJob-type-fileConnector-description', 'Import eller eksport fra lokal mappe eller nettverksdisk'),
				model: {
					Description: 'Overfør fil fra lokal disk eller nettverk',
					Type: 'FileConnector',
					Params: { JobId: -1, DeleteSourceFile: false }
				}
			}
		];

		$scope.save = function (jobType) {
			$modalInstance.close(jobType.model);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}

})();
