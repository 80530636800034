(function () {
	angular.module('Plania').controller('DashboardController', ['$scope', 'Repository', '$stateParams', '$modal', 'TranslationService', '$q', '$state', controller]);

	function controller($scope, repository, $stateParams, $modal, translationService, $q, $state) {
		$scope.edit = $stateParams.editMode;
		$scope.model = {};

		$scope.restrictEdit = true;

		var parseWidgetData = function () {
			$scope.model.WidgetDtos.forEach(function (widget) {
				if (widget.WidgetData && typeof (widget.WidgetData) === 'string') {
					widget.WidgetData = JSON.parse(widget.WidgetData);
				}
			});
		};

		$scope.openDashboardSettings = function () {
			var edit = $stateParams.guid ? true : false;
			var modalInstance = $modal.open({
				templateUrl: 'app/dashboard/dashboardSettingsModal.html',
				controller: 'DashboardSettingsModalController',
				resolve: {
					params: function () {
						return {
							edit: edit,
							model: $scope.model
						};
					}
				}
			});

			modalInstance.result.then(function (selectedWidget) {
				$scope.saveDashboard();
			}, function () {
				//Dismissed
			});
		};

		var updateAccess = function () {
			var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
			$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebDashboard, checkOtherDO);
		};

		var errorFunction = function (error) {
			if (error === 'Ingen data funnet')
				repository.growl(translationService.translate('web-dashboard-couldnotfind-error',
					'Kunne ikke finne dashbordet, sjekk at dashbord er registrert på riktig klient i forhold til rettighetsprofil'),
					'danger');
			else
				repository.growl(error, 'danger');
		};

		if ($stateParams.guid) {
			repository.getSingle(repository.apiData.webDashboard.url, $stateParams.guid).then(function (response) {
				$scope.model = response.Data;
				updateAccess();
				parseWidgetData();
			}, errorFunction);
		} else if ($scope.navigation.params.menuGuid) {
			menuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			repository.getSingle(repository.apiData.webDashboard.url, menuParams.GuidDashboard).then(function (response) {
				$scope.model = response.Data;
				updateAccess();
				parseWidgetData();
			}, errorFunction);
		} else {
			repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.WebDashboard)
				.then(function (result) {
					$scope.model.WidgetDtos = [];
					$scope.restrictEdit = false;
					$scope.openDashboardSettings();
				});
		}

		$scope.openAddWidgetModal = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/dashboard/addWidgetModal.html',
				controller: 'AddWidgetModalController',
				resolve: {
					widget: function () { return null; }
				}
			});

			modalInstance.result.then(function (selectedWidget) {
				$scope.sharedControl.addNewWidget(selectedWidget);
				$scope.saveDashboard(false);
			}, function () {
				//Dismissed
			});
		};

		$scope.toggleEdit = function () {
			$scope.edit = !$scope.edit;
			$scope.sharedControl.setMode($scope.edit);
		};

		var disableEdit = function () {
			$scope.edit = false;
			$scope.sharedControl.setMode($scope.edit);
		};

		$scope.sharedControl = {};

		$scope.saveDashboard = function (shouldDisableEdit) {
			var deferred = $q.defer();

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
				deferred.reject();
			};
			
			$scope.model.WidgetDtos.forEach(function (widget) {
				if (typeof (widget.WidgetData) === 'object') {
					widget.WidgetData = JSON.stringify(widget.WidgetData);
				}
			});

			if (!$scope.model.Guid) {
				repository.createSingle(repository.apiData.webDashboard.url, $scope.model).then(function (response) {
					repository.growl('Dashbordet har blitt opprettet', 'info');
					$scope.model.Guid = response.Guid;
					parseWidgetData();
					if (shouldDisableEdit) {
						disableEdit();
					}
					$state.go('dashboard.view', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid }, { location: 'replace' });
					deferred.resolve(response);
				}).catch(error);
			} else {
				repository.updateSingle(repository.apiData.webDashboard.url, $scope.model).then(function (response) {
					repository.growl('Dashbordet har blitt oppdatert', 'info');
					parseWidgetData();
					if (shouldDisableEdit) {
						disableEdit();
					}
					deferred.resolve(response);
				}).catch(error);
			}
			return deferred.promise;
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
