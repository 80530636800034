(function () {
	var app = angular.module('Plania');
	app.directive('entityTask', function () {
		return {
			restrict: 'E',
			scope: {
				reload: '=reload',
				entityType: '=entityType',
				guidEntity: '=guidEntity',
				disableActions: '=disableActions'
			},
			controller: ['$scope', 'Repository', 'TranslationService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/entityTaskDirective.html'
		};
	});

	function controller($scope, repository, translationService) {
		if (!$scope.guidEntity || !$scope.entityType) return;

		var entityLinkFilter = {
			GuidEntity: $scope.guidEntity,
			PropertyFilter: [
				{
					Property: 'EntityType',
					Operator: '=',
					Value: $scope.entityType
				},
				{
					Property: 'Type',
					Operator: '=',
					Value: '1'
				}
			]
		};
		$scope.entityLinks = [];
		getEntityLinks();

		function getEntityLinks() {
			var columns = ['Name', 'Url', 'Type', 'Description', 'IndexPosition', 'EntityType', 'UseUrlEncoding', 'HttpHeaders', 'ActionRule', 'EntityTasks', 'Filter'];

			repository.GetPaginated(repository.apiData.entityLink.url, 0, 10000, { 'IndexPosition': 'asc' }, entityLinkFilter, null, JSON.stringify(columns)).then(function (result) {
				$scope.totalCount = result.TotalCount;
				$scope.entityLinks = result.List.filter(function (link) { return link.Visible; });
				_.forEach($scope.entityLinks, function(link){
					if (link.ActionRule) {
						link.ParsedActionRule = JSON.parse(link.ActionRule);
						if (link.ParsedActionRule.OnlyOnce){

							link.CurrentEntityTask = angular.copy(link.EntityTasks[0]);
							if (!link.CurrentEntityTask || !link.CurrentEntityTask.GuidUserCreatedBy) return;
							repository.getSingle(repository.apiData.user.url, link.CurrentEntityTask.GuidUserCreatedBy, null).then(
								function (result) {
									link.CurrentEntityTask.UserCreatedBy = result.Data.RealName;
								}, function (error) {
									repository.growl(error, 'danger');
									link.CurrentEntityTask.UserCreatedBy = '';
								}
							);
						}
					}
				});
			}, function (error) {
				repository.growl(error, 'danger');
			});
		}

		$scope.getStatusColor = function(status) {
			if (status === 'Created') return 'info';
			switch (status) {
				case 'Created':
					return 'info';
				case 'Error':
					return 'danger';
			}
		};

		$scope.runAction = function (link) {
			swal({
				title: "Bekreft",
				text: translationService.translate('web-entityTask-confirmAction', 'Vil du opprette handling %Name%?', link),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#337ab7",
				confirmButtonText: "Ja",
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				var params = {
					GuidEntity:  $scope.guidEntity
				};
				var guidEntityLink = link.Guid;
				repository.runAction(repository.apiData.entityLink.url + guidEntityLink + '/doAction', params).then(function (response) {
					repository.growl("Utført", 'success');
					getEntityLinks();
					swal.close();
				}).catch(function (error) {
					repository.growl(error, 'danger');
					getEntityLinks();
					swal.close();
				});
			});
		};
	}
})();
