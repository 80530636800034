(function () {
	var app = angular.module('Plania');

	app.directive('bimConnectionDirective', function () {
		return {
			restrict: 'E',
			scope: {
				guid: '=guid',
				entity: '=entity',
				prefix: '=prefix',
				reload: '=reload'
			},
			controller: ['$scope', '$modal', 'Repository', 'TranslationService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/bimConnectionDirective.html'
		};
	});


	function controller($scope, $modal, repository, translationService) {
		$scope.renderSelf = false;

		var getGuidBuilding = function () {
			if (!$scope.entity)
				return null;
			return $scope.entity.GuidBuilding;
		};

		var hasBuildingBimModel = function () {
			var guidBuilding = getGuidBuilding();
			if (!guidBuilding) return;

			var filter = {
				PropertyFilter: [
					{ Property: "GuidBuilding", Operator: "=", Value: getGuidBuilding() },
					{ Property: "BimFile.ProcessingStatus", Operator: "=", Value: 3 }
				]
			};

			repository.GetPaginated(repository.apiData.buildingXBimFile.url, 0, 1, {}, filter, null, JSON.stringify(["Id"])).then(function (result) {
				$scope.renderSelf = !!result.TotalCount;
			}, function () { /*Empty*/ });
		};

		var loadIfcProductData = function () {
			if ($scope.entity.GuidIfc) {
				var url = repository.apiData.planiaBim.url;
				url += "building/" + getGuidBuilding();
				url += "/products/" + $scope.entity.GuidIfc;
				repository.getWithUrlParameter(url).then(function (result) {
					if (result && result.attributes) {
						var longName = result.attributes.LongName;
						var name = result.attributes.Name;
						if (longName && name) {
							$scope.productName = [longName.value, name.value].filter(function (x) { return !!x; }).join(" - ");
						}
					}
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		hasBuildingBimModel();
		loadIfcProductData();

		// Reload once to wait for model to load.
		var reloadWatcher = $scope.$watch("reload", function (newValue) {
			if (newValue) {
				hasBuildingBimModel();
				loadIfcProductData();
				reloadWatcher();
			}
		});

		

		$scope.openEdit = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/bimConnectionEditModal.html',
				controller: 'BimConnectionEditModalController',
				resolve: {
					params: function () {
						return {
							guidIfc: $scope.entity.GuidIfc,
							prefix: $scope.prefix,
							guid: $scope.guid,
							guidBuilding: getGuidBuilding()
						};
					}
				}
			}).result.then(function (guidIfc) {
				$scope.entity.GuidIfc = guidIfc;
				loadIfcProductData();
			});
		};
	}
})();
