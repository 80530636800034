
// =========================================================================
// Base controller for common functions
// =========================================================================
angular.module('Plania').controller('MainController', ['$scope', '$timeout', '$state', '$rootScope', controller]);

function controller($scope, $timeout, $state, $rootScope) {

	var vm = this;

	// Detact Mobile Browser
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		angular.element('html').addClass('ismobile');
		localStorage.setItem('ma-layout-status', 1);
	}
	// By default Sidbars are hidden in boxed layout and in wide layout only the right sidebar is hidden.
	vm.sidebarToggle = {
		left: false,
		right: false
	};

	if (!localStorage.getItem('ma-layout-status')) {
		localStorage.setItem('ma-layout-status', 1);
	}

	// By default template has a boxed layout
	vm.layoutType = localStorage.getItem('ma-layout-status');

	// For Mainmenu Active Class
	vm.$state = $state;

	// Fallback just in case we are not using template for react pages, and keep same angular pages.
	var fullscreenStates = [
		"map",
		"dwg.view",
		"document.viewPdf",
		"bimFile.view",
		"bimFile.viewBimsync",
		"entityPermissionProfile.edit", "entityPermissionProfile.create",
		"namedSelection.edit", "namedSelection.create",
		"alarm.edit", "alarm.create",
		"planiasystem",
		"databaseInfo",
		"login",
		"forgotPassword",
		"bcfProject.create", "bcfProject.edit",
		"bimProjects",
		"mobileMenuProfile.edit", "mobileMenuProfile.create",
		"admin.dashboard",
		"doorKeySystem.edit", "doorKeySystem.create",
		"doorKey.edit", "doorKey.create",
		"doorLock.edit", "doorLock.create",
		"doorKeyXUser.edit", "doorKeyXUser.create",
	];

	var ignoreOverflowStates = [
		"map",
		"dwg.view",
		"document.viewPdf",
	];

	var shouldUseFullscreen = function (params) {
		if (params && params.template) return true;
		for (var i = 0; i < fullscreenStates.length; i++) {
			var stateName = fullscreenStates[i];
			if ($state.includes(stateName))
				return true;
		}
		return false;
	};
	vm.showFullscreen = shouldUseFullscreen($state.current);

	var shouldIgnoreOverflow = function () {
		for (var i = 0; i < ignoreOverflowStates.length; i++) {
			var stateName = ignoreOverflowStates[i];
			if ($state.includes(stateName))
				return true;
		}
		return false;
	};
	vm.shouldIgnoreOverflow = shouldIgnoreOverflow();

	$rootScope.$on('$stateChangeSuccess', function (from, fromParams) {
		vm.showFullscreen = shouldUseFullscreen(fromParams);
		vm.shouldIgnoreOverflow = shouldIgnoreOverflow();
	});

	//Close sidebar on click
	vm.sidebarStat = function (event) {
		if (!angular.element(event.target).parent().hasClass('active')) {
			vm.sidebarToggle.left = false;
		}
	};

}
