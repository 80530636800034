(function () {
	angular.module('Plania').controller('EntityHistoryController', ['$scope', 'Repository', 'ngTableParams', 'TranslationService', '$modal', 'ListService', controller]);

	function controller($scope, repository, ngTableParams, translationService, $modal, listService) {

		var initEntityHistoryTable = function (guid) {
			if ($scope.entityHistoryTable) return;

			$scope.entityHistoryColumns = [
				{ Position: 1, Title: translationService.translate('web-entityHistory-fieldname', 'Feltnavn'), Property: 'FieldName', PropertyType: 'string', SortingDisabled: true },
				{ Position: 2, Title: translationService.translate('web-entityHistory-previousValue', 'Fra'), Property: 'PreviousValue', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-entityHistory-value', 'Til'), Property: 'Value', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-entityHistory-updatedDate', 'Tidspunkt'), Property: 'UpdatedDate', PropertyType: 'datetime' },
				{ Position: 5, Title: translationService.translate('web-entityHistory-updatedBy', 'Bruker'), Property: 'UserUpdatedBy.Username', PropertyType: 'string' }
			];

			$scope.getPropertyValue = function (item, column) {
				var col = column;
				if (column.Property === "FieldName") {
					col = {
						Position: column.Position,
						Title: column.Title,
						Property: "TranslatedFieldName",
						PropertyType: column.PropertyType,
						SortingDisabled: column.SortingDisabled
					};
				}
					
				return listService.GetPropertyValue(item, col);
			};

			$scope.entityHistoryTable = new ngTableParams(
				{
					page: 1,
					count: 10,
                    sorting: { 'UpdatedDate': 'desc', 'CreationDate': 'desc' },
					filter: { PropertyFilter: [{ Property: 'GuidEntity', Operator: '=', Value: guid }] }
				},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.entityHistoryColumns.forEach(function (col) {
							columns.push(col.Property);
						});
						repository.GetPaginated(repository.apiData.entityHistory.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(),
							null,
							JSON.stringify(columns)).then(
							function (result) {
								$scope.entityHistoryTable.settings().total = result.TotalCount;
								$scope.entityHistoryTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		};

		$scope.showEntityHistory = function (guid) {
			initEntityHistoryTable(guid);
			$modal.open({
				templateUrl: 'app/entityHistory/views/entityHistoryModal.html',
				scope: $scope,
				size: 'lg'
			});
		};

	}
})();
