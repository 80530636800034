(function () {
	var app = angular.module('Plania');

	app.directive('bimNonsReferenceDirective', function () {
		return {
			restrict: 'E',
			scope: {
				guid: '=guid',
				entity: '=entity',
				reload: '=reload'
			},
			controller: ['$scope', '$modal', 'Repository', 'TranslationService', '$rootScope', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/bimNonsReferenceDirective.html'
		};
	});

	function controller($scope, $modal, repository, translationService, $rootScope) {
		$scope.renderSelf = false;
		$scope.treeModel = [];
		$scope.isEntitiesCollapsed = false;
		$scope.treeModelIsLoading = false;
		var getGuidBuilding = function () {
			if (!$scope.entity)
				return null;

			return $scope.entity.GuidBuilding;
		};

		var hasBuildingBimModel = function () {
			var guidBuilding = getGuidBuilding();
			if (!guidBuilding) return;
			if (!$scope.entity.GuidNonsReference) return;

			var filter = {
				PropertyFilter: [
					{ Property: "GuidBuilding", Operator: "=", Value: guidBuilding },
					{ Property: "BimFile.ProcessingStatus", Operator: "=", Value: 3 }
				]
			};

			repository.GetPaginated(repository.apiData.buildingXBimFile.url, 0, 1, {}, filter, null, JSON.stringify(["Id"])).then(function (result) {
				$scope.renderSelf = !!result.TotalCount;
				if ($scope.renderSelf)
					GetProductsFromNonsReference();
			});
		};
		hasBuildingBimModel();

		// Reload once to wait for model to load.
		var reloadWatcher = $scope.$watch("reload", function (newValue) {
			if (newValue) {
				hasBuildingBimModel();
				reloadWatcher();
			}
		});

		function GetProductsFromNonsReference() {
			$scope.treeModelIsLoading = true;
			var url = repository.apiData.planiaBim.url;
			url += "building/" + $scope.entity.GuidBuilding;
			url += "/nons/" + $scope.entity.GuidNonsReference;
			url += "/products";

			repository.getWithUrlParameter(url).then(function (result) {
				$scope.focusedNodeId = result.FocusedNodeId;
				var nodes = result.NonsReferenceNode;
				$scope.treeModel = [nodes];

				// look up FocusedNodeId and expand all parents
				var focusedNode = lookupFocusedNode($scope.focusedNodeId, nodes);

				if (focusedNode)
					focusedNode.isExpanded = true;
				else
					$scope.treeModel[0].isExpanded = true;
				$scope.treeModelIsLoading = false;
			}, function (error) {
				repository.growl(error, 'danger');
				$scope.treeModelIsLoading = false;
			});
		}

		function findNodes(nodeId, nodes) {
			var found = _.find(nodes, function (item) {
				return item.Id === nodeId;
			});
			return found;
		}

		function iterateNodes(nodeId, nodes) {
			var found;
			_.forEach(nodes, function (node) {
				found = lookupFocusedNode(nodeId, node);
				if (found) return found;
			});
			return found;
		}

		function lookupFocusedNode(nodeId, node) {
			var found;
			while (!found) {
				found = findNodes(nodeId, node.Nodes);
				if (!found) {
					found = iterateNodes(nodeId, node.Nodes);
				}
				if (found)
					node.isExpanded = true;
				return found;
			}
			return found;
		}

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="fas ' + icon + ' fa-hc-fw fa-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		$scope.menuOptions = function (item) {
			var options = [];
			options.push(getMenuLink($rootScope.navigation.href('bimFile.viewBimsync', { guidEquipment: $scope.entity.Guid, nonsReferencePath: item.Path }), 'fa-cube', translationService.translate('web-listview-contextMenu-area-viewBimsyncModel', 'Gå til bim modell')));

			return options;
		};
	}
})();
