(function () {
	angular.module('Plania').controller('ComponentController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', 'ListService', '$localStorage', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, listService, $localStorage) {
		$scope.isUpdate = $scope.navigation.current.name === 'component.edit';
		$scope.persons = [];
		$scope.activateAutocomplete = false;
		$scope.barcode = {};
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.model = { Guid: $stateParams.guid };
		$scope.useComponentCounter = $localStorage.generalOptions.UseComponentCounter;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Component, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Component, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Component);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Component);
			}
		};

		updateAccess();

		$scope.componentEquipmentColumns = [
			{ Position: 1, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-componentxequipment-quantity', 'Antall'), Property: 'Quantity', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-area-id'), Property: 'Equipment.Area.Id', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-area-description'), Property: 'Equipment.Area.Description', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-equipment-updatedDate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' },
		];

		$scope.componentAreaColumns = [
			{ Position: 1, Title: translationService.translate('web-area-id'), Property: 'Area.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-area-description'), Property: 'Area.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-componentxarea-quantity', 'Antall'), Property: 'Quantity', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-componentxequipment-updatedDate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'string' },
		];

		var supplierColumns = [
			'Supplier.Id', 'Supplier.Description', 'Supplier.Telephone',
			'Manufacturer', 'Price', 'PartNumber', 'DeliveryTimePeriodUnit', 'DeliveryTimePeriodNumber'
		];

		var getCardTables = function () {
			$scope.equipmentTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { 'Equipment.Id': 'asc' },
					filter: {
						PropertyFilter: [
							{ Property: 'GuidComponent', Operator: '=', Value: $scope.model.Guid }
						]
					}
				},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 30],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = _.map($scope.componentEquipmentColumns, 'Property');
						columns.push.apply(columns, ['Note', 'Equipment.Building.Id', 'Equipment.Building.Description']);
						repository.GetPaginated(repository.apiData.componentEquipment.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
							$scope.equipmentTable.settings().total = result.TotalCount;
							$scope.equipmentTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
					}
				});


			$scope.supplierTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Supplier.Id': 'asc' },
				filter: {
					GuidComponent: $scope.model.Guid
				}
			},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 30],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						repository.GetPaginated(repository.apiData.componentSupplier.url, params.page() - 1, params.count(), params.sorting(), params.filter(), '', JSON.stringify(supplierColumns)).then(function (result) {
							$scope.supplierTable.settings().total = result.TotalCount;
							$scope.supplierTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
					}
				});

			$scope.areaTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Area.Id': 'asc' },
				filter: { PropertyFilter: [{ Property: "GuidComponent", Operator: "=", Value: $scope.model.Guid }] }
			},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 30],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = _.map($scope.componentAreaColumns, 'Property');
						columns.push.apply(columns, ['Area.Building.Id', 'Area.Building.Description']);
						repository.GetPaginated(repository.apiData.componentArea.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
							$scope.areaTable.settings().total = result.TotalCount;
							$scope.areaTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
					}
				});
		};

		var columns = ['ComponentCategory.Description', 'PreferredComponent.Id', 'PreferredComponent.Description'];

		if ($scope.isUpdate) {
			repository.getMainModel(repository.apiData.component.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.reload = true;

					updateAccess();
					$scope.reloadDocumentTable = true;
					$scope.reloadQrCodes = true;

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
			getCardTables();
		} else {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidComponentCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.componentCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'ComponentCategory':
								$scope.model.ComponentCategory = { Guid: prefill.Value.Guid, Description: prefill.Value.Label, Caption: prefill.Value.Label };
								$scope.model.GuidComponentCategory = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}
			getCategoryPrefill();
			$scope.activateAutocomplete = true;
		}

		$scope.getPropertyValue = function (row, columns) {
			return listService.GetPropertyValue(row, columns);
		};

		$scope.onComponentCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidComponentCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidComponentCategory = category.Guid;
		};

		$scope.updateComponent = function (destination) {

			var success = function (result) {
				repository.growl($scope.isUpdate ? "Komponenten ble oppdatert" : 'Komponenten ble lagret', 'success');
				var componentGuid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, componentGuid);
				if (destination === 'component.create') {
					$scope.navigation.go(destination, {}, { reload: true });
				} else {
					repository.persistedData.clearPersistedData();
					$scope.goBack(destination, { guid: componentGuid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate)
				repository.updateSingle(repository.apiData.component.url, $scope.model).then(success).catch(error);
			else {
				repository.createSingle(repository.apiData.component.url, $scope.model).then(success).catch(error);
			}
		};

		$scope.cancel = function () {
			$scope.goBack('component.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.removeComponent = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-component-message', "Komponenten vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-component-button-confirm', 'Ja, fjern komponenten'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.component.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-component-success', 'Komponenten ble fjernet!'), result, "success");
						$scope.goBack('component.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.addEquipment = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						return {
							title: translationService.translate('web-compnentEquipment-title-add', 'Legg til anlegg'),
							columns: [
								{ Title: translationService.translate('web-equipment-building-id', 'Byggnr.'), Property: 'Building.Id' },
								{ Title: translationService.translate('web-equipment-building-description', 'Byggnavn'), Property: 'Building.Description' },
								{ Title: translationService.translate('web-equipment-id', 'Id'), Property: 'Id' },
								{ Title: translationService.translate('web-equipment-description', 'Beskrivelse'), Property: 'Description' }
							],
							url: repository.apiData.equipment.url,
							sorting: { Id: 'asc' },
							filter: { PropertyFilter: [{ Property: 'IsTemplate', Operator: '=', Value: false }] }
						};
					}
				}
			}).result.then(function (equipmentGuids) {
				var model = [];
				equipmentGuids.forEach(function (guid) {
					model.push({ GuidEquipment: guid, GuidComponent: $scope.model.Guid });
				});

				repository.createMultiple(repository.apiData.componentEquipment.url, model).then(function (result) {
					repository.growl(translationService.translate('component-equipment-add-success', 'Anlegg har blitt lagt til'), 'success');
					$scope.equipmentTable.reload();
				}, function (error) {
					repository.growl(error, "danger");
					$scope.equipmentTable.reload();
				});
			}, function () {
				//cancel
			});
		};

		$scope.removeEquipment = function (equipment) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-component-equipment-message', "Anlegget vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-component-equipment-button-confirm', 'Ja, fjern anlegget'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.componentEquipment.url, equipment.Guid).then(function (result) {
					swal(translationService.translate('web-swal-component-equipment-success', 'Anlegget ble fjernet!'), result, "success");
					$scope.equipmentTable.reload();
				});
			});
		};

		$scope.updateEquipment = function (equipment) {
			$modal.open({
				templateUrl: 'app/component/views/editComponentEquipment.html',
				controller: 'EditComponentEquipmentController',
				resolve: {
					params: function () {
						return {
							equipment: equipment
						};
					}
				}
			}).result.then(function () {
				$scope.equipmentTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.addSupplier = function () {
			$modal.open({
				templateUrl: 'app/component/views/componentSupplierModal.html',
				controller: 'ComponentSupplierController',
				resolve: {
					params: function () {
						return {
							isUpdate: false,
							guidComponent: $scope.model.Guid
						};
					}
				}
			}).result.then(function () {
				$scope.supplierTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.updateSupplier = function (supplier) {
			$modal.open({
				templateUrl: 'app/component/views/componentSupplierModal.html',
				controller: 'ComponentSupplierController',
				resolve: {
					params: function () {
						return {
							isUpdate: true,
							supplier: supplier,
						};
					}
				}
			}).result.then(function () {
				$scope.supplierTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.removeSupplier = function (supplier) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-component-supplier-message', "Leverandøren vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-component-supplier-button-confirm', 'Ja, fjern leverandøren'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.componentSupplier.url, supplier.Guid).then(function (result) {
					swal(translationService.translate('web-swal-component-supplier-success', 'Leverandøren ble fjernet!'), result, "success");
					$scope.supplierTable.reload();
				});
			});
		};

		$scope.addArea = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: translationService.translate('web-compnentEquipment-title-add-area', 'Legg til rom'),
							columns: [
								{ Title: translationService.translate('web-area-id', 'Id'), Property: 'Id' },
								{ Title: translationService.translate('web-area-description', 'Beskrivelse'), Property: 'Description' }
							],
							url: repository.apiData.area.url,
							sorting: { Id: 'asc' },
							filter: {}
						};
					}
				}
			}).result.then(function (areaGuids) {
				var model = [];
				areaGuids.forEach(function (guid) {
					model.push({ GuidArea: guid, GuidComponent: $scope.model.Guid });
				});

				repository.createMultiple(repository.apiData.componentArea.url, model).then(function (result) {
					repository.growl(translationService.translate('web-component-addArea-success', 'Areal har blitt lagt til'), 'success');
					$scope.areaTable.reload();
				}, function (error) {
					repository.growl(error, "danger");
					$scope.areaTable.reload();
				});
			}, function () {
				//cancel clicked on modal
			});
		};

		$scope.removeArea = function (area) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-component-area-message', "Rommet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-component-area-button-confirm', 'Ja, fjern rommet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.componentArea.url, area.Guid).then(function (result) {
					swal(translationService.translate('web-swal-component-area-success', 'Rommet ble fjernet!'), result, "success");
					$scope.areaTable.reload();
				});
			});
		};



		$scope.updateArea = function (area) {
			$modal.open({
				templateUrl: 'app/component/views/editComponentArea.html',
				controller: 'EditComponentAreaController',
				resolve: {
					params: function () {
						return {
							componentArea: area
						};
					}
				}
			}).result.then(function () {
				$scope.areaTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.uploadImage = function (images) {
			if (images.length < 1) return;
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/imageUploadModal.html',
				controller: 'ImageUploadModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							image: images[0],
							areaType: 'square'
						};
					}
				}
			});

			modalInstance.result.then(function (croppedImage) {
				var imageViewModel = {
					ImageSrc: null,
					Name: null,
					OriginalImageSrc: null
				};

				handleFileUpload(croppedImage, images[0]);

				//awaits reading of both the original image source, and the cropped image source into imageviewModel than uploads the image
				function handleFileUpload(file, originalImage) {
					var readCroppedImage = new Promise(function (resolve, reject) {
						var reader = new FileReader();
						reader.onload = function (e) {
							try {
								imageViewModel.ImageSrc = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
								imageViewModel.Name = file.name;
								resolve();
							} catch (error) {
								repository.growl(error);
								reject();
							}
						};
						reader.readAsDataURL(file);
					});

					var readOriginalImage = new Promise(function (resolve, reject) {
						var reader = new FileReader();

						reader.onload = function (e) {
							try {
								imageViewModel.OriginalImageSrc = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
								resolve();
							} catch (error) {
								repository.growl(error);
								reject();
							}
						};
						reader.readAsDataURL(originalImage);
					});

					Promise.allSettled([
						readCroppedImage, readOriginalImage,
					]).then(uploadImages);
				}

				function uploadImages() {
					var files = [imageViewModel];
					var shouldDeleteOldImage = false;
					if ($scope.model.GuidImage) {
						shouldDeleteOldImage = true;
						var oldGuid = $scope.model.GuidImage;
					}

					repository.createMultiple(repository.apiData.image.url, files).then(function (result) {
						$scope.model.GuidImage = result[0].Guid;

						repository.updateSingle(repository.apiData.component.url, $scope.model).then(function (result) {
							if (shouldDeleteOldImage) {
								repository.deleteSingle(repository.apiData.image.url, oldGuid).then(function (result) {
								});
							}
						});
					}, function (error) {
						repository.growl(error);
					});
				}
			});
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "componentCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
