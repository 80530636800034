(function () {
	angular.module('Plania').controller('ControlListController', ['$scope', 'Repository', '$stateParams', '$modal', 'TranslationService', '$location', '$timeout', controller]);
	function controller($scope, repository, $stateParams, $modal, translationService, $location, $timeout) {
		$scope.isUpdate = $scope.navigation.current.name === 'controlList.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.selectedFormItem = null;
		$scope.copyOfSelectedItem = {};
		$scope.conditionColumns = [];
		$scope.previewControlListXEntityObject = null;

		// #region Tab control
		var availableHashLocations = ["#info", "#connections", "#history", "#answers", "#setup"];
		var updateLocation = function () {
			var hash = window.location.hash;
			if (hash && availableHashLocations.includes(hash)) {
				$('#controlListTabs a[data-target="' + hash + '"]').tab('show');
				$scope.currentTab = hash.substr(1);
			}
			else {
				$('#controlListTabs a[data-target="#info"]').tab('show');
				$scope.currentTab = 'info';
			}
		};

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			updateLocation();
		});

		$scope.setTab = function (tab) {
			$location.hash(tab).replace();
		};

		if ($scope.isUpdate && $stateParams.tab) {
			$scope.setTab($stateParams.tab);
			updateLocation();
		} else {
			updateLocation();
		}
		// #endregion

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.ControlList, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ControlList, checkOtherDO);

				// Can only change a draft version
				if ($scope.model.VersionStatus !== 'Draft') {
					$scope.restrictEdit = true;
					$scope.restrictDelete = true;
				}
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.ControlList);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ControlList);
			}
		};

		updateAccess();

		var columns = [
			"ReferenceTypeNotExecuted.Id", "ReferenceTypeNotExecuted.Description"
		];

		var getEntity = function () {
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// furthermore, some fields may be dynamically initialized for new entities, what may be difficult to maintain in front end code
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			return repository.getMainModel(repository.apiData.controlList.url, ($scope.isUpdate ? $stateParams.guid : "00000000-0000-0000-0000-000000000000"), JSON.stringify(columns) + ($stateParams.guidEntityToCopy ? "&GuidCopyFrom=" + $stateParams.guidEntityToCopy : ""));
		};

		var parseControlListItems = function () {
				if ($scope.model.ControlListItems) {
					$scope.model.ControlListItems.forEach(function (item) {
						if (item.Data)
							item.Data = JSON.parse(item.Data);
					});
			} else {
					$scope.model.ControlListItems = [];
			}
		};

		var parseControlListRules = function () {
				if ($scope.model.ControlListRules) {
					$scope.model.ControlListRules.forEach(function (item) {
						if (item.Conditions)
							item.Conditions = JSON.parse(item.Conditions);
						if (item.Parameters)
							item.Parameters = JSON.parse(item.Parameters);
					});
				} else
					$scope.model.ControlListRules = [];
		};

		var parseConditions = function () {
			if ($scope.model.Conditions)
				$scope.conditionColumns = JSON.parse($scope.model.Conditions);
		};

		getEntity().then(function (response) {
			var controlList = response.Data;
			$scope.model = controlList;

			$scope.reload = true;
			if ($scope.isUpdate) {
				parseControlListItems();
				parseControlListRules();
				parseConditions();
			}

			$scope.model.canSetNotExecuted = !!$scope.model.GuidReferenceTypeNotExecuted;
			updateAccess();
			$timeout(function () { $scope.activateAutocomplete = true; }, 250);
		}, function (error) {
			repository.growl(error, 'danger');
		});

		$scope.update = function (destination) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			// continue with main control list saving process only if no error during saving of current item
			$scope.saveCurrentItem(function () {
				var modelCopy = angular.copy($scope.model);

				if ($scope.conditionColumns && $scope.conditionColumns.length > 0) modelCopy.Conditions = angular.toJson($scope.conditionColumns);
				else modelCopy.Conditions = undefined;

				delete modelCopy.ControlListItems;
				delete modelCopy.ControlListRules;

				var success = function (result) {
					repository.growl($scope.isUpdate ?
						translationService.translate('update-controlList-success', 'Kontrollisten ble oppdatert') :
						translationService.translate('create-controlList-success', 'Kontrollisten ble opprettet'), 'success');

					repository.commonService.setLastRegisterGuid(destination, result.Guid);

					var hash = window.location.hash;
					if (hash) hash = hash.substr(1);
					$scope.goBack(destination, { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid, tab: hash });
				};

				var error = function (error) {
					repository.growl(error, 'danger');
				};

				if ($scope.isUpdate) {
					repository.updateSingle(repository.apiData.controlList.url, modelCopy).then(success).catch(error);
				}
				else {
					repository.createSingle(repository.apiData.controlList.url, modelCopy).then(success).catch(error);
				}
			});
		};

		$scope.delete = function () {
			if ($scope.model.VersionStatus !== 'Draft') return;

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlList-message', "Kontrollisten vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlList-button-confirm', 'Ja, fjern kontrollisten'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.controlList.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-controlList-success', 'Kontrollisten ble fjernet!'), result, "success");
					$scope.goBack('controlList.list', { menuGuid: $scope.navigation.params.menuGuid });
				}, function (error) {
					var swalMessage = error.Message;
					if (error.IsDeactivateable) {
						swalMessage += '<br/><br/>Vil du deaktivere kontrollisten?';
					}
					swal({
						title: translationService.translate('web-swal-controlList-delete-error-title', 'Kunne ikke slette kontrollisten!'),
						text: swalMessage,
						type: "error",
						html: true,
						showCancelButton: true,
						confirmButtonText: error.IsDeactivateable ? "Deaktiver" : "Ok",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					}, function (isConfirm) {
						window.onkeydown = null;
						window.onfocus = null;
						if (isConfirm) {
							$scope.model.IsDeactivated = true;
							$scope.model.Name = '#' + $scope.model.Name;
							$scope.update('controlList.list');
						}
					});
				});
			});
		};

		$scope.openPreview = function (usePreviousEntity) {
			function openPreview() {
				var tempControlListItemAnswers = [];
				$scope.model.ControlListItems.forEach(function (item) {
					tempControlListItemAnswers.push({
						Guid: planiaUtils.randomString(16),
						User: {
							RealName: $scope.userInfo.realName
						},
						UpdatedDate: new Date().toISOString(),
						GuidControlListItem: item.Guid,
						ControlListItem: item,
						LinkedDeviations: [],
						LinkedRequests: []
					});
				});

				var workOrder = $scope.previewControlListXEntityObject.WorkOrder;
				if (!workOrder)
					workOrder = { Caption: "Forhåndsvisning" };

				$modal.open({
					templateUrl: 'app/controlList/views/controlListCompletionModal.html',
					size: 'lg',
					controller: 'ControlListCompletionModalController',
					resolve: {
						params: function () {
							return {
								controlList: $scope.model,
								controlListXEntity: $scope.previewControlListXEntityObject,
								controlListRules: $scope.model.ControlListRules,
								controlListItemAnswers: tempControlListItemAnswers,
								workOrder: workOrder,
								workOrderCaption: workOrder.Caption,
								isPreview: true
							};
						}
					}
				});
			}

			if (usePreviousEntity && $scope.previewControlListXEntityObject) {
				openPreview();
			} else {
				$modal.open({
					templateUrl: 'app/controlList/views/controlListPreviewEntitySelectionModal.html',
					size: 'md',
					controller: 'ControlListPreviewEntitySelectionModalController',
					resolve: {
						params: function () {
							return {
								controlList: $scope.model
							};
						}
					}
				}).result.then(function (result) {
					$scope.previewControlListXEntityObject = {
						GuidControlList: $scope.model.Guid,
						Status: "New"
					};

					if (result.workOrder) {
						$scope.previewControlListXEntityObject.GuidWorkOrder = result.workOrder.Guid;
						$scope.previewControlListXEntityObject.WorkOrder = result.workOrder;
					}

					if (result.equipment) {
						$scope.previewControlListXEntityObject.GuidEquipment = result.equipment.Guid;
						$scope.previewControlListXEntityObject.Equipment = result.equipment;
					}

					if (result.area) {
						$scope.previewControlListXEntityObject.GuidArea = result.area.Guid;
						$scope.previewControlListXEntityObject.Area = result.area;
					}

					openPreview();
				}, function () {
					//cancel
				});
			}
		};

		$scope.saveCurrentItem = function (thenFunction) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			// No selected form item, or the selected form item is not changed
			if (!$scope.selectedFormItem || angular.equals($scope.selectedFormItem, $scope.copyOfSelectedItem)) {
				if (thenFunction)
					thenFunction();
				return;
			}

				var objectToSave = angular.copy($scope.selectedFormItem);
			if (objectToSave.Data)
				objectToSave.Data = JSON.stringify(objectToSave.Data);

				if (objectToSave.ControlListLogItem && objectToSave.ControlListLogItem.Id) {
					objectToSave.ControlListLogItem.Id = objectToSave.ControlListLogItem.Id.trim();
				}

				repository.updateSingle(repository.apiData.controlListItem.url, objectToSave).then(function (result) {
					if (thenFunction)
						thenFunction();
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
		};

		$scope.copyControlList = function () {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCopyModal.html',
				controller: 'ControlListCopyController',
				resolve: {
					params: function () {
						return {
							model: angular.copy($scope.model)
						};
					}
				}
			}).result.then(function (result) {
				result.GuidCopyFrom = $scope.model.Guid;
				repository.createSingle(repository.apiData.controlList.url, result).then(function (response) {
					$scope.navigation.go('controlList.edit', { guid: response.Guid });
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});
		};

		$scope.takeDraft = function () {
			if ($scope.model.VersionStatus !== "Published")
				return;

			$modal.open({
				templateUrl: 'app/controlList/views/controlListCreateDraftModal.html',
				size: 'md',
				controller: 'ControlListCreateDraftModalController',
				resolve: {
					params: function () {
						return {
							guids: [$scope.model.Guid]
						};
					}
				}
			}).result.then(function () {
				$scope.goBack('controlList.edit', { guid: $scope.model.Guid });
			}, function () {
				//cancel
			});
		};

		$scope.publishDraft = function () {
			if ($scope.model.VersionStatus !== "Draft")
				return;

			swal({
				title: translationService.translate('web-controlList-publishDraft-title', 'Vil du publisere utkastet?'),
				text: translationService.translate('web-controlList-publishDraft-text', 'Utkastet vil bli tilgjengelig til bruk.'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#008094",
				confirmButtonText: translationService.translate('web-controlList-publishDraft-confirm', 'Ja'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.runAction(repository.apiData.controlList.endpoint.publish + $scope.model.Guid, $scope.model, null, true).then(function (response) {
					repository.growl(translationService.translate('web-controlList-publishDraft-success', 'Utkastet er publisert'), "success");
					$scope.goBack('controlList.edit', { guid: $scope.model.Guid });
					swal.close();
				}, function (error) {
					repository.showError(error);
					swal.close();
		});
			});
		};

		$scope.revertDraft = function () {
			if ($scope.model.VersionStatus !== "Draft")
				return;

			swal({
				title: translationService.translate('web-controlList-revertDraft-title', 'Forkast alle endringer i utkastet?'),
				text: translationService.translate('web-controlList-revertDraft-text', 'Alle endringer for gjeldende utkast vil forsvinne med ingen muligheter å gjennopprette.'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-controlList-revertDraft-confirm', 'Ja'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.runAction(repository.apiData.controlList.endpoint.revertDraft + $scope.model.Guid).then(function (response) {
					repository.growl(translationService.translate('web-controlList-revertDraft-success', 'Utkastet er reversert til sist publisert'), "success");
					swal.close();
					$scope.goBack('controlList.edit', { guid: $scope.model.Guid });
				}, function (error) {
					repository.showError(error);
					swal.close();
		});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
