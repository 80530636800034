(function () {

	angular.module('Plania').controller('ScheduledJobController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'TranslationService', 'authService', 'ListService', '$interval', '$localStorage', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, translationService, authService, listService, $interval, $localStorage) {
		$scope.isUpdate = $scope.navigation.current.name === 'scheduledJob.edit';
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.model = { Guid: $stateParams.guid };
		$scope.originalModel = angular.copy($scope.model);
		$scope.activateAutocomplete = false;
		// to hold autocomplete/lookup values
		$scope.viewModel = {};

		$scope.toggleViewMode = function() {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var systemJobNames = [
			"UpdateWorkOrderSums",
			"UpdateProjectSums",
			"UpdatePlaniaEconomyFields",
			"UpdateGeneralOptionsCache",
			"UpdateCustomerExpectedAnonymizationDates",
			"UpdateCleaningTasks",
			"UpdateAccessDomainCache",
			"TwoFactorTokenMaintenance",
			"SendUsageReportToPlania",
			"SendSettingInformationToPlania",
			"SendLicenseToPlania",
			"RemoveOverdueExitCleaningTask",
			"RemoveExpiredRefreshTokens",
			"RemoveExpiredJobExecution",
			"FtpConnectorRegistration",
			"FileConnectorRegistration",
			"DeleteUnusedHousingCustomers",
			"BimsyncPoller",
			"AnonymizeHousingCustomers",
			"AggregateCleaningCompletions"
		];

		var updateAccess = function() {
			if ($scope.isUpdate) {
				var checkOtherDo = false;
				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.ScheduledJob, checkOtherDo);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.ScheduledJob, checkOtherDo);
				if ($scope.model && $scope.model.Type) {
					var isAllowedDeletion = false;
					if ($scope.model.Type === 'FtpConnector' || $scope.model.Type === 'AzureADSync' || $scope.model.Type === 'MedicalClassificationExport' || $scope.model.Type === 'FileConnector')
						isAllowedDeletion = true;
					$scope.restrictDelete = !isAllowedDeletion;
				}
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.ScheduledJob);
			}
		};

		var getFromServer = function() {
			repository.getSingle(repository.apiData.scheduledJob.url, $stateParams.guid).then(
				function(response) {
					$scope.model = response.Data;
					if ($scope.model) {
						$scope.model.Params = JSON.parse($scope.model.Parameters);
						if ($scope.integrationDataJobIds) {
							$scope.selectedJobId = _.find($scope.integrationDataJobIds, { Id: $scope.model.Params.JobId });
						}
					}
					updateAccess();
					$scope.isSystemJob = systemJobNames.includes($scope.model.Id);
					$scope.reload = true;
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
					$scope.originalModel = angular.copy($scope.model);
				},
				function(error) {
					repository.growl(error, 'danger');
				}
			);
		};

		var initNew = function () {
			updateAccess();

			if ($stateParams.scheduledJobModel) {
				$scope.model = $stateParams.scheduledJobModel;
			} else {
				repository.growl(translationService.translate('web-scheduledJob-create-missingModel', 'Du må velge type bakgrunnsjobb for å kunne opprette ny'));
				$scope.navigation.go('scheduledJob.list', { menuGuid: $scope.navigation.params.menuGuid });
			}
		};

		if ($stateParams.guid) {
			getFromServer();
		} else {
			initNew();
			$scope.originalModel = angular.copy($scope.model);
		}

		repository.getWithUrlParameter(repository.apiData.integrationDataJobId.url).then(
			function (response) {
				$scope.integrationDataJobIds = response.Data;
				if ($scope.model.Params) {
					$scope.selectedJobId = _.find($scope.integrationDataJobIds, { Id: $scope.model.Params.JobId });
				}
			},
			function (error) {
				repository.growl(error, 'danger');
			}
		);

		$scope.setSelectedJobId = function(jobId) {
			$scope.model.Params.JobId = jobId.Id;
			$scope.selectedJobId = jobId;
		};


		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl(translationService.translate('web-swal-scheduledJob-savedOk', 'Bakgrunnsjobben er lagret'), 'success');
				$scope.goBack(destination, { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
			};
			var error = function(error) {
				repository.growl(error, 'danger');
			};

			var modelToSave = angular.copy($scope.model);
			modelToSave.Parameters = JSON.stringify(modelToSave.Params);
			delete modelToSave.Params;

			if ($scope.isUpdate)
				repository.updateSingle(repository.apiData.scheduledJob.url, modelToSave).then(success).catch(error);
			else
				repository.createSingle(repository.apiData.scheduledJob.url, modelToSave).then(success).catch(error);
		};

		$scope.delete = function() {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-scheduledJob-message', 'Bakgrunnsjobben vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-scheduledJob-button-confirm', 'Ja, slett bakgrunnsjobben!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.scheduledJob.url, $scope.model.Guid)
					.then(function (result) {
						swal('Suksess!', translationService.translate('web-swal-scheduledJob-success', 'Bakgrunnsjobben ble fjernet!'), "success");
						repository.growl('Bakgrunnsjobben har blitt fjernet', 'success');
						$scope.goBack('scheduledJob.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
				});
		};

		$scope.runJob = function () {
			if ($scope.isSavingRunJob) return;

			var requestBody = {
				GuidScheduledJob: $scope.model.Guid
			};
			$scope.isSavingRunJob = true;
			repository.createSingle(repository.apiData.scheduledJobStart.url, requestBody).then(function (result) {
				$scope.executionTable.reload();
				repository.growl(translationService.translate('web-swal-scheduledJobExecution-savedOk', 'Kjøring av bakgrunnsjobben er startet'), 'success');
				$scope.isSavingRunJob = false;
			}).catch(function (error) {
				$scope.isSavingRunJob = false;
				repository.growl(error, 'danger');
			});
		};

		$scope.executionColumns = [
			{ Position: 1, Title: translationService.translate('web-scheduledJobExecution-jobId', 'Jobb ID'), Property: 'JobId', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-scheduledJobExecution-creationDate', 'Opprettet'), Property: 'CreationDate', PropertyType: 'date' },
			{ Position: 3, Title: translationService.translate('web-scheduledJobExecution-updatedDate', 'Oppdatert'), Property: 'UpdatedDate', PropertyType: 'date' },
			{ Position: 4, Title: translationService.translate('web-scheduledJobExecution-status', 'Status'), Property: 'Status', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-scheduledJobExecution-logOutput', 'Logg'), Property: 'LogOutput', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-scheduledJobExecution-userCreatedBy-realName', 'Startet av'), Property: 'UserCreatedBy.RealName', PropertyType: 'string' }
		];

		$scope.executionTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { CreationDate: 'desc' },
				filter: { PropertyFilter: [{ Property: 'GuidScheduledJob', Operator: '=', value: $scope.model.Guid }] }
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var columns = _.map($scope.executionColumns, 'Property');

					repository.GetPaginated(repository.apiData.scheduledJobExecution.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
						.then(function (result) {
							$scope.executionTable.settings().total = result.TotalCount;
							$scope.executionTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.goToExecutionEdit = function (execution) {
			$scope.navigation.go('scheduledJobExecution.edit', { guid: execution.Guid }, { reload: true });
		};

		var reloadTableInterval = $interval(function () {
			if ($scope.isUpdate) {
				$scope.executionTable.reload();
			}
		}, 10000);

		$scope.$on('$destroy', function () {
			if (angular.isDefined(reloadTableInterval)) {
				$interval.cancel(reloadTableInterval);
			}
		});

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.hstep = 1;
		$scope.mstep = 5;

		$scope.synchronizePersonWithLdap = $localStorage.generalOptions.SynchronizePersonWithLdap;

		$scope.azureSyncDropdownOptions = {
			ldapGuid: ["Id"],
			username: ["UserPrincipalName", "OnPremisesSamAccountName", "OnPremisesUserPrincipalName", "MailNickname", "Mail", "OtherMails"],
			realName: ["DisplayName"],
			userEmail: ["Mail", "OtherMails"],
			phoneNumber: ["BusinessPhones", "MobilePhone"],
			ldapDn: ["OnPremisesDistinguishedName"],
			firstName: ["GivenName"],
			lastName: ["Surname"],
			personEmail: ["Mail", "OtherMails"],
			telephoneWork: ["BusinessPhones", "MobilePhone"],
			cellphone: ["BusinessPhones", "MobilePhone"],
			uniquePlaniaFields: ["Username", "Email", "RealName", "PhoneNumber"],
			uniqueAzureFields: ["UserPrincipalName", "OnPremisesSamAccountName", "OnPremisesUserPrincipalName", "MailNickname", "Mail", "OtherMails", "DisplayName", "BusinessPhones", "MobilePhone"],
		};
	}
})();
