(function () {
	angular.module('Plania').controller('EmailModalController', ['$scope', '$modalInstance', '$modal', 'params', 'TranslationService', 'DocumentService', 'Repository', '$q', '$sce', '$filter', '$localStorage', '$window', '$timeout', controller]);

	function controller($scope, $modalInstance, $modal, params, translationService, documentService, repository, $q, $sce, $filter, $localStorage, $window, $timeout) {
		var entity = params.entity;
		$scope.translatedEntity = translationService.translate('web-enum-prefix-' + entity);
		$scope.model = params.model;
		$scope.emailModel = params.model; // For validation and fill out missing values
		$scope.isInitiallyLoadingAttachments = true;

        // { Attachments: [] }
        var availableAttachments = [];
		$scope.attachments = [];
		$scope.emailTemplates = [];
		$scope.isInitialized = true;

		$scope.reportPreviewItems = params.reportPreviewItems;

		$scope.email = params.email || {
			to: [],
			copy: [],
			body: '',
			subject: ''
		};

		if (params.prefillSubjectWithCaption) $scope.email.subject = $scope.translatedEntity + " " + $scope.getEntityCaption($scope.model);

		// Two different event methods to update the modals since we cannot be sure that the original input of attachments are updated via reference.
		// Event to signal that a new document / image has been created. Does not expect any in return.
		var onNewDocument = params.onNewDocument;
		var onNewImage = params.onNewImage;

		if (!$scope.email.from && $localStorage.userData.email) {
			var fromEmail = $localStorage.userData.email;
			if (fromEmail.includes('<') && fromEmail.includes('>')) {
				$scope.email.from = fromEmail;
			} else {
				$scope.email.from = $localStorage.userData.realName + " <" + $localStorage.userData.email + ">";
			}
		}

		var camelCasePrefix = _.camelCase(entity);

		var url = repository.apiData[camelCasePrefix].url;

        var fetchAttachments = function () {
            return repository.getWithUrlParameter(repository.apiData.email.url + "getAvailableAttachmentsForEntity/" + $scope.model.Guid).then(function (response) {
                availableAttachments = response.Attachments;
				$scope.isInitiallyLoadingAttachments = false;
				return availableAttachments;
            }, function (error) {
                repository.showError(error);
				$scope.isInitiallyLoadingAttachments = false;
				return;
            });
        };

		fetchAttachments(true).then(function () {
			availableAttachments.forEach(function (attachment) {
				if (attachment.IsDefaultAttached)
					$scope.attachments.push(attachment);
			});
		});

		var getEmailTemplates = function () {
			repository.GetPaginated(repository.apiData.emailTemplate.url, 0, 50, { Id: 'asc' }, { PropertyFilter: [{ Property: 'EntityType', Operator: '=', Value: entity }] }).then(function (result) {
				$scope.emailTemplates = result.List;

				if ($scope.emailTemplates.length > 0) {

					$scope.selectedEmailTemplate = getDefaultTemplate();
					$scope.selectedTemplateChanged($scope.selectedEmailTemplate);
				}
			});
		};

		getEmailTemplates();

		var getDefaultTemplate = function () {
			var defaultTemplate = _.find($scope.emailTemplates, { IsDefault: true });
			if (!defaultTemplate) {
				defaultTemplate = _.find($scope.emailTemplates, function (o) {
					return o.Id === 'Plania Standard';
				});
			}

			return defaultTemplate;
		};

		$scope.selectedTemplateChanged = function (newValue) {
			$scope.setEmailBodyWithValues(newValue.TemplateBody);
		};

		$scope.loadTags = function (query) {
			var deferred = $q.defer();

			repository.GetPaginated(repository.apiData.emailContact.url, 0, 20, { Email: 'asc' }, { searchString: query, PropertyFilter: [{ Property: 'Email', Operator: '<>', Value: '' }] }).then(function (result) {
                deferred.resolve(result);
			});

			return deferred.promise;
		};

		$scope.tinymceOptions = {
			height: 350,
			language: 'nb_NO',
			branding: false,
			plugins: [
				'advlist autolink lists link image charmap print preview hr anchor pagebreak',
				'searchreplace visualblocks visualchars code codesample',
				'insertdatetime media nonbreaking save table contextmenu directionality',
				'emoticons template paste textcolor colorpicker textpattern imagetools toc help'
			],
			toolbar: 'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright | bullist numlist outdent indent',
			image_advtab: true,
			paste_data_images: true,
			relative_urls: false,
			convert_urls: false,
			templates: [
				{ title: 'Test template 1', content: 'Test 1' },
				{ title: 'Test template 2', content: 'Test 2' }
			],
			inline_styles: true,
			font_formats: 'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Calibri=calibri;Comic Sans MS=comic sans ms,sans-serif;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;',
			setup: function (editor) {
			}
		};

		$scope.setEmailBodyWithValues = function (string) {
			if (!string) {
				$scope.email.body = '';
				return;
			}

			var properties = string.match(/{{\s*[\w\.]+\s*}}/g);
			var userProperties = string.match(/{{\@\s*[\w\.]+\s*}}/g);
			var customProperties = string.match(/{{\#\s*[\w\.]+\s*}}/g);

			if (!properties)
				properties = [];

			var mappedProperties = properties.map(function (x) { return x.match(/[\w\.]+/)[0]; });
			var dottedColumns = _.filter(mappedProperties, function (o) { return o.indexOf('.') !== -1; });

			var tmpString = string;

			if (checkNestedProperties(dottedColumns)) {
				setEmailBody(tmpString, properties, mappedProperties, userProperties, customProperties);
			} else {
				repository.getSingle(url, $scope.model.Guid, JSON.stringify(dottedColumns)).then(function (response) {
					$scope.emailModel = response.Data;

					setEmailBody(tmpString, properties, mappedProperties, userProperties, customProperties);
				});
			}
		};

		var customPropertyFunctions = [
			{
				property: '#web_base_url',
				getValue: function () { return window.location.protocol + '//' + window.location.host; }
			}
		];

		function setEmailBody (tmpString, properties, mappedProperties, userProperties, customProperties) {
			for (var i = 0; i < properties.length; i++) {
				tmpString = tmpString.replace(properties[i], getPropertyValue(mappedProperties[i]));
			}

			if (userProperties && userProperties.length > 0) {
				var mappedUserProperties = userProperties.map(function (x) { return x.match(/[\@\w\.]+/)[0]; });

				for (var j = 0; j < userProperties.length; j++) {
					tmpString = tmpString.replace(userProperties[j], $scope.userInfo[mappedUserProperties[j].replace('@CurrentUser.', '')]);
				}
			}

			if (customProperties && customProperties.length > 0) {
				var mappedCustomProperties = customProperties.map(function (x) { return x.match(/[\#\w\.]+/)[0]; });
				for (var k = 0; k < customProperties.length; k++) {
					var prop = _.find(customPropertyFunctions, { property: mappedCustomProperties[k] });
					if (prop) {
						tmpString = tmpString.replace(customProperties[k], prop.getValue());
					}
				}
			}

			// #22293 tmpString is vulnerable to XSS, cannot use built in sanitizer as it will remove tinyMce styles
			$scope.email.body = $sce.trustAsHtml(tmpString);
		}

		function getPropertyValue (property) {
			var properties = property.split('.');
			var propertyValue = $scope.emailModel;

			properties.forEach(function (prop) {
				if (propertyValue) {
					propertyValue = propertyValue[prop];
				}
			});

			if (typeof (propertyValue) === 'string' && isNaN(Number(propertyValue)) && moment(propertyValue).isValid()) {
				propertyValue = $filter('date')(propertyValue, 'dd.MM.yyyy');
			}

			if (typeof (propertyValue) === 'string')
				propertyValue = propertyValue.replace(/(?:\r\n|\r|\n)/g, '<br />');

			return propertyValue ? propertyValue : '';
		}

		function hasValue(testModel, prop, isLastProperty) {
			if (!testModel || !testModel.hasOwnProperty(prop))
				return false;

			// If we are on the last property, we should check if any values are not a default value.
			// Ex fetching a part of Area via WorkOrder might return 0 for number fields despite it having an acutal value.
			if (isLastProperty && typeof (testModel) === "object") {
				var value = testModel[prop];
				if (value === 0)
					return false;
				if (value === "Default")
					return false;
				if (value === "")
					return false;
			}

			return true;
		}

		function checkNestedProperties (dottedColumns) {
			try {
				for (var i = 0; i < dottedColumns.length; i++) {
					var testModel = $scope.emailModel;
					var splittedProperties = dottedColumns[i].split('.');
					for (var j = 0; j < splittedProperties.length; j++) {
						var isLastProperty = j === splittedProperties.length - 1;
						var prop = splittedProperties[j];
						if (!hasValue(testModel, prop, isLastProperty))
							return false;
						testModel = testModel[splittedProperties[j]];
					}
				}
				return true;
			}
			catch (err) {
				return false;
			}
		}

		$scope.send = function () {
			if ($scope.email.to.length < 1) {
				swal("Manglende mottaker!", "Minst 1 mottaker må velges", "info");
				$scope.showToErrorMessage = true;
				return;
			} else {
				$scope.showToErrorMessage = false;
			}

            var attachments = $scope.attachments.map(function (a) {
                return a.Guid;
            }).join(",");

			if (!$scope.email.subject) $scope.email.subject = $scope.getEntityCaption($scope.model);
			if (typeof $scope.email.body === 'object') $scope.email.body = $scope.email.body.toString();

			var postEmail = {
				GuidEntity: $scope.model.Guid,
				EmailTo: _.map($scope.email.to, 'Email').join(';'),
				EmailCopyTo: _.map($scope.email.copy, 'Email').join(';'),
				EmailSubject: $scope.email.subject,
				EmailBody: $scope.email.body,
				EmailFrom: $scope.email.from,
				Attachments: attachments,
				Status: 'Outbox'
			};

			if (params.returnEmailObject) {
				$modalInstance.close(postEmail);
			}
			else {
				repository.createSingle(repository.apiData.alarmLog.url, postEmail).then(function (result) {
					swal("Suksess!", "Eposten ble lagt til i utboksen", "success");
					$modalInstance.close(result);
				},
					function (error) {
						swal("Feil!", error, "error");
					});
			}
		};

		$scope.isEmptyGuid = function (guid) {
			return guid === "00000000-0000-0000-0000-000000000000";
		};

		$scope.onTagAdded = function (tag) {
			if (!tag.Email) {
				tag.Email = tag.Name;
            }

            if (!tag.DisplayProperty) {
                tag.DisplayProperty = tag.Name + new Date().valueOf();
            }
        };

		$scope.$watch('email.to', function (newValue, oldValue) {
			$scope.showToErrorMessage = false;
		});

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

        $scope.addAttachments = function () {
            $modal.open({
                templateUrl: 'app/common/modal/views/selectAttachmentModal.html',
                controller: 'SelectAttachmentModalController',
                size: 'lg',
                resolve: {
					params: function () {
						return {
							entityType: entity,
							guid: $scope.model.Guid,
							availableAttachments: availableAttachments,
							selectedAttachmentGuids: _.map($scope.attachments, function (o) { return o.Guid; }),
							onNewAttachment: fetchAttachments,
							onNewDocument: onNewDocument,
							onNewImage: onNewImage,
						};
					}
				}
            }).result.then(function (selectedAttachments) {
                selectedAttachments.forEach(function (attachment) {
                    if (!_.find($scope.attachments, { Guid: attachment.Guid }))
                        $scope.attachments.push(attachment);
                });
			});
		};

        $scope.openDocument = function (attachment) {
            if (attachment.Type === "Document") {
                documentService.openDocument({
                    Guid: attachment.Guid,
                    FilePath: attachment.FilePath
                });
            }
		};

		$scope.previewReport = function(reportId){
			$localStorage.reportParams = JSON.stringify({
				Guid: $scope.model.Guid,
				DisableAutomaticPrint: true
			});

			var url = $scope.navigation.href('report.print', { reportName: reportId });
			$timeout(function () {
				$window.open(url, '_blank');
			}, 100);
		};
	}
})();
