(function () {
	var app = angular.module('Plania');

	app.directive('plEnumDropdown', function ($compile) {
		return {
			restrict: 'E',
			scope: {
				enumType: '=enumType',
				selectedValue: '=selectedValue',
				selectedValueProperty: '=selectedValueProperty', //Value, StringValue or TranslatedValue
				limitEnumOptions: '=limitEnumOptions',
				includeUndefined: '=includeUndefined',
				multiple: '=multiple',
				onChange: '=onChange',
				ngDisabled: '=ngDisabled',
				disableSearch: '=disableSearch'
			},
			controller: ['$scope', 'EnumService', controller],
			templateUrl: 'app/common/directives/views/plEnumDropdown.html',
			compile: function compile(element, attrs) {
					var select = element.find('select');

					if (select) {
						if (attrs.multiple) {
							select.attr('multiple', 'true');
					}
				}
			}
		};
	});

	function controller($scope, enumService) {
		$scope.internalValue = $scope.multiple ? [] : "";
		// NgDisabled disables field if it is undefined.
		if (!$scope.ngDisabled) $scope.ngDisabled = false;
		if ($scope.disableSearch === undefined) $scope.disableSearch = true;

		if (!$scope.selectedValueProperty) {
			$scope.selectedValueProperty = 'Value';
		}

		var limitEnumOptions = function (enumOptions) {
			var options = enumOptions.slice(0);
			if (!$scope.limitEnumOptions)
				return options;

			var validOptions = [];
			if ($scope.limitEnumOptions === 'PeriodicTask.Period') {
				validOptions = ['Day', 'Week', 'Month', 'Year', 'HourOfUse'];
			}
			if ($scope.limitEnumOptions === 'PeriodicTask.deadlinePeriodUnit') { //also used by priority (priority.html)
				validOptions = ['Day', 'Week', 'Month', 'Year'];
			}
			if ($scope.limitEnumOptions === 'CleaningTask.TimePeriodUnit') {
				validOptions = ['Day', 'Week', 'Month'];
			}
			if ($scope.limitEnumOptions === 'GeneralOptions.ContractTypeRentalPeriod')
				validOptions = ['Day', 'Week', 'Month', 'Year', 'Hour', 'Once', 'Undefined', 'Udefinert'];
			if ($scope.limitEnumOptions === 'Deviation.ClosedReason')
				validOptions = ['Duplicate', 'AsExpected', 'WillNotBeSolved', 'NotRelevant'];
			if ($scope.limitEnumOptions === 'DataOwner.EnergyPeriodUnit' || $scope.limitEnumOptions === 'DataOwner.ActivityPeriodUnit')
				validOptions = ['Day', 'Week', 'Month', 'Year', 'Hour', 'Once'];
			if ($scope.limitEnumOptions === 'ComponentXSupplier.DeliveryTimePeriodUnit')
				validOptions = ['Undefined', 'Day', 'Week', 'Month', 'Year', 'Hour'];

			if (validOptions.length > 0) {
				_.remove(options,
					function (option) {
						return !validOptions.some(function (o) { return o === option.StringValue; });
					});
				return options;
			}

			return options;
		};

		$scope.applyChange = function (newValue) {
			$scope.selectedValue = newValue;
			if ($scope.onChange && typeof $scope.onChange === 'function') {
				$scope.onChange(newValue);
			}
        };

		var findValue = function (val) {
			if (!$scope.enumSelectOptions) return val;
			var selectedEnumValue = _.find($scope.enumSelectOptions, function (enumValue) {
				var isFound = enumValue[$scope.selectedValueProperty].toString() === val.toString();
				if (!isFound) {
					isFound = enumValue[$scope.selectedValueProperty === "StringValue" ? "Value" : "StringValue"].toString() === val.toString();
				}
				return isFound;
			});
			if (!selectedEnumValue) return val;
			return selectedEnumValue[$scope.selectedValueProperty];
		};

        var setInternalValue = function (newValue) {
            var internalValue = $scope.multiple ? [] : "";
			if (!newValue) {
                $scope.internalValue = internalValue;
				return;
			}

			if ($scope.multiple) {
				if (newValue === null || newValue === undefined) {
                    internalValue = [];
				} else if (Array.isArray(newValue)) {
                    internalValue = newValue;
				} else if (typeof newValue === "string") {
                    internalValue = newValue.split(",");
				} else {
                    internalValue = newValue.toString().split(",");
				}
			} else {
                internalValue = newValue;
            }

            // Remap in case the input value is not in the correct format (StringValue vs Value (as int))
            if ($scope.enumSelectOptions && Array.isArray($scope.enumSelectOptions)) {
                if (Array.isArray(internalValue)) {
                    internalValue = internalValue.map(function (val) {
                        return findValue(val) || val;
                    });
                } else {
                    internalValue = findValue(internalValue) || internalValue;
                }
            }

            $scope.internalValue = internalValue;
		};

		setInternalValue($scope.selectedValue);

		$scope.$watch('selectedValue', function (newVal) {
			setInternalValue(newVal);
		});

		if ($scope.enumType) {
			enumService.GetEnumValues($scope.enumType).then(function (result) {
				var enumSelectOptions = limitEnumOptions(result);

				if (!$scope.includeUndefined) {
					_.remove(enumSelectOptions, function (option) {
						return option.StringValue === 'Undefined' || option.StringValue === 'Udefinert';
					});
				}

				if ($scope.enumType && ($scope.enumType.toLowerCase() === 'prefix' || $scope.enumType.toLowerCase() === 'documentationcontext')) {
					enumSelectOptions.sort(function (a, b) {
						if (a.TranslatedValue < b.TranslatedValue) { return -1; }
						if (a.TranslatedValue > b.TranslatedValue) { return 1; }
						return 0;
					});
				}

				$scope.enumSelectOptions = enumSelectOptions.map(function (opt) {
					return {
						Value: opt.Value,
						StringValue: opt.StringValue,
						TranslatedValue: opt.TranslatedValue,
					};
                });

                setInternalValue($scope.selectedValue);
			});
		}
	}

})();
