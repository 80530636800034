//This directive displays a table of documents and a add button for adding to specified document_x_object with correct column data.
(function () {

	var app = angular.module('Plania');

	app.directive('dynamicProperties', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=model',
				isCollapsed: '=isCollapsed',
				guidProperty: '=guidProperty',
				newDynamicProperty: '=newDynamicProperty',
				autoCompleteProperty: '=autoCompleteProperty',
				viewMode: '=viewMode',
				restrictEdit: '=restrictEdit',
				delayMandatoryFields: '=delayMandatoryFields'
			},
			controller: ['$scope', '$localStorage', '$modal', '$filter', '$sce', controller],
			templateUrl: 'app/common/directives/dynamicProperty/dynamicPropertyDirective.html'
		};
	});

	function controller($scope, $localStorage, $modal, $filter, $sce) {
		$scope.isExternalUser = $localStorage.userData.isExternalUser;
		$scope.isCollapsed = $localStorage.dynamicPropertiesIsCollapsed;

		$scope.toggleCollapsed = function () {
			$scope.isCollapsed = !$scope.isCollapsed;
			$localStorage.dynamicPropertiesIsCollapsed = $scope.isCollapsed;
		};

		var reinitDynamicFieldDefaultValues = function () {
			if (!$scope.model || !$scope.model.DynamicProperty) return;
			$scope.model.DynamicProperty.Groups.forEach(function (group) {
				group.Items.forEach(function (item) {
					if (item.Type === 'Combo') {
						var selectedOption = _.find(item.NamedSelectionOptions,
							function (option) {
								return option === $scope.model[item.PropertyName];
							});

						if (!selectedOption) {
							if (item.NamedSelectionDefaultOption)
								$scope.model[item.PropertyName] = item.NamedSelectionDefaultOption;
							else {
								$scope.model[item.PropertyName] = null;
							}
						}
					}
				});
			});
		};

		var oldPropertyValue = $scope.model[$scope.guidProperty];
		var oldAutoCompleteValue = $scope.autoCompleteProperty;

		$scope.getPropertyValue = function(property)		{
			var value = $scope.model[property.PropertyName];
			if (!value) return value;
			if (property.Type === 'Date') return $filter('date')(value, 'dd.MM.yyyy');
			else if (property.Type === 'Combo' && property.NamedSelectionMultiValue && value.startsWith('[') && value.endsWith(']')) {
				
				var list = JSON.parse(value);
				var html = "<div class ='chosen-container chosen-container-multi'>";
				html += "<ul class='chosen-choices chosen-choices-readonly'>";
				_.forEach(list, function(item){
					html += "<li class='search-choice search-choice-readonly' >" + $sce.getTrustedHtml(item) + "</li>";
				});
				html += "</ul>";
				html += "</div>";
				return $sce.trustAsHtml(html);
			}
			else {
				return value;
			}
		};

		$scope.$watch('model.' + $scope.guidProperty,
			function (newValue, oldValue) {
				if (newValue === oldValue) return;
				if (newValue === oldPropertyValue) return;
				if (oldPropertyValue === undefined) {
					// either existing entity is just being loaded, or category is just being set for the first time on a new entity
					oldPropertyValue = newValue;
					oldAutoCompleteValue = $scope.autoCompleteProperty;
					// reinit fields only if the entity did not have a valid category (=new entity)
					if (!$scope.model.DynamicProperty || $scope.model.DynamicProperty.Groups.length <= 0) {
						$scope.model.DynamicProperty = $scope.newDynamicProperty;
						reinitDynamicFieldDefaultValues();
                    }
                    return;
				}

				if ($scope.model.DynamicProperty && $scope.model.DynamicProperty.Groups.length > 0) {
					$modal.open({
						templateUrl: 'app/common/views/confirmationBox.html',
						controller: 'ConfirmationBoxController',
						resolve: {
							confirmation: function () {
								return {
									header: "Bekreft handling",
									message:
										"Du endrer en kategori som har egendefinerte felter, ønsker du å fortsette?"
								};
							}
						}
					}).result.then(function () {
						oldPropertyValue = newValue;
						oldAutoCompleteValue = $scope.autoCompleteProperty;
						$scope.model.DynamicProperty = $scope.newDynamicProperty;
						reinitDynamicFieldDefaultValues();
					},
						function () {
							$scope.model[$scope.guidProperty] = oldPropertyValue;
							$scope.autoCompleteProperty = oldAutoCompleteValue;
						});
				} else {
					oldPropertyValue = newValue;
					oldAutoCompleteValue = $scope.autoCompleteProperty;
					$scope.model.DynamicProperty = $scope.newDynamicProperty;
					reinitDynamicFieldDefaultValues();
				}
			});
	}

})();
