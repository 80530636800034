(function () {
    var app = angular.module('Plania');

    app.directive('plLinkWidget', ['TranslationService', function (translationService) {
        return {
            restrict: 'A',
            require: '^plDashboardGrid',
            scope: {
                widget: '=widget',
                edit: '=edit',
                saveFunction: '=saveFunction'
            },
            link: function (scope, element, attrs, gridCtrl) {
                scope.$parent.attachWidget(element, attrs);

                scope.removeWidget = function () {
                    swal({
                        title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                        text: translationService.translate('web-swal-dashboard-link-message', "Linkene vil bli permanent fjernet!"),
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f44336",
                        confirmButtonText: translationService.translate('web-swal-dashboard-link-confirm', 'Ja, fjern linkene'),
                        cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
                        closeOnConfirm: false
                    }, function () {
                        window.onkeydown = null;
                        window.onfocus = null;
                        swal(translationService.translate('web-swal-dashboard-link-success', 'Linkene ble fjernet!'), '', "success");
                        scope.$parent.removeWidget(element, attrs);
                    });
                };
            },
            templateUrl: 'app/dashboard/directives/widgets/linkWidget.html',
			controller: ['$scope', '$modal', safeController],
        };
	}]);

	function safeController($scope, modal) {
		try {
			controller($scope, modal);
		} catch (error) {
			console.log(error);
		}
	}

	function controller($scope, modal) {
		$scope.model = $scope.widget;
		$scope.navigate = function (item) {
			var destination = '';
			$rootScope.navigation.go(destination, { guid: item.Guid }, { reload: true });
		};

		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false);
			}, function () {
				//Dismissed
			});
		};
	}

    app.directive('linkTarget', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var href = element.href;
                if (scope.link.IsExternalLink) {
                    element.attr("target", "_blank");
                }
            }
        };
    });
})();
