(function () {

	/*
		Plania Entity Navigation
		Directive used to insert a hyperlink to plania entity based on if the user has read rights or not.
		Inserts normal text if user does not have read access to entity.
		*/
	angular.module('Plania').directive('plEntityNavigation', ['Repository', '$rootScope', '$q',
		function controller(repository, $rootScope, $q) {
			return {
				restrict: 'E',
				scope: {
					entity: '=entity',
					guid: '=guid',
					model: '=model',
					context: '=context',
					target: '=target',
				},
				templateUrl: 'app/common/directives/views/entityNavigation.html',
				link: function (scope, element, attrs) {
					var loadedEntity = {
						guid: "",
						context: ""
					};

					// Watch the element visibility so we won't do API calls while it is not used. (Ex. on creation, plEntityNavigation is in the DOM but is hidden.)
					var isElementVisible = function () {
						if (element && typeof element.css === "function")
							return element.css("display") !== "none";
						return true;
					};
					scope.isVisible = isElementVisible();

					scope.$watch(function () {
						return isElementVisible();
					}, function () {
						scope.isVisible = isElementVisible();
					});

					var refreshCanNavigate = function () {
						if (scope.guid)
							scope.canNavigate = scope.$parent.$root.hasReadAccess(scope.entity) && $rootScope.userInfo.enableWebNavigation;
						else {
							scope.canNavigate = false;
							scope.model = null;
						}
					};
					refreshCanNavigate();

					var getContext = function () {
						var deferred = $q.defer();
						if (!scope.isVisible || scope.entity !== "Customer" || !scope.canNavigate || !scope.guid || !!scope.context || !scope.$parent.$root.hasModule('Housing'))
							return $.Deferred().resolve(scope.context).promise();

						if (loadedEntity.guid === scope.Guid)
							return $.Deferred().resolve(loadedEntity.context).promise();

						scope.navigationState = null;
						// Change navigation based on the CustomerType of Customer. This is only when installation has Housing module.
						repository.getSingle(repository.apiData.customer.url, scope.guid).then(function (response) {
							if (response && response.Data && response.Data.Guid === scope.guid) {
								context = response && response.Data && response.Data.CustomerType && response.Data.CustomerType === "Person" ? planiaUtils.contextIds.housingCustomer : undefined;

								loadedEntity.guid = scope.guid;
								loadedEntity.context = context;
								deferred.resolve(context);
							} else {
								deferred.resolve(scope.context);
							}
						}, function () {
							deferred.resolve(scope.context);
						});

						return deferred.promise;
					};
					
					var refreshNavigationState = function () {
						getContext().then(function (context) {
							var apiData = _.find(repository.apiData, function (o) { return o.prefix === scope.entity && (context ? o.contextId === context : true); });
							scope.navigationState = apiData.navigationState;
						});
					};
					refreshNavigationState();
					
					scope.$watchGroup(['entity', 'guid', 'context', 'isVisible'], function () {
						refreshCanNavigate();
						refreshNavigationState();
					});
				}
			};
		}
	]);
})();

