(function () {
	var app = angular.module('Plania');
	app.directive('plTableWidget', ['TranslationService', function (translationService) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);
				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-table-message', "Tabellen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-table-confirm', 'Ja, fjern tabellen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-table-success', 'Tabellen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			controller: ['$scope', 'Repository', 'NgTableParams', '$modal', '$rootScope', 'states', '$interval', '$localStorage', '$modal', 'ListService', 'TranslationService', safeController],
			templateUrl: 'app/dashboard/directives/widgets/tableWidget.html'
		};
	}]);

	function safeController($scope, repository, ngTableParams, modal, $rootScope, states, $interval, $localStorage, $modal, listService, translationService) {
		try {
			controller($scope, repository, ngTableParams, modal, $rootScope, states, $interval, $localStorage, $modal, listService, translationService);
		} catch (error) {
			console.log(error);
		}
	}

	function controller($scope, repository, ngTableParams, modal, $rootScope, states, $interval, $localStorage, $modal, listService, translationService) {
		$scope.model = $scope.widget;
		$scope.search = {};
		$scope.canCreateNew = false;
		$scope.filterConfirmationIsCollapsed = true;

		//Object to store data broadcasted from other widgets
		var dataReceivedFromBroadcast = {};
		var createNavState = '';
		var refreshInterval;
		var onSelectionEventListener;

		if (!$scope.model.WidgetData) $scope.model.WidgetData = {};
		if (typeof ($scope.model.WidgetData) === "string") {
			$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
		}

		$scope.editWidget = function () {
			modal.open({
				controller: 'AddWidgetModalController',
				templateUrl: 'app/dashboard/addWidgetModal.html',
				resolve: {
					widget: function () {
						return $scope.model;
					}
				}
			}).result.then(function (widgetModel) {
				$scope.saveFunction(false).then(function () {
					updateAutoRefresh();
					updateOnSelectionEvent();
					setCreateNavState();

					$scope.widgetTable.filter().searchString = '';
					getTable();

					$scope.widgetTable.$params.sorting = $scope.model.WidgetData.SelectedListSetup.Sorting;
					if ($scope.model.WidgetData.SelectedListSetup.GroupBy) {
						$scope.widgetTable.settings().groupBy = function () {
							$scope.widgetTable.settings().groupBy = groupByFunction;
						};
					} else {
						$scope.widgetTable.settings().groupBy = null;
					}

					$scope.widgetTable.reload();
				});
			}, function () {
				//Dismissed
			});
		};

		var getEntityName = function () {
			var entityName = $scope.model.WidgetData.SelectedListSetup ? $scope.model.WidgetData.SelectedListSetup.EntityName : '';
			if (!entityName) {
				entityName = $scope.model.WidgetData.SelectedListSetup ? $scope.model.WidgetData.SelectedListSetup.EntityType : '';
			}

			if (!entityName) {
				entityName = 'Undefined';
			}

			return entityName;
		};

		var setCreateNavState = function () {
			try {
				createNavState = $scope.model.WidgetData.NavigationState;
				if (!createNavState) {
					$scope.canCreateNew = false;
					return;
				}

				var entityName = getEntityName();
				var isValidCreateState = false;

				// Can add custom logic per entity if we use different navState for it. For example Sparepart.
				switch (entityName) {
					default:
						createNavState = createNavState.replace('.edit', '.create');
						isValidCreateState = createNavState.includes('.create');
						break;
				}

				if (!isValidCreateState) {
					$scope.canCreateNew = false;
					return;
				}

				$scope.canCreateNew = (_.find(states.list, function (o) { return o.name === createNavState; }) ? true : false) && $rootScope.hasCreateAccess(entityName);

			} catch (ex) {
				$scope.canCreateNew = false;
			}
		};
		setCreateNavState();

		$scope.markRow = function (item) {
			return item.HasUnreadComments;
		};

		var groupByFunction = function (obj) {
			if (!$scope.model.WidgetData.SelectedListSetup.GroupBy) {
				return false;
			}

			var attrs = $scope.model.WidgetData.SelectedListSetup.GroupBy.split('.');
			attrs.forEach(function (attr) {
				obj = obj[attr];
			});

			return obj;
		};

		var getTable = function () {
			if (!$scope.model.WidgetData.SelectedListSetup) {
				return;
			}

			var listParams = {};
			var entityName = $scope.model.WidgetData.SelectedListSetup.EntityName;
			var entityType = $scope.model.WidgetData.SelectedListSetup.EntityType;
			if (entityName === repository.apiData.userGroup.prefix || entityType === repository.apiData.userGroup.prefix)
				listParams.UserGroup = 1;

			//Currently only used by workOrder to handle activity status filters
			if (entityName === repository.apiData.workOrder.prefix || entityType === repository.apiData.workOrder.prefix) {
				var filter = _.filter(listParams.PropertyFilter, function (row) {
					return row.Property.toLowerCase() === 'stage';
				});
				if (filter[0])
					listParams.ActivityStatus = filter[0].Value;
				else
					listParams.ActivityStatus = 'Active';
			}

			if ((entityName === repository.apiData.request.prefix || entityType === repository.apiData.request.prefix) && !$rootScope.userInfo.isSystemAdministrator) {
				listParams.PropertyFilter = _.union(listParams.PropertyFilter, [{ Property: 'IsTemplate', Operator: '=', Value: '0' }]);
			}

			if ((entityName === repository.apiData.project.prefix || entityType === repository.apiData.project.prefix)) {
				var isTemplate = $scope.model.WidgetData.NavigationState.indexOf('projectTemplate') > -1;
				listParams.PropertyFilter = _.union(listParams.PropertyFilter, [{ Property: 'IsTemplate', Operator: '=', Value: isTemplate ? '1' : '0' }]);
			}


			if (entityName === repository.apiData.workOrderXResourceGroup.prefix || entityType === repository.apiData.workOrderXResourceGroup.prefix)
				listParams.PropertyFilter = _.union(listParams.PropertyFilter, [{ Property: 'GuidPerson', Operator: '=', Value: repository.authService.getUserData().guidPerson }]);

			if (entityName === repository.apiData.controlListItemAnswer.prefix || entityType === repository.apiData.controlListItemAnswer.prefix)
				listParams.FilterAnswerList = true;

			var sorting = $scope.model.WidgetData.SelectedListSetup.Sorting;
			if (typeof (sorting) === 'string') {
				sorting = JSON.parse(sorting);
			}

			$scope.widgetTable = new ngTableParams({
				page: 1,
				count: $scope.model.WidgetData.defaultPaginationCount ? $scope.model.WidgetData.defaultPaginationCount : 10,
				sorting: sorting,
				filter: listParams
			},
				{
					total: 0,
					counts: [10, 25, 50, 100],
					groupBy: $scope.model.WidgetData.SelectedListSetup.GroupBy ? groupByFunction : '',
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var filter = params.$params.filter, columns = [];
						if (!filter.PropertyFilter)
							filter.PropertyFilter = [];

						$scope.model.WidgetData.SelectedListSetup.VisibleColumns = _.filter($scope.model.WidgetData.SelectedListSetup.Columns, function (c) {
							if (c.Filter && c.Filter.some(function (f) { return f.Hidden; }))
								return false;
							return true;
						});

						$scope.model.WidgetData.SelectedListSetup.Columns.forEach(function (col) {
							if (col.Filter) {
								if (typeof (col.Filter) === 'string') {
									col.Filter = JSON.parse(col.Filter);
								}
								filter.PropertyFilter = _.union(filter.PropertyFilter, col.Filter);
							}
							columns.push(col.Property);
						});

						if (!$scope.hideTable) {
							repository.GetPaginated($scope.widget.WidgetData.ServiceUrl, params.page() - 1, params.count(), params.sorting(), filter, '', JSON.stringify(columns)).then(
								function (result) {
									params.total(result.TotalCount);
									$defer.resolve(result.List);
								},
								function (error) {
									repository.growl(error, 'danger');
								});
						}
					}
				});
		};
		getTable();

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		var updateAutoRefresh = function () {
			if ($scope.model.WidgetData.AutoRefresh && $scope.model.WidgetData.RefreshInterval) {
				if (refreshInterval) {
					cancelInterval(refreshInterval);
				}

				refreshInterval = $interval(function () {
					if ($scope.widgetTable) {
						$scope.widgetTable.reload();
					}
				}, $scope.model.WidgetData.RefreshInterval * 1000);

				$scope.$on('$destroy', function () {
					cancelInterval(refreshInterval);
				});
			} else {
				if (refreshInterval) {
					cancelInterval(refreshInterval);
				}
			}
		};
		updateAutoRefresh();
		function cancelInterval(interval) {
			$interval.cancel(interval);
			interval = undefined;
		}

		$scope.navigate = function (item) {
			var itemPrefix = item.Prefix;
			if (itemPrefix === 'ControlListXEntity' || itemPrefix === 'ControlListItemAnswer') {
				var params = { restrictEdit: true };
				params.workOrderCaption = itemPrefix === 'ControlListXEntity' ? item.WorkOrder.Caption : item.ControlListXEntity.WorkOrder.Caption;
				params.controlList = itemPrefix === 'ControlListXEntity' ? item.ControlList : item.ControlListXEntity.ControlList;
				params.controlListXEntity = itemPrefix === 'ControlListXEntity' ? item : { Guid: item.GuidControlListXEntity };

				$modal.open({
					templateUrl: 'app/controlList/views/controlListCompletionModal.html',
					size: 'lg',
					controller: 'ControlListCompletionModalController',
					resolve: {
						params: function () {
							return params;
						}
					}
				});
				return;
			}
			if (itemPrefix === 'BuildingXPerson') {
				$modal.open({
					templateUrl: 'app/buildingPerson/views/editBuildingPerson.html',
					controller: 'BuildingPersonController',
					resolve: {
						params: function () {
							return {
								guid: item.Guid,
								modalParam: { isUpdate: true }
							};
						}
					}
				});
				return;
			}
			$rootScope.navigation.go($scope.model.WidgetData.NavigationState, { guid: item.Guid }, { reload: true });
		};

		// Logic for table type that can be selected
		$scope.selectedList = [];
		$scope.addSelected = function (guid) {
			if (!_.contains($scope.selectedList, guid)) {
				$scope.selectedList.push(guid);
			} else {
				for (var i = 0; i < $scope.selectedList.length; i++) {
					var p = $scope.selectedList[i];
					if (p === guid) {
						$scope.selectedList.splice(i, 1);
						break;
					}
				}
			}
		};

		$scope.isSelected = function (guid) {
			return _.contains($scope.selectedList, guid);
		};

		// When a table can select, then it is used to filter other tables within the same dashboard.
		$scope.$watch('selectedList', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if ($scope.selectedList.length > 0) {
				$scope.filterConfirmationIsCollapsed = false;
			} else {
				if ($scope.filterConfirmationIsCollapsed === false)
					$scope.broadcastNewFilter();

				$scope.filterConfirmationIsCollapsed = true;
			}
		}, true);

		// Event caller for selection tables to call all dashboards
		$scope.broadcastNewFilter = function () {
			var objects = [];

			$scope.selectedList.forEach(function (guid) {
				objects.push(_.find($scope.widgetTable.data, function (o) { return o.Guid === guid; }));
			});

			var entityName = getEntityName();

			var newFilterData = {
				entityName: entityName,
				objects: objects
			};

			$scope.$root.$broadcast('dashboard.newFilter', newFilterData);
		};

		var onNewFilter = function (evt, data) {
			dataReceivedFromBroadcast = data;

			if (data.objects.length === 0)
				$scope.hideTable = true;
			else
				$scope.hideTable = false;

			var filter = $scope.widgetTable.filter();
			if (!filter) filter = {};

			if (!filter.PropertyFilter) filter.PropertyFilter = [];

			var previousFilterIndex = _.findIndex(filter.PropertyFilter, function (o) { return o.Property === 'Guid' + data.entityName; });

			if (previousFilterIndex > -1) {
				filter.PropertyFilter.splice(previousFilterIndex, 1);
			}

			if (data.objects.length > 0)
				filter.PropertyFilter.push({ Property: 'Guid' + data.entityName, Operator: 'in', Value: _.map(data.objects, 'Guid').join(',') });
		};

		var updateOnSelectionEvent = function () {
			if (onSelectionEventListener)
				onSelectionEventListener();

			if ($scope.model.WidgetData.FilterOnSelectionEvent) {
				$scope.hideTable = true;
				onSelectionEventListener = $scope.$on('dashboard.newFilter', onNewFilter);
			} else {
				$scope.hideTable = false;
				onSelectionEventListener = undefined;
			}
		};
		updateOnSelectionEvent();

		$scope.addNew = function () {
			$rootScope.navigation.go(createNavState, { reload: true });
		};

		$scope.$on($rootScope.events.newSelection, function () {
			if ($scope.widgetTable) {
				$scope.widgetTable.reload();
			}
			setCreateNavState();
		});
	}
})();
