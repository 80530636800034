(function () {
	var app = angular.module('Plania');
	app.directive('controlListAnswersDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab'
			},
			controller: ['$scope', 'TranslationService', '$rootScope', '$timeout', '$modal', controller],
			templateUrl: 'app/controlList/views/editControlListViews/controlListAnswers.html',
		};
	});

	function controller($scope, translationService, $rootScope, $timeout, $modal) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;

		$scope.hasPageLoaded = $scope.currentTab === 'answers';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'answers' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.dropdownFilters = [
			{ value: 'current', name: translationService.translate('web-controlList-answersTab-controlListItemAnswers-current', 'Gjeldende') },
			{ value: 'all', name: translationService.translate('web-controlList-answersTab-controlListItemAnswers-all', 'Alle') }
		];

		$scope.selectedDropdownFilter = $scope.dropdownFilters[0];

		$scope.applyDropdownFilter = function (dropdownFilter, filter) {
			switch (dropdownFilter.value) {
				case 'current':
					
					filter.FilterModel.Rules.push({ Property: 'ControlListXEntity.GuidControlList', Operator: '=', Value: $scope.model.Guid });
					break;
				case 'all':
					if ($scope.model.GuidMasterRecord)
						filter.FilterModel.Rules.push({ Property: 'ControlListXEntity.ControlList.GuidMasterRecord', Operator: '=', Value: $scope.model.GuidMasterRecord });
					else
						filter.FilterModel.Rules.push({ Property: 'ControlListXEntity.GuidControlList', Operator: '=', Value: $scope.model.Guid });
					break;
				default:
					filter.FilterModel.Rules.push({ Property: 'ControlListXEntity.GuidControlList', Operator: '=', Value: $scope.model.Guid });
			}
		};

		$scope.controlListItemAnswerFilter = {
			FilterModel: {
				Condition: "and",
				Rules: [
					{
						Condition: "or",
						Rules: [
							{
								Property: 'ControlListXEntity.ControlList.GuidMasterRecord',
								Operator: '=',
								Value: $scope.model.Guid,
							},
							{
								Property: 'ControlListXEntity.ControlList.Guid',
								Operator: '=',
								Value: $scope.model.Guid,
							}
						]
					}
				]
			}
		};
		
		$scope.controlListItemAnswerColumns = [
			"ControlListXEntity.GuidWorkOrder", "ControlListXEntity.WorkOrder.Id", "ControlListXEntity.WorkOrder.Description",
			"ControlListXEntity.GuidEquipment", "ControlListXEntity.Equipment.Id", "ControlListXEntity.Equipment.Description",
			"ControlListXEntity.GuidPeriodicTask", "ControlListXEntity.PeriodicTask.Id", "ControlListXEntity.PeriodicTask.Description",
			"ControlListXEntity.GuidArea", "ControlListXEntity.Area.Id", "ControlListXEntity.Area.Description", "ControlListXEntity.Area.IsHousing",
		];

		$scope.controlListItemAnswerContext = planiaUtils.contextIds.controlListControlListItemAnswer;

		$scope.openControlList = function (controlListItemAnswer) {
			var caption = "";
			if (controlListItemAnswer.ControlListXEntity.WorkOrder)
				caption = controlListItemAnswer.ControlListXEntity.WorkOrder.Caption;
			else if (controlListItemAnswer.ControlListXEntity.PeriodicTask)
				caption = controlListItemAnswer.ControlListXEntity.PeriodicTask.Caption;

			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							workOrderCaption: caption,
							controlList: $scope.model,
							controlListXEntity: controlListItemAnswer.ControlListXEntity,
							restrictEdit: true
						};
					}
				}
			});
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="fas' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.controlListItemAnswersContextMenuOptions = function (item) {
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-controlList-answersTab-controlListXEntityContextMenuOptions-openControlList', 'Åpne kontrolliste') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.openControlList(item);
				}
			});

			if ($scope.hasReadAccess("WorkOrder") && item.ControlListXEntity.GuidWorkOrder) {
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.ControlListXEntity.GuidWorkOrder }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-workOrder-view', 'Åpne arbeidsordre')));
			}

			if ($scope.hasReadAccess("PeriodicTask") && item.ControlListXEntity.GuidPeriodicTask) {
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.ControlListXEntity.GuidPeriodicTask }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-periodicTask-view', 'Åpne periodisk rutine')));
			}

			if ($scope.hasReadAccess("Area") && item.ControlListXEntity.GuidArea) {
				if (item.ControlListXEntity.Area.IsHousing)
					options.push(getMenuLink($rootScope.navigation.href('housing.edit', { guid: item.ControlListXEntity.GuidArea }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-housing-view', 'Åpne bolig')));
				else
					options.push(getMenuLink($rootScope.navigation.href('area.edit', { guid: item.ControlListXEntity.GuidArea }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-area-view', 'Åpne rom')));
			}

			if ($scope.hasReadAccess("Equipment") && item.ControlListXEntity.GuidEquipment) {
				options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.ControlListXEntity.GuidEquipment }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-equipment-view', 'Åpne anlegg')));
			}

			return options;
		};
	}
})();
