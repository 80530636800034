(function () {
	angular.module('Plania').controller('AddressSearchController', ['$scope', '$modalInstance', 'modalParams', 'Repository', 'ngTableParams', controller]);

	function controller($scope, $modalInstance, modalParams, repository, ngTableParams) {

		$scope.modalParams = modalParams;

		$scope.items = new ngTableParams({
			page: 1,
			count: 10,
			filter: { searchString: '' },
			sorting: $scope.defaultSort ? $scope.defaultSort : { id: 'asc' }
		}, {
			total: 0,
			counts: [10, 20, 50],
			paginationMaxBlocks: 5,
			getData: function ($defer, params) {
				var searchString = params.filter().searchString;
				if (!searchString) return;
				repository.getWithUrlParameter(repository.apiData.address.endpoint.search, 'searchString=' + searchString).then(function (result) {
					params.total(result.Addresses.length);
					if (result.Addresses)
						$defer.resolve(result.Addresses);
				});
			}
		});



		$scope.ok = function (item) {
			$modalInstance.close(item);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
