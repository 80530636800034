(function () {
	angular.module('Plania').controller('CustomerController', ['$scope', 'Repository', '$state', '$q', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', '$localStorage', 'ListService', '$timeout', '$http', controller]);

	//#region Address
	// TODO: convert to "class" when IE is no longer supported
	function Address() {
		this.StreetAddress = null;
		this.PostalAddress = null;
		this.PostalCode = null;
		this.PostalArea = null;
		this.Country = null;

		this.GuidPostalData = null;// from POSTAL_CODE, but renamed to non confusing name
	}

	function buildAddress(plainAddress) {
		var address = new Address();
		if (plainAddress.StreetAddress) address.StreetAddress = plainAddress.StreetAddress;
		if (plainAddress.PostalAddress) address.PostalAddress = plainAddress.PostalAddress;
		if (plainAddress.PostalCode) address.PostalCode = plainAddress.PostalCode;
		if (plainAddress.PostalArea) address.PostalArea = plainAddress.PostalArea;
		if (plainAddress.Country) address.Country = plainAddress.Country;

		return address;
	}

	function tryParseInt(str, defaultValue) {
		try {
			var retValue = defaultValue;
			if (str !== null && str.length > 0 && !isNaN(str)) {
				retValue = parseInt(str);
				if (isNaN(retValue))
					retValue = defaultValue;
			}

			return retValue;
		} catch (ex) {
			return defaultValue;
		}
	}

	Address.prototype.isValid = function () {
		return (this.StreetAddress || this.PostalAddress) && this.PostalCode && this.PostalArea;
	};

	Address.prototype.synchroniseFromPostalData = function (postalData, translationService) {
		this.GuidPostalData = postalData ? postalData.Guid : null;
		this.PostalCode = postalData ? postalData.ZipCode : null;
		this.PostalArea = postalData ? postalData.PostalArea : null;
		// Until saving country as countrycode, we need to store the translated name TODO remove translationService, store enum in DB instead of text
		this.Country = postalData ? translationService.getEnumTranslation('countrycode', postalData.CountryCode) : null;
	};

	//#endregion

	function controller($scope, repository, $state, $q, $stateParams, $modal, ngTableParams, translationService, $localStorage, listService, $timeout, $http) {

		//#region Customer
		// TODO: convert to "class" when IE is no longer supported
		function Customer() {
			// declare addresses as real Address objects that will be merged with their DTO counterparts on loading
			this.Address = new Address();
			this.MailingAddress = new Address();
			this.InvoiceAddress = new Address();

			this.isEditable = true;
		}

		Customer.prototype.url = function () {
			return repository.apiData.customer.url;
		};

		Customer.prototype.getClientParentProperties = function (guid) {
			// get all (not null) parent properties currently defined for the client entity (not all "registered" properties of the BL class may exist in the JS model)
			// assume that no parent property is defined in the JS entity without its FK counterpart (= for all 'XXX' there is a 'GuidXXX')
			var parentProperties = [];
			for (var propertyName in this) {
				if (!propertyName.startsWith('Guid'))
					continue;
				var parentPropertyName = _.capitalize(propertyName.substring(4));
				var parent = this[parentPropertyName];
				if (parent)
					parentProperties.push(parentPropertyName);
			}
			return parentProperties;
		};

		Customer.prototype.load = function (guid, columns) {
			var defer = $q.defer();
			var self = this;

			repository.getSingle(this.url(), guid, columns ? JSON.stringify(columns) : null).then(
				function (response) {
					// NB: the API may not send all properties currently loaded in the model -> merge
					angular.merge(self, response.Data);
					defer.resolve(self);
				}).catch(function (error) {
					repository.growl(error, 'danger');
					defer.reject();
				});

			return defer.promise;
		};

		Customer.prototype.prefillFrontModel = function (frontModel) {
			var self = this;
			if (frontModel) {
				// Remove Guid, since the object has not been created
				delete frontModel.Guid;

				// Remove CustomerNr, since it will be autogenerated on creation.
				delete frontModel.CustomerNr;
				angular.merge(self, frontModel);
			}
		};

		Customer.prototype.hasValidFrontId = function () {
			var self = this;
			return self.ExternalId && tryParseInt(self.ExternalId) && tryParseInt(self.ExternalId) > 0;
		};

		Customer.prototype.save = function (guid) {
			// properties to parent entities may contain incomplete (ex. when fetched using columns), outdated or unreliable data, that may cause errors when deserialized on server side
			// furthermore, they would send an unnecessary heavy payload
			// -> unless explicitly required (seldom), reset them to be sure they are correctly reloaded if needed
			var defer = $q.defer();
			var self = this;

			var parentProperties = this.getClientParentProperties();

			// get a copy of the original entity whose parent entities are reset (to be passed as payload for update function)
			var payloadEntity = angular.copy(this);
			parentProperties.forEach(function (field) {
				delete payloadEntity[field];
			});

			var executeUpdate = function () {
				($scope.isUpdate ? repository.updateSingle : repository.createSingle)(self.url(), payloadEntity).then(
					function (response) {
						// NB: the API may not send all properties currently loaded in the client model (if the BL did not need them, they were not loaded, just the GUID is valid)
						// -> merge to be sure to keep these client properties, but reset the ones that are not consistent with their matching guid (= this.Parent.Guid must be equal to this.GuidParent)
						angular.merge(self, response);
						parentProperties.forEach(function (field) {
							if (self[field] && self[field].Guid && self['Guid' + field] && self[field].Guid !== self['Guid' + field])
								delete self[field];
						});
						defer.resolve(self);
					}).catch(function (error) {
						defer.reject(error);
					});
			};

			executeUpdate();
			return defer.promise;
		};

		Customer.prototype.resetParent = function (field) {
			if (this['Guid' + field])
				this['Guid' + field] = null;
			if (this[field])
				delete this[field];
		};

		Customer.prototype.getInvoiceCustomer = function () {
			if (!this.GuidInvoiceCustomer)
				return (this.InvoiceCustomer = null);

			if (this.InvoiceCustomer && this.InvoiceCustomer.Guid === this.GuidInvoiceCustomer)
				return this.InvoiceCustomer;


			// trigger an asynchronous loading of the object, and return meanwhile the minimal valid one to ensure no parallel loading is triggered if the current function is called again before the first one is complete
			this.InvoiceCustomer = new Customer();
			this.InvoiceCustomer.Guid = this.GuidInvoiceCustomer;
			var me = this;
			this.InvoiceCustomer.load(this.GuidInvoiceCustomer).then(
				function (customer) {
					$scope.invoiceCustomerCaption = customer.Caption;
				},
				function (error) {
					me.InvoiceCustomer.Guid = null;
					repository.growl(error, 'danger');
				});

			return this.InvoiceCustomer;
		};

		Customer.prototype.isFieldEnabled = function (field) {
			if (!this.isEditable || $scope.viewMode)
				return false;

			switch (field) {
				case 'PostalArea':
				case 'Country':
					return !this.Address.GuidPostalData;
				case 'MailingStreetAddress':
				case 'MailingPostalAddress':
				case 'MailingPostalCode':
					return !($scope.generalOptions.IsBBY && this.ExternalId) || this.FrontMailingAddressType === "Undefined";
				case 'MailingPostalArea':
				case 'MailingCountry':
					if (this.MailingAddress.GuidPostalData)
						return false;
					return !($scope.generalOptions.IsBBY && this.ExternalId) || this.FrontMailingAddressType === "Undefined";
				case 'InvoiceStreetAddress':
				case 'InvoicePostalAddress':
				case 'InvoicePostalCode':
					return !this.GuidInvoiceCustomer;
				case 'InvoicePostalArea':
				case 'InvoiceCountry':
					return !this.InvoiceAddress.GuidPostalData && !this.GuidInvoiceCustomer;
				case 'CanBeInvoiceRecipient':
					return !this.GuidInvoiceCustomer && !this.HasDependentCustomers;// cannot edit if customer has an invoice customer or is itself an invoice customer
				case 'Id':
				case 'Description':
				case 'DateOfDeath':
					return !($scope.generalOptions.IsBBY && this.ExternalId);
				case 'Email':
				case 'CellPhone':
					return !($scope.generalOptions.IsBBY && (this.ExternalId && this.CustomerType === 'Person'));
			}

			return true;
		};

		// for convenience with angular, which prefers negative assertion
		Customer.prototype.isFieldDisabled = function (field) {
			return !this.isFieldEnabled(field);
		};

		Customer.prototype.isFieldVisible = function (field) {
			switch (field) {
				case 'GuidInvoiceCustomer':
					return !this.CanBeInvoiceRecipient;
				case 'CanBeInvoiceRecipient':
					return !this.GuidInvoiceCustomer;
				case 'DateOfDeath':
					if (this.CustomerType !== 'Person')
						return false;
					if ($scope.viewMode && !this.DateOfDeath)
						return false;
					return !!this.DateOfDeath || !this.ExternalId;
				case 'MaritalStatus':
				case 'Language':
				case 'Nationality':
					if (this.CustomerType === 'Organization')
						return false;
					return true;
			}

			return true;
		};

		Customer.prototype.getMailingAddress = function () {
			if (this.MailingAddress.isValid())
				return this.MailingAddress;
			return this.Address;
		};

		Customer.prototype.getInvoiceAddress = function () {
			// fall through parent customer invoice address, actual invoice address, mailing address, residential address in this order until the first valid one
			if (this.GuidInvoiceCustomer && this.GuidInvoiceCustomer !== this.Guid)
				return this.getInvoiceCustomer().getInvoiceAddress();
			if (this.InvoiceAddress.isValid())
				return this.InvoiceAddress;
			return this.getMailingAddress();
		};
		//#endregion

		$scope.model = new Customer();
		$scope.isUpdate = RegExp("edit$").test($scope.navigation.current.name);
		$scope.context = $state.includes('housingCustomer') ? "HousingCustomer" : "Customer";
		$scope.model.Guid = $stateParams.guid;
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};
		$scope.canChangeCustomerType = false;
		$scope.activateAutocomplete = false;
		$scope.searchResults = [];
		$scope.search = {
			text: "",
			focus: false,
			hasSearched: false
		};

		$scope.bbyFront = {
			text: "",
			focus: false,
			synchronizing: false,
			hasError: false,
			errorMessage: ""
		};

		$scope.steps = [];

		var updateSteps = function () {
			var stepIndex = 0;
			var steps = [{ stepIndex: ++stepIndex, name: 'chooseCustomerType', title: translationService.translate('web-customer-section-title-customerType', 'Kundetype') }];
			if ($scope.model.CustomerType) {
				if ($scope.generalOptions.IsBBY && $scope.generalOptions.UseFrontApi) {
					steps.push(({ stepIndex: ++stepIndex, name: 'bbyFrontSearch', title: translationService.translate('web-customer-section-title-bbyFrontSearch', 'Front') }));
				} else if ($scope.generalOptions.UseBronnoysundRegister && $scope.model.CustomerType !== 'Person') {
					steps.push(({ stepIndex: ++stepIndex, name: 'searchBroenoysund', title: translationService.translate('web-customer-section-title-searchBroenoysund', 'Søk') }));
				}
				steps.push(({ stepIndex: ++stepIndex, name: 'basicInfo', title: translationService.translate('web-customer-section-title-basicInformation', 'Basisinformasjon') }));
			}
			$scope.steps = steps;
		};
		updateSteps();
		$scope.currentStep = $scope.steps[0];

		$scope.goToNextStep = function () {
			var stepIndex = _.findIndex($scope.steps, { name: $scope.currentStep.name });
			if (stepIndex >= 0 && stepIndex < $scope.steps.length) {
				var nextStep = $scope.steps[++stepIndex];
				if (nextStep) {
					if (nextStep.name === 'searchBroenoysund') {
						$scope.searchResults = [];
						$scope.search.text = "";
						$scope.search.hasSearched = false;
						$timeout(function () {
							$scope.search.focus = true;
						}, 500);
					}

					if (nextStep.name === 'bbyFrontSearch') {
						$scope.bbyFront.text = "";
						$scope.bbyFront.hasValidationError = false;
						$timeout(function () {
							$scope.bbyFront.focus = true;
						}, 500);
					}

					$scope.currentStep = nextStep;
				}
			}
		};

		$scope.goToPreviousStep = function () {
			var stepIndex = _.findIndex($scope.steps, { name: $scope.currentStep.name });
			if (stepIndex > 0) {
				var prevStep = $scope.steps[--stepIndex];
				if (prevStep) {
					$scope.currentStep = prevStep;
				}
			}
		};

		$scope.setCustomerType = function (customerType) {
			$scope.model.CustomerType = customerType;
			updateSteps();
			$scope.goToNextStep();
		};

		$scope.getPrefillFromFront = function () {
			if ($scope.bbyFront.synchronizing) return;

			$scope.bbyFront.synchronizing = true;
			$http({
				method: "GET",
				url: encodeURI(repository.apiUrl.base + "bby/bbycustomer?identificationNumber=" + $scope.bbyFront.text + "&customerType=" + $scope.model.CustomerType + repository.getUrlParameters('&'))
			}).then(function (apiResponse) {
				$scope.bbyFront.synchronizing = false;
				if (apiResponse.status === 200) {
					if (apiResponse.data) {
						$scope.model.prefillFrontModel(apiResponse.data);
					}
				} else {
					$scope.bbyFront.synchronizing = false;
					$scope.bbyFront.hasError = true;
					$scope.model.Id = $scope.bbyFront.text;

					if (apiResponse) {
						if (typeof (apiResponse.data) === "string")
							$scope.bbyFront.errorMessage = apiResponse.data;
						else if (apiResponse.data.Message)
							$scope.bbyFront.errorMessage = apiResponse.data.Message;
					}
				}
				$scope.goToNextStep();
			});
		};

		$scope.orgNumberRegex = /^[0-9]{9}$/;
		var isOrganizationNumber = function (orgNumber) {
			return $scope.orgNumberRegex.test(orgNumber);
		};

		$scope.searchCustomerRegister = function () {
			var searchString = $scope.search.text;
			var searchName = true;
			try {
				searchName = !isOrganizationNumber(searchString);
			} catch (ex) {
				searchName = true;
			}

			var queryParameters = "";
			if (searchName)
				queryParameters = "name=" + searchString;
			else
				queryParameters = "organizationNumber=" + searchString;

			repository.getWithUrlParameter(repository.apiData.customer.endpoint.searchPublicRegister, queryParameters).then(function (result) {
				if (result) {
					$scope.searchResults = result.List;
					$scope.searchResultTotalCount = result.TotalCount;
					$scope.search.hasSearched = true;
				}
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.selectCustomer = function (customer) {
			$scope.model.Id = customer.organisasjonsnummer;
			$scope.model.Description = customer.navn;

			if (customer.forretningsadresse) {
				$scope.model.Address.StreetAddress = customer.forretningsadresse.adresse[0];
				$scope.model.Address.PostalCode = customer.forretningsadresse.postnummer;
				$scope.model.Address.PostalArea = customer.forretningsadresse.poststed;
				$scope.model.Address.Country = customer.forretningsadresse.land;
			}

			if (customer.postadresse) {
				$scope.model.MailingAddress.StreetAddress = customer.postadresse.adresse[0];
				$scope.model.MailingAddress.PostalCode = customer.postadresse.postnummer;
				$scope.model.MailingAddress.PostalArea = customer.postadresse.poststed;
				$scope.model.MailingAddress.Country = customer.postadresse.land;
			}

			$scope.goToNextStep();
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Customer, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Customer, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Customer);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Customer);
			}
			$scope.model.isEditable = !$scope.restrictEdit;
		};

		updateAccess();

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "customer":
					filter.PropertyFilter = [{ Property: 'InvoiceCustomers', Operator: '=', Value: true }];
					return filter;
			}
			return filter;
		};

		$scope.load = function (guid) {
			// due to limitations in LINQ, properties of a parent (InvoiceCustomer) that has the same type as the main entity cannot be fetched in the same query
			var columns = ['CustomerCategory.Id', 'CustomerGroup.Id', 'LineOfBusiness.Id', 'Department.Id'/*, 'InvoiceCustomer.Caption'*/];
			$scope.model.load(guid, columns).then(
				function (customer) {
					$scope.setHtmlTitleByModel(customer);

					if (customer.CustomerLineOfBusiness)
						$scope.lineOfBusiness = customer.LineOfBusiness.Id;

					if (customer.Department)
						$scope.department = customer.Department.Id;

					if (customer.CustomerCategory)
						$scope.category = customer.CustomerCategory.Id;

					if (customer.CustomerGroup)
						$scope.group = customer.CustomerGroup.Id;

					//if (customer.InvoiceCustomer)
					//	$scope.invoiceCustomerCaption = customer.InvoiceCustomer.Caption;

					$scope.canChangeCustomerType = customer.CustomerType === 'Undefined';

					updateAccess();
					$scope.reloadDocumentTable = true;

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});

			$scope.getCardTables();
		};

		$scope.init = function () {
			$scope.activateAutocomplete = true;

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'CustomerLineOfBusiness':
								$scope.lineOfBusiness = prefill.Value.Label;
								$scope.model.GuidCustomerLineOfBusiness = prefill.Value.Guid;
								break;
							case 'CustomerGroup':
								$scope.group = prefill.Value.Label;
								$scope.model.GuidCustomerGroup = prefill.Value.Guid;
								break;
							case 'CustomerCategory':
								$scope.category = prefill.Value.Label;
								$scope.model.GuidCustomerCategory = prefill.Value.Guid;
								break;
							case 'Department':
								$scope.department = prefill.Value.Label;
								$scope.model.GuidDepartment = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}
		};

		$scope.$watch('model.InvoiceCustomer.Caption', function (newVal, oldVal) {
			$scope.invoiceCustomerCaption = newVal;
		});

		$scope.update = function (destination) {
			var success = function (customer) {
				repository.growl($scope.isUpdate ? "Kunden ble oppdatert" : 'Kunden ble lagret', 'success');
				repository.commonService.setLastRegisterGuid(destination, customer.Guid);
				if (destination === 'customer.create') {
					$scope.navigation.go(destination, { reload: true });
				} else {
					repository.persistedData.clearPersistedData();
					$scope.goBack(destination, { guid: customer.Guid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				repository.growl((typeof (error) === "string") ? error : error.Data.Message, 'danger');
			};

			$scope.model.save().then(success).catch(error);
		};

		$scope.cancel = function () {
			$scope.goBack('customer.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-customer-message', "Kunden vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-customer-button-confirm', 'Ja, fjern kunden'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.customer.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-customer-success', 'Kunden ble fjernet!'), result, "success");
						$scope.goBack('customer.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal({
							title: "Kunne ikke slette kunden!",
							text: error.Message + "<br/><br/> Vil du deaktivere kunden?",
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: "Deaktiver",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.IsDeactivated = true;
								$scope.model.Id = '#' + $scope.model.Id;
								$scope.updateSupplier('customer.list');
							}
						});
					});
			});
		};

		$scope.getInvoiceAddress = function () {
			return $scope.model.GuidInvoiceCustomer ? $scope.model.getInvoiceAddress() : $scope.model.InvoiceAddress;// map either to invoice customer's address (as read-only), or to current customer's invoice address
		};

		$scope.onPostCodeSelect = function (postalData) {
			if (!$scope.model.Address.synchroniseFromPostalData)
				$scope.model.Address = buildAddress($scope.model.Address);// promote plain DTO address to Address object
			$scope.model.Address.synchroniseFromPostalData(postalData, translationService);
		};

		$scope.onMailingPostCodeSelect = function (postalData) {
			if (!$scope.model.MailingAddress.synchroniseFromPostalData)
				$scope.model.MailingAddress = buildAddress($scope.model.MailingAddress);// promote plain DTO address to Address object
			$scope.model.MailingAddress.synchroniseFromPostalData(postalData, translationService);
		};

		$scope.onInvoicePostCodeSelect = function (postalData) {
			if (!$scope.model.GuidInvoiceCustomer) {
				if (!$scope.model.InvoiceAddress.synchroniseFromPostalData)
					$scope.model.InvoiceAddress = buildAddress($scope.model.InvoiceAddress);// promote plain DTO address to Address object
				$scope.model.InvoiceAddress.synchroniseFromPostalData(postalData, translationService);
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.doorKeyXCustomerColumns = [
			{ Position: 1, Title: translationService.translate('web-doorKey-id'), Property: 'DoorKey.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-doorKey-description'), Property: 'DoorKey.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-doorKeyXUser-quantity'), Property: 'Quantity', PropertyType: 'number' },
			{ Position: 4, Title: translationService.translate('web-doorKey-creationDate'), Property: 'CreationDate', PropertyType: 'date' },
		];

		$scope.onClickDoorKeyXCustomer = function (entity, event) {
			event.preventDefault();
			event.stopPropagation();
			$scope.navigation.go('doorKey.edit', { guid: entity.GuidDoorKey });
		};

		$scope.contactPersonInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-contactperson-firstname'), Property: 'FirstName', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-contactperson-lastname'), Property: 'LastName', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-contactperson-telephone'), Property: 'Telephone', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-contactperson-email'), Property: 'Email', PropertyType: 'string' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'LastName': 'asc' },
					filter: { PropertyFilter: [{ Property: 'GuidCustomer', Operator: '=', Value: $stateParams.guid }] }
				},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.contactPerson.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.showError(error);
									});
						}
					});
			};

			this.add = function () {
				if (!me.canCreate()) return;

				$modal.open({
					templateUrl: 'app/contactPerson/contactPerson.html',
					controller: 'ContactPersonController',
					resolve: {
						params: function () { return { guidCustomer: $scope.model.Guid }; }
					}
				}).result.then(function () {
					me.table.reload();
				});
			};

			this.update = function (item) {
				if (!me.canEdit(item)) return;
				$modal.open({
					templateUrl: 'app/contactPerson/contactPerson.html',
					controller: 'ContactPersonController',
					resolve: {
						params: function () { return { guid: item.Guid }; }
					}
				}).result.then(function () {
					me.table.reload();
				}, function () { });
			};

			this.remove = function (item) {
				if (!me.canDelete(customerLog)) return;

				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-supplier-contactperson-message', 'Kontaktpersonen vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-supplier-contactperson-button-confirm', 'Ja, fjern kontaktpersonen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.contactPerson.url, item.Guid).then(function (result) {
						swal(translationService.translate('web-swal-supplier-contactperson-success', 'Kontaktpersonen ble fjernet!'), result, "success");
						me.table.reload();
					});
				});
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('ContactPerson');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('ContactPerson');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('ContactPerson');
			};
		}();

		$scope.requestInfo = new function () {
			var me = this;

			this.columns = $scope.context === "HousingCustomer" ?
				[
					{ Position: 1, Title: translationService.translate('web-request-id'), Property: 'Id', PropertyType: 'string' },
					{ Position: 2, Title: translationService.translate('web-request-status'), Property: 'Status', PropertyType: 'enum.RequestStatus' },
					{ Position: 3, Title: translationService.translate('web-request-description'), Property: 'Description', PropertyType: 'string' },
					{ Position: 4, Title: translationService.translate('web-request-priority'), Property: 'Priority.Description', PropertyType: 'string' },
					{ Position: 5, Title: translationService.translate('web-request-forwardPerson'), Property: 'ForwardPerson', PropertyType: 'string' },
					{ Position: 6, Title: translationService.translate('web-building-address'), Property: 'Building.Address', PropertyType: 'string' },
					{ Position: 7, Title: translationService.translate('web-request-creationDate'), Property: 'CreationDate', PropertyType: 'date' },

				] :
				[
					{ Position: 1, Title: translationService.translate('web-request-id'), Property: 'Id', PropertyType: 'string' },
					{ Position: 2, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
					{ Position: 3, Title: translationService.translate('web-request-description'), Property: 'Description', PropertyType: 'string' },
					{ Position: 4, Title: translationService.translate('web-request-contactperson'), Property: 'ContactPerson', PropertyType: 'string' },
					{ Position: 5, Title: translationService.translate('web-request-status'), Property: 'Status', PropertyType: 'enum.RequestStatus' },
				];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Id': 'desc' },
					filter: {
						PropertyFilter: [
							{ Property: 'GuidCustomer', Operator: '=', Value: $stateParams.guid },
							{ Property: 'Status', Operator: '<>', Value: '3' },
							{ Property: 'Status', Operator: '<>', Value: '7' }
						]
					}
				},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.request.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.showError(error);
									});
						}
					});
			};

			this.add = function () {
				// check for active contracts
				var filterModel = {
					Condition: 'and',
					Rules: [
						{Property: 'GuidCustomer', Operator: '=', Value: $scope.model.Guid},
						{Property: 'GuidArea', Operator: '<>', Value: null},
						{Property: 'Area.IsHousing', Operator: '=', Value: true},
						{Property: 'StartDate', Operator: '<>', Value: null},
						{Property: 'StartDate', Operator: '<', Value: new Date().toISOString()},
						{
							Condition: 'or',
							Rules: [
								{ Property: "EndDate", Operator: ">", Value: new Date().toISOString() },
								{ Property: "EndDate", Operator: "=", Value: null }
							]
						}
					]
				};

				var columns = ["Area.Id", "Area.GuidBuilding"];
				repository.GetPaginated(repository.apiData.contract.url, 0, -1, {'Area.Id': 'asc'}, {FilterModel: filterModel}, null, JSON.stringify(columns)).then(function (result){
					// if one, navigate to request page with prefill
					if (result && result.List.length === 1) {
						navigateToRequest(result.List[0].Area);
						return;
					}

					// if none, no prefill
					if (result && result.List.length === 0){
						navigateToRequest(null);
						return;
					}

					// if multiple, the user must choose
					$modal.open({
						templateUrl: 'app/common/views/singleSelectModal.html',
						controller: 'SingleSelectModalController',
						size: 'md',
						resolve: {
							modalParams: function () {
								return {
									url: repository.apiData.contract.url,
									sorting: {},
									title: 'Velg areal',
									filter: { FilterModel: filterModel},
									columns: [
										{ title: 'Areal id', property: 'Area.Id' },
										{ title: 'Adresse', property: 'Area.Address' },
										{ title: 'Kategori', property: 'Area.AreaCategory.Description' },
										{ title: 'Status utleie', property: 'Area.RentalStatus' },
										{ property: 'Area.GuidBuilding', hidden: true },
									]
								};
							}
						}
					}).result.then(function (item) {
						navigateToRequest(item.Area);
					});

				}, function (error){
					repository.showError(error);
				});
			};

			function navigateToRequest(selectedArea) {
				var newRequest = {
					model: {
						GuidCustomer: $scope.model.Guid,
						GuidArea: selectedArea !== null ? selectedArea.Guid : null,
						GuidBuilding: selectedArea !== null ? selectedArea.GuidBuilding : null
					},
					customer: $scope.model.Caption
				};

				repository.persistedData.setPersistedData('request.create', newRequest);
				$scope.navigation.go('request.create', { showPersistantData: true }, { reload: true });
			}

			this.canCreate = function () {
				return $scope.hasCreateAccess('Request');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('Request');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('Request');
			};
		}();

		$scope.customerDeliveryAddressInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-customerDeliveryAddress-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-customerDeliveryAddress-address', "Adresse"), Property: 'StreetAddress', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-customerDeliveryAddress-po', "Postboks"), Property: 'PostalAddress', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-customerDeliveryAddress-PostNumber', "Postnr"), Property: 'PostalCode', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-customerDeliveryAddress-PostalArea', "Poststed"), Property: 'PostalArea', PropertyType: 'string' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Description': 'desc' },
					filter: {
						PropertyFilter: [
							{ Property: 'GuidCustomer', Operator: '=', Value: $stateParams.guid }
						]
					}
				},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.customerDeliveryAddress.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.showError(error);
									});
						}
					});
			};

			this.add = function () {
				if (!me.canCreate()) return;

				$modal.open({
					templateUrl: 'app/customer/views/deliveryAddress.html',
					controller: 'CustomerDeliveryAddressController',
					resolve: {
						params: function () { return { guidCustomer: $scope.model.Guid, customerName: $scope.model.Description }; }
					}
				}).result.then(function () {
					me.table.reload();
				});
			};

			this.update = function (item) {
				if (!me.canEdit(item)) return;
				$modal.open({
					templateUrl: 'app/customer/views/deliveryAddress.html',
					controller: 'CustomerDeliveryAddressController',
					resolve: {
						params: function () { return { guid: item.Guid, customerName: $scope.model.Description }; }
					}
				}).result.then(function () {
					me.table.reload();
				}, function () { });
			};

			this.remove = function (item) {
				if (!me.canDelete(item)) return;

				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-customer-deliveryAddress-message', 'Adressen vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-customer-deliveryAddress-button-confirm', 'Ja, fjern adressen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.customerDeliveryAddress.url, item.Guid).then(function (result) {
						swal(translationService.translate('web-swal-customer-deliveryAddress-success', 'Adressen ble fjernet!'), result, "success");
						me.table.reload();
					});
				});
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('CustomerDeliveryAddress');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('CustomerDeliveryAddress');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('CustomerDeliveryAddress');
			};
		}();

		$scope.workOrderInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-workorder-type'), Property: 'Type', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-workorder-resourcegroup-description', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
				{ Position: 6, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' },
			];

			this.filterTypes = [
				{ value: '-1', name: translationService.translate('web-workorder-filterType-all', 'Alle') },
				{ value: '0', name: translationService.translate('web-workorder-filterType-project', 'Prosjekt') },
				{ value: '1', name: translationService.translate('web-workorder-filterType-open', 'Aktive') },
				{ value: '2', name: translationService.translate('web-workorder-filterType-closed', 'Historiske') }
			];

			this.selectedFilterType = me.filterTypes[0];

			this.setFilterType = function (filter) {
				me.selectedFilterType = filter;
				me.table.reload();
			};

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'Id': 'desc' },
					filter: {
						ActivityStatus: 'Undefined',
						PropertyFilter: [
							{ Property: 'GuidCustomer', Operator: '=', Value: $stateParams.guid }
						]
					}
				},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							var filter = angular.copy(params.filter());
							if (me.selectedFilterType && me.selectedFilterType.value >= 0)
								filter.PropertyFilter.push({ Property: 'Stage', Operator: '=', Value: me.selectedFilterType.value });

							repository.GetPaginated(repository.apiData.workOrder.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								filter,
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.showError(error);
									});
						}
					});
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('WorkOrder');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('WorkOrder');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('WorkOrder');
			};
		}();

		$scope.contractInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-contract-Id', "Id"), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-contract-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-area-Id', "Bolig Id"), Property: 'Area.Id', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-area-Description', "Bolig beskrivelse"), Property: 'Area.Description', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-contract-startDate', "Startdato"), Property: 'StartDate', PropertyType: 'date' },
				{ Position: 6, Title: translationService.translate('web-contract-ScheduledEndDate', "Dato flyttet ut"), Property: 'ScheduledEndDate', PropertyType: 'date' },
				{ Position: 7, Title: translationService.translate('web-contract-endDate', "Sluttdato"), Property: 'EndDate', PropertyType: 'date' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'StartDate': 'desc' },
					filter: { PropertyFilter: [{ Property: 'GuidCustomer', Operator: '=', Value: $stateParams.guid }] }
				},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.contract.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.showError(error);
									});
						}
					});
			};

			this.allocateHousing = function () {
				$modal.open({
					templateUrl: 'app/common/views/singleSelectModal.html',
					controller: 'SingleSelectModalController',
					size: 'md',
					resolve: {
						modalParams: function () {
							return {
								title: 'Velg bolig',
								url: repository.apiData.area.url,
								sorting: {},
								filter: { IsForHousingAllocation: true },
								columns: [
									{ title: 'Id', property: 'Id' },
									{ title: 'Beskrivelse', property: 'Description' }
								]
							};
						}
					}
				}).result.then(function (item) {
					var success = function (result) {
						repository.growl($scope.isUpdate ? "Avtalen ble oppdatert" : 'Avtalen ble lagret', 'success');
						me.table.reload();
					};

					var error = function (error) {
						if (typeof (error) === "string") {
							repository.growl(error, 'danger');
						} else {
							repository.growl(error.Data.Message, 'danger');
						}
					};

					if (item.Guid === undefined)
						return;

					var contract = {
						RentalType: 'Housing',
						GuidCustomer: $scope.model.Guid,
						GuidArea: item.Guid
					};
					repository.createSingle(repository.apiData.contract.url, contract).then(success).catch(error);
				});
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('Contract');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('Contract');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('Contract');
			};
		}();

		$scope.customerLogInfo = new function () {
			var me = this;

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-customerLog-Description', "Beskrivelse"), Property: 'Description', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-customerLog-ResponsiblePerson', "Ansvarlig"), Property: 'ResponsiblePerson', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-customerLog-Date', "Dato"), Property: 'LogDate', PropertyType: 'date' },
				{ Position: 4, Title: translationService.translate('web-customerLog-userCreatedBy-realName', "Opprettet av"), Property: 'UserCreatedBy.RealName', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-customerLog-creationDate', "Opprettet"), Property: 'CreationDate', PropertyType: 'date' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: { 'LogDate': 'desc' },
					filter: {
						PropertyFilter: [
							{ Property: 'GuidCustomer', Operator: '=', Value: $stateParams.guid }
						]
					}
				},
					{
						total: 0,
						counts: [10, 20, 50],
						filterDelay: 10,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.customerLog.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.showError(error);
									});
						}
					});
			};

			this.add = function () {
				if (!me.canCreate()) return;

				$modal.open({
					templateUrl: 'app/customer/views/log.html',
					controller: 'CustomerLogController',
					resolve: {
						params: function () { return { guidCustomer: $scope.model.Guid }; }
					}
				}).result.then(function () {
					me.table.reload();
				});
			};

			this.update = function (customerLog) {
				if (!me.canEdit(customerLog)) return;
				$modal.open({
					templateUrl: 'app/customer/views/log.html',
					controller: 'CustomerLogController',
					resolve: {
						params: function () { return { guid: customerLog.Guid }; }
					}
				}).result.then(function () {
					$scope.customerLogInfo.table.reload();
				});
			};

			this.remove = function (customerLog) {
				if (!me.canDelete(customerLog)) return;

				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-customer-customerLog-message', 'Loggen vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-customer-customerLog-button-confirm', 'Ja, fjern loggen!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.customerLog.url, customerLog.Guid).then(function (result) {
						swal(translationService.translate('web-swal-customer-customerLog-success', 'Loggen ble fjernet!'), result, "success");
						me.table.reload();
					});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];
				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-customer-customerLog-update', 'Oppdater') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.update(item);
						}
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-customer-customerLog-delete', 'Slett') + '</a >',
						click: function ($itemScope, $event, modelValue, text, $li) {
							me.remove(item);
						}
					});
				}

				return options;
			};

			this.canCreate = function () {
				return $scope.hasCreateAccess('CustomerLog');
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('CustomerLog');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('CustomerLog');
			};
		}();

		$scope.getCardTables = function () {
			if ($scope.context === "HousingCustomer") {
				$scope.workOrderInfo.initTable();
			} else {
				$scope.customerDeliveryAddressInfo.initTable();
			}
			$scope.contactPersonInfo.initTable();
			$scope.requestInfo.initTable();
			$scope.contractInfo.initTable();
			$scope.customerLogInfo.initTable();
		};

		if ($scope.isUpdate)
			$scope.load($stateParams.guid);
		else
			$scope.init();
	}
})();
