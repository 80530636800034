(function () {
	angular.module('Plania').controller('EstateController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, translationService) {
		$scope.update = $scope.navigation.current.name === 'estate.edit';
		$scope.activateAutocomplete = false;
		$scope.isDocumentsCollapsed = true;
		$scope.reloadDeviations = false;
		$scope.reloadDocumentTable = false;
		$scope.reloadOperationalMessages = false;
		$scope.viewMode = $scope.update ? true : false;
		$scope.model = { Guid: $stateParams.guid };

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};


		$scope.isDynamicPropertiesCollapsed = false;

		$scope.model = { Guid: $stateParams.guid };

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Estate, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Estate, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Estate);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Estate);
			}
		};

		updateAccess();

		var columns = [
			'EstateCategory.Description', 'EstateCategory.Icon', 'EstateCategory.Color',
			'CostCenter.Id', 'CostCenter.Description',
			'BusinessUnit.Id', 'BusinessUnit.Description', 'BusinessUnit.IndexPosition', 'BusinessUnit.ExplanatoryText1', 'BusinessUnit.ExplanatoryText2', 'BusinessUnit.GuidParent',
			'BusinessUnit.ReferenceType.Id', 'BusinessUnit.ReferenceType.Description', 'BusinessUnit.ReferenceType.ExplanatoryText',
			'Owner.Id', 'Owner.Description',
			'ExternalFacilityManager.Id', 'ExternalFacilityManager.Description',
			'FacilityManager.FirstName', 'FacilityManager.LastName',
			'OperationsManager.FirstName', 'OperationsManager.LastName'
		];

		if ($scope.update) {
			repository.getMainModel(repository.apiData.estate.url, $scope.model.Guid, JSON.stringify(columns)).then(
                function (response) {
                	$scope.model = response.Data;

                	updateAccess();
                	$scope.reloadDocumentTable = true;
                	$scope.reloadDeviations = true;
					$scope.reloadOperationalMessages = true;
                	setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
                },
                function (error) {
                	repository.growl(error, 'danger');
                });

		} else {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidEstateCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.estateCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'EstateCategory':
								$scope.model.EstateCategory = { Guid: prefill.Value.Guid, Description: prefill.Value.Label, Caption: prefill.Value.Label };
								$scope.model.GuidEstateCategory = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			if ($stateParams.guidEntityToCopy) {
				repository.getSingle(repository.apiData.estate.url, $stateParams.guidEntityToCopy, JSON.stringify(columns)).then(function (result) {
					delete result.Data.Guid;
					delete result.Data.GuidGisEntity;

					$scope.model = result.Data;
					$scope.model.Id += '-N';
				});
			} else {
				getCategoryPrefill();
			}

			$scope.activateAutocomplete = true;
		}


		$scope.updateEstate = function (destination) {

			var success = function (result) {
				repository.growl($scope.update ? "Eiendom har blitt oppdatert" : 'Eiendom har blitt opprettet.', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				repository.growl(error, 'danger');
			};

			if ($scope.update)
				repository.updateSingle(repository.apiData.estate.url, $scope.model).then(success).catch(error);
			else
				repository.createSingle(repository.apiData.estate.url, $scope.model).then(success).catch(error);
		};

		$scope.removeEstate = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-estate-message', 'Eiendommen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-estate-button-confirm', 'Ja, slett eiendommen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.estate.url, $scope.model.Guid)
                    .then(function (result) {
                    	swal(translationService.translate('web-swal-estate-success', 'Eiendommen ble fjernet!'), result, "success");
                    	$scope.goBack('estate.list', { menuGuid: $scope.navigation.params.menuGuid });
                    });
			});
		};

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.GuidPost = null;
				$scope.model.PostalArea = '';
				$scope.model.County = '';
				$scope.model.Municipality = '';
			} else {
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.GuidPost = postalInfo.Guid;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.model.County = postalInfo.County;
				$scope.model.Municipality = postalInfo.MunicipalityName;
				$scope.postalInfo = postalInfo;
			}
		};



		$scope.onEstateCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidEstateCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidEstateCategory = category.Guid;
		};

        $scope.buildingColumns = [
            { Position: 1, Title: translationService.translate('web-building-id'), Property: 'Id', PropertyType: 'string' },
            { Position: 2, Title: translationService.translate('web-building-description'), Property: 'Description', PropertyType: 'string' },
            { Position: 3, Title: translationService.translate('web-building-buildingCategory'), Property: 'BuildingCategory.Description', PropertyType: 'string' }
		];

        $scope.autoCompleteFilter = function (filterName) {
            var filter = {};
            switch (filterName) {
                case "person":
                    filter.PropertyFilter = [{ Property: 'IsOperationsManager', Value: 1, Operator: '=' }];
					return filter;
				case "estateCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
				case "businessUnit":
					filter.FilterModel = {
						Condition: 'and',
						Rules: [
							{
								Condition: 'or',
								Rules: [
									{ Property: "DisabledFromDate", Operator: ">", Value: new Date().toISOString() },
									{ Property: "DisabledFromDate", Operator: "=", Value: null }
								]
							},
							{ Property: 'ReferenceType.Context', Operator: "=", Value: 3 }
						]
					};
					return filter;
            }
		};

		$scope.getBusinessUnitColumns = function () {
			return [
				{ Title: translationService.translate('web-businessUnit-indexPosition', 'Indeks'), Property: 'IndexPosition' },
				{ Title: translationService.translate('web-businessUnit-id', 'Id'), Property: 'Id' },
				{ Title: translationService.translate('web-businessUnit-description', 'Beskrivelse'), Property: 'Description' },
			];
		};

		$scope.openReferenceData = function (type) {
			var guid = "";
			var initialModel = null;

			switch (type) {
				case "BusinessUnit":
					guid = $scope.model.GuidBusinessUnit;
					initialModel = $scope.model.BusinessUnit;
			}

			if (!guid) return;

			$modal.open({
				templateUrl: 'app/referenceData/views/referenceDataInfoModal.html',
				controller: 'ReferenceDataInfoModalController',
				resolve: {
					modalParams: function () {
						return {
							guid: guid,
							initialModel: initialModel
						};
					}
				},
				size: 'lg'
			});
		};

		$scope.getPropertyCadastralNumber = function(){
			if (!$scope.model.PropertyCadastralNumber && !$scope.model.PropertyUnitNumber && !$scope.model.PropertyLeaseholdNumber && !$scope.model.PropertyUnderNumber) return '-';

			var str = $scope.model.PropertyUnderNumber || '';
			if (str) str += '/';
			str += $scope.model.PropertyLeaseholdNumber || '';
			if (str) str += '/';
			str += $scope.model.PropertyUnitNumber || '';
			str += '/';
			str += $scope.model.PropertyCadastralNumber || '';

			return str.split("/").reverse().join("/");
		};

		$scope.$watchCollection('model.Images', function () {
			if ($scope.model && $scope.model.Images && Array.isArray($scope.model.Images)) {
				var defaultImage = $scope.model.Images.find(function (img) {
					return img.IsDefault;
				});
				if (defaultImage)
					$scope.model.GuidDefaultImage = defaultImage.Guid;
				else
					delete $scope.model.GuidDefaultImage;
			}
		});
	}
})();
