(function () {
	//'$http', 'config', 'genericRepository', '$q',
	angular.module('Plania').factory('CommonService', ['$localStorage', commonService]);

	function commonService($localStorage) {

		var service = {};
		service.filterData = { isLoaded: false };

		service.clearFilterData = function () {
			delete service.filterData;
			service.filterData = { isLoaded: false };
			service.setEmptyEstate();
			service.setEmptyBuilding();
			service.setEmptyDataOwner();
			service.setEmptySelection();
		};

		//estate
		service.setEmptyEstate = function () {
			var name = 'Velg eiendom';
			var storageLanguageData = angular.fromJson($localStorage.languageData);
			if (storageLanguageData) {
				var translatedName = storageLanguageData[$localStorage.locale + 'web-estate-choose'];
				if (translatedName)
					name = translatedName;
			}
			service.filterData.selectedEstate = { Name: name, Guid: '' };
		};
		service.setEmptyEstate();
		service.setEstate = function (estate) {
			if (!estate) return;
			service.filterData.selectedEstate.Guid = estate.Guid;
			if (!estate.Name)
				service.filterData.selectedEstate.Name = estate.Caption;
			else {
				service.filterData.selectedEstate.Name = estate.Name;
			}
			$localStorage.filterData = service.filterData;
		};
		//end estate

		//building
		service.setEmptyBuilding = function () {
			var name = 'Velg bygg';
			var storageLanguageData = angular.fromJson($localStorage.languageData);
			if (storageLanguageData) {
				var translatedName = storageLanguageData[$localStorage.locale + 'web-building-choose'];
				if (translatedName)
					name = translatedName;
			}
			service.filterData.selectedBuilding = { Name: name, Guid: '' };
		};
		service.setEmptyBuilding();
		service.setBuilding = function (building) {
			service.filterData.selectedBuilding.Guid = building.Guid;
			if (!building.Name)
				service.filterData.selectedBuilding.Name = building.Caption;
			else {
				service.filterData.selectedBuilding.Name = building.Name;
			}

			if (building.Estate)
				service.filterData.selectedBuilding.Estate = building.Estate;

			$localStorage.filterData = service.filterData;
		};
		//end building

		//data owner
		service.setEmptyDataOwner = function () {
			var storageLanguageData = angular.fromJson($localStorage.languageData);
			var description = 'Klient';
			if (storageLanguageData)
				description = storageLanguageData[$localStorage.locale + 'web-dataowner'];
			service.filterData.selectedDataOwner = { Description: description, Guid: '', GuidDefaultDocumentType: '', DefaultDocumentTypeId: '' };
		};
		service.setEmptyDataOwner();
		service.setDataOwner = function (owner) {
			service.filterData.selectedDataOwner.Guid = owner.Guid;
			service.filterData.selectedDataOwner.Description = owner.Description;
			service.filterData.selectedDataOwner.Dimension1 = owner.Dimension1;
			service.filterData.selectedDataOwner.GuidImageLogo = owner.GuidImageLogo;
			if (owner.GuidDefaultDocumentType && owner.DefaultDocumentType) {
				service.filterData.selectedDataOwner.GuidDefaultDocumentType = owner.GuidDefaultDocumentType;
				service.filterData.selectedDataOwner.DefaultDocumentTypeId = owner.DefaultDocumentType.Id;
			} else {
				service.filterData.selectedDataOwner.GuidDefaultDocumentType = '';
				service.filterData.selectedDataOwner.DefaultDocumentTypeId = '';
			}

			if (owner.GuidWoXEqDocumentCategory && (owner.DocumentCategory1 || owner.WoXEqDocumentCategory)) {
				service.filterData.selectedDataOwner.GuidWoXEqDocumentCategory = owner.GuidWoXEqDocumentCategory;
				service.filterData.selectedDataOwner.WoXEqDocumentCategory = owner.DocumentCategory1 ? owner.DocumentCategory1.Description : owner.WoXEqDocumentCategory;
			} else {
				service.filterData.selectedDataOwner.GuidWoXEqDocumentCategory = '';
				service.filterData.selectedDataOwner.WoXEqDocumentCategory = '';
			}

			$localStorage.filterData = service.filterData;
		};
		//end data owner

		//building selection
		service.setEmptySelection = function () {
			var storageLanguageData = angular.fromJson($localStorage.languageData);
			var description = 'Velg utvalg';
			if (storageLanguageData)
				description = storageLanguageData[$localStorage.locale + 'web-buildingselection-choose'];
			service.filterData.selectedSelection = { Description: description, Guid: '' };
		};
		service.setEmptySelection();
		service.setSelectedSelection = function (selection) {
			service.filterData.selectedSelection.Guid = selection.Guid;
			if (selection.Description) {
				service.filterData.selectedSelection.Description = selection.Description;
			}
			$localStorage.filterData = service.filterData;
		};
		//end building selection

		service.getFilterData = function () {
			if (!service.filterData.isLoaded) {
				var storageFilterData = angular.fromJson($localStorage.filterData);

				if (storageFilterData) {
					service.setBuilding(storageFilterData.selectedBuilding);
					service.setDataOwner(storageFilterData.selectedDataOwner);
					service.setSelectedSelection(storageFilterData.selectedSelection);
					service.setEstate(storageFilterData.selectedEstate);
				}
				service.filterData.isLoaded = true;
			}

			return service.filterData;
		};

		service.disableWebMenuNavigation = function () {
			return service.getFilterData().selectedBuilding.Guid.length === 0;
		};


		var lastRegister = {};
		service.setLastRegisterGuid = function (object, guid) {
			lastRegister[object] = guid;
		};

		service.getLastRegisterGuid = function (object) {
			var guid = lastRegister[object];
			lastRegister = {};
			return guid;
		};


		service.prefix = {
			internalWeb: 'internalWeb',
			Undefined: 'undefined',
			Null: 'Null',
			//DocumentUser: 'DocumentUser',
			Account: 'Account',
			AccountingDimension: 'AccountingDimension',
			AccountingXAccounting: 'AccountingXAccounting',
			AccountXAccounting: 'AccountXAccounting',
			ActivityCategory: 'ActivityCategory',
			ActivityConstraint: 'ActivityConstraint',
			ActivityGroup: 'ActivityGroup',
			Alarm: 'Alarm',
			AlarmLog: 'AlarmLog',
			ApiClient: 'ApiCLient',
			ApiRequestLog: 'ApiRequestLog',
			Area: 'Area',
			AreaAvailability: 'AreaAvailability',
			AreaCategory: 'AreaCategory',
			AreaCategoryXAreaType: 'AreaCategoryXAreaType',
			AreaPrice: 'AreaPrice',
			AreaType: 'AreaType',
			AreaXCleaningTask: 'AreaXCleaningTask',
			AreaXEntity: 'AreaXEntity',
			Article: 'Article',
			Barcode: 'Barcode',
			BcfProject: "BcfProject",
			BookingCategory: 'BookingCategory',
			Budget: 'Budget',
			Building: 'Building',
			BuildingCategory: 'BuildingCategory',
			BuildingMeanTemperature: 'BuildingMeanTemperature',
			BuildingSelection: 'BuildingSelection',
			BuildingTemperaturePower: 'BuildingTemperaturePower',
			BuildingXAreaCategory: 'BuildingXAreaCategory',
			BuildingXBuildingSelection: 'BuildingXBuildingSelection',
			BuildingXContract: 'BuildingXContract',
			BuildingXPerson: 'BuildingXPerson',
			BuildingXSupplier: 'BuildingXSupplier',
			Cause: 'Cause',
			ControlList: 'ControlList',
			ControlListItemAnswer: 'ControlListItemAnswer',
			ControlListXEntity: 'ControlListXEntity',
			Cleaning: 'Cleaning',
			CleaningCode: 'CleaningCode',
			CleaningInterval: 'CleaningInterval',
			CleaningMethod: 'CleaningMethod',
			CleaningQuality: 'CleaningQuality',
			CleaningQualityControl: 'CleaningQualityControl',
			CleaningTask: 'CleaningTask',
			CleaningType: 'CleaningType',
			CleaningXArea: 'CleaningXArea',
			CleaningXCleaningMethod: 'CleaningXCleaningMethod',
			Component: 'Component',
			ComponentCategory: 'ComponentCategory',
			ComponentXArea: 'ComponentXArea',
			ComponentXEquipment: 'ComponentXEquipment',
			ComponentXSupplier: 'ComponentXSupplier',
			Condition: 'Condition',
			ConditionType: 'ConditionType',
			Configuration: 'Configuration',
			Consequence: 'Consequence',
			ConsequenceType: 'ConsequenceType',
			ConstructionType: 'ConstructionType',
			Consumable: 'Consumable',
			ContactPerson: 'ContactPerson',
			Contract: 'Contract',
			ContractAdjustment: 'ContractAdjustment',
			ContractAdjustmentConditions: 'ContractAdjustmentConditions',
			ContractCategory: 'ContractCategory',
			ContractEntityPriceXService: 'ContractEntityPriceXService',
			ContractItem: 'ContractItem',
			ContractItemXService: 'ContractItemXService',
			ContractLease: 'ContractLease',
			ContractLeaseItem: 'ContractLeaseItem',
			ContractType: 'ContractType',
			ContractWarranty: 'ContractWarranty',
			Cost: 'Cost',
			CostCenter: 'CostCenter',
			Customer: 'Customer',
			CustomerCategory: 'CustomerCategory',
			CustomerDeliveryAddress: 'CustomerDeliveryAddress',
			CustomerGroup: 'CustomerGroup',
			CustomerLineOfBusiness: 'CustomerLineOfBusiness',
			CustomerLog: 'CustomerLog',
			CustomerXAreaCategory: 'CustomerXAreaCategory',
			CustomFunction: 'CustomFunction',
			CustomReport: 'CustomReport',
			CylinderType: 'CylinderType',
			DatabaseConnection: 'DatabaseConnection',
			DataImport: 'DataImport',
			DataOwner: 'DataOwner',
			DataTransfer: 'DataTransfer',
			DataTransferXFunction: 'DataTransferXFunction',
			DeliveryTerm: 'DeliveryTerm',
			Department: 'Department',
			Deviation: 'Deviation',
			DeviationType: 'DeviationType',
			Document: 'Document',
			DocumentCategory: 'DocumentCategory',
			DocumentRevision: 'DocumentRevision',
			DocumentType: 'DocumentType',
			DocumentWebAccess: 'DocumentWebAccess',
			DocumentXEntity: 'DocumentXEntity',
			DoorKey: 'DoorKey',
			DoorKeySystem: 'DoorKeySystem',
			DoorKeyTransaction: 'DoorKeyTransaction',
			DoorKeyXDoorLock: 'DoorKeyXDoorLock',
			DoorLock: 'DoorLock',
			DoorLockXArea: 'DoorLockXArea',
			Drawing: 'Drawing',
			DrawingText: 'DrawingText',
			DrawingXLayerGroup: 'DrawingXLayerGroup',
			DutyLog: 'DutyLog',
			DutyLogCategory: 'DutyLogCategory',
			DutyLogCategoryXGroup: 'DutyLogCategoryXGroup',
			DutyLogEvent: 'DutyLogEvent',
			DutyLogGroup: 'DutyLogGroup',
			EmailTemplate: 'EmailTemplate',
			EnergyBlock: 'EnergyBlock',
			EnergyCategory: 'EnergyCategory',
			EnergyConsumption: 'EnergyConsumption',
			EnergyCounter: 'EnergyCounter',
			EnergyDataFormat: 'EnergyDataFormat',
			EnergyMeter: 'EnergyMeter',
			EnergyPeriodicTask: 'EnergyPeriodicTask',
			EnergyReading: 'EnergyReading',
			EnergyUnit: 'EnergyUnit',
			EnergyWorkOrder: 'EnergyWorkOrder',
			EnergyWorkOrderXCounter: 'EnergyWorkOrderXCounter',
			EntityHistory: 'EntityHistory',
			EntityComment: 'EntityComment',
			EntityLink: 'EntityLink',
			EntityTask: 'EntityTask',
			EntityCounter: 'EntityCounter',
			EntityMailList: 'EntityMailList',
			EntityPermission: 'EntityPermission',
			EntityPermissionProfile: 'EntityPermissionProfile',
			Equipment: 'Equipment',
			EquipmentCategory: 'EquipmentCategory',
			EquipmentOperatingHours: 'EquipmentOperatingHours',
			EquipmentOperatingHourType: 'EquipmentOperatingHourType',
			EquipmentRelocation: 'EquipmentRelocation',
			EquipmentRental: 'EquipmentRental',
			EquipmentTemplate: 'EquipmentTemplate',
			EquipmentTemplateXCategory: 'EquipmentTemplateXCategory',
			Estate: 'Estate',
			EstateCategory: 'EstateCategory',
			Event: 'Event',
			EventXEntity: 'EventXEntity',
			FieldInfoOverride: 'FieldInfoOverride',
			FollowUp: 'FollowUp',
			GeneralOptions: 'GeneralOptions',
			GisEntity: 'GisEntity',
			Gmdn: 'Gmdn',
			GroundXBuilding: 'GroundXBuilding',
			Hatching: 'Hatching',
			HatchingXArea: 'HatchingXArea',
			HourType: 'HourType',
			BimFile: 'BimFile',
			BimProject: 'BimProject',
			IfcSettings: 'IfcSettings',
			Image: 'Image',
			ImageXEntity: 'ImageXEntity',
			IntegrationData: 'IntegrationData',
			InvoicingTerm: 'InvoicingTerm',
			KeyXCustomer: 'KeyXCustomer',
			KeyXPerson: 'KeyXPerson',
			KeyXPersonDelivery: 'KeyXPersonDelivery',
			KeyXUser: 'KeyXUser',
			LanguageEntry: 'LanguageEntry',
			LanguageField: 'LanguageField',
			LanguageXWebText: 'LanguageXWebText',
			LayerGroup: 'LayerGroup',
			LayerGroupSet: 'LayerGroupSet',
			LayerGroupSetXLayerGroup: 'LayerGroupSetXLayerGroup',
			LeaseFollowUp: 'LeaseFollowUp',
			ListHighlight: 'ListHighlight',
			ListInfo: 'ListInfo',
			ListLayout: 'ListLayout',
			Log: 'Log',
			LogSettings: 'LogSettings',
			Meter: 'Meter',
			MeterReading: 'MeterReading',
			MeterXArea: 'MeterXArea',
			MobileMenuProfile: 'MobileMenuProfile',
			NamedSelection: 'NamedSelection',
			NamedSelectionValue: 'NamedSelectionValue',
			NonsReference: 'NonsReference',
			OperationalMessage: 'OperationalMessage',
			Organization: 'Organization',
			OrganizationSection: 'OrganizationSection',
			OrganizationUnit: 'OrganizationUnit',
			OrganizationXArea: 'OrganizationXArea',
			PasswordHistory: 'PasswordHistory',
			PaymentOrder: 'PaymentOrder',
			PaymentOrderDistribution: 'PaymentOrderDistribution',
			PaymentOrderForm: 'PaymentOrderForm',
			PaymentOrderItem: 'PaymentOrderItem',
			PaymentOrderItemXService: 'PaymentOrderItemXService',
			PaymentOrderXBuildingXAreaCategoryXService: 'PaymentOrderXBuildingXAreaCategoryXService',
			PaymentOrderXCostCenterXService: 'PaymentOrderXCostCenterXService',
			PaymentTerm: 'PaymentTerm',
			PeriodicTask: 'PeriodicTask',
			PeriodicTaskEmailList: 'PeriodicTaskEmailList',
			PeriodicTaskXArea: 'PeriodicTaskXArea',
			PeriodicTaskXCraft: 'PeriodicTaskXCraft',
			PeriodicTaskXEquipment: 'PeriodicTaskXEquipment',
			PeriodicTaskXSparePart: 'PeriodicTaskXSparePart',
			PeriodicTaskXResourceGroup: 'PeriodicTaskXResourceGroup',
			PeriodicTaskXStandardText: 'PeriodicTaskXStandardText',
			PeriodOfNotice: 'PeriodOfNotice',
			Person: 'Person',
			PersonRole: 'PersonRole',
			PostalCode: 'PostalCode',
			Price: 'Price',
			PriceSheet: 'PriceSheet',
			PriceSheetCategory: 'PriceSheetCategory',
			PriceSheetCategoryPrice: 'PriceSheetCategoryPrice',
			PriceSheetRevision: 'PriceSheetRevision',
			PriceSheetXBuilding: 'PriceSheetXBuilding',
			Priority: 'Priority',
			Project: 'Project',
			ProjectCategory: 'ProjectCategory',
			ProjectType: 'ProjectType',
			ProxyEntity: 'ProxyEntity',
			PurchaseOrder: 'PurchaseOrder',
			PurchaseOrderEmailList: 'PurchaseOrderEmailList',
			PurchaseOrderForm: 'PurchaseOrderForm',
			PurchaseOrderItem: 'PurchaseOrderItem',
			ReferenceData: 'ReferenceData',
			ReferenceType: 'ReferenceType',
			Region: 'Region',
			ReminderTemplate: 'ReminderTemplate',
			RentalGroup: 'RentalGroup',
			Report: 'Report',
			Request: 'Request',
			ResourceGroup: 'ResourceGroup',
			ResourceGroupXCause: 'ResourceGroupXCause',
			Role: 'Role',
			RoleXUser: 'RoleXUser',
			ScheduledJob: 'ScheduledJob',
			ScheduledJobExecution: 'ScheduledJobExecution',
			Service: 'Service',
			ServicePrice: 'ServicePrice',
			ServiceXAreaCategory: 'ServiceXAreaCategory',
			Session: 'Session',
			SparePart: 'SparePart',
			SparePartCounting: 'SparePartCounting',
			SparePartCountingItem: 'SparePartCountingItem',
			SparePartCountingList: 'SparePartCountingList',
			SparePartWithdrawal: 'SparePartWithdrawal',
			StandardText: 'StandardText',
			StartPage: 'StartPage',
			Supplier: 'Supplier',
			SupplierAgreement: 'SupplierAgreement',
			SupplierLineOfBusiness: 'SupplierLineOfBusiness',
			Symbol: 'Symbol',
			Task: 'Task',
			TransactionLog: 'TransactionLog',
			User: 'User',
			UserProfile: 'UserProfile',
			UserSession: 'UserSession',
			UserXCustomer: 'UserXCustomer',
			UserXSparePartCountingList: 'UserXSparePartCountingList',
			UserXWebProfile: 'UserXWebProfile',
			VideoXEntity: 'VideoXEntity',
			WebCustomer: 'WebCustomer',
			WebDashboard: 'WebDashboard',
			WebListView: 'WebListView',
			WebListViewColumn: 'WebListViewColumn',
			WebMenu: 'WebMenu',
			WebProfile: 'WebProfile',
			WebProfileXWebMenu: 'WebProfileXWebMenu',
			WebUserToken: 'WebUserToken',
			WorkOrder: 'WorkOrder',
			WorkOrderEmailList: 'WorkOrderEmailList',
			WorkOrderTrace: 'WorkOrderTrace',
			WorkOrderXArea: 'WorkOrderXArea',
			WorkOrderXResourceGroup: 'WorkOrderXResourceGroup',
			WorkOrderXSparePart: 'WorkOrderXSparePart',
		};
		service.alreadyRefreshingReport = false;
		return service;
	}
})
	();
