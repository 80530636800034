(function () {
	angular.module('Plania').controller('CreatePeriodicTaskXEquipmentController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', 'TranslationService', "$localStorage", 'ListService', 'Constants', controller]);

	function controller($scope, $modalInstance, params, repository, ngTableParams, translationService, $localStorage, listService, constants) {
		$scope.equipmentColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description' },
			{ Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Id' },
			{ Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Description' },
		];

		var createPropertyFilter = function () {
			var filter = [{ Property: 'HasConditionControl', Operator: '=', Value: 1 }];

			switch ($localStorage.generalOptions.InspectedEntityLocation) {
				case constants.inspectedEntityLocation.estate:
					if (params.guidEstate) {
						filter.push({ Property: 'Building.GuidEstate', Operator: '=', Value: params.guidEstate });
					}
					break;
				case constants.inspectedEntityLocation.building:
					if (params.guidBuilding) {
						filter.push({ Property: 'GuidBuilding', Operator: '=', Value: params.guidBuilding });
					}
					break;
				default:
			}
			return filter;
		};

		$scope.equipmentTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: { PropertyFilter: createPropertyFilter() }
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					var columns = [];
					$scope.equipmentColumns.forEach(function (column) {
						columns.push(column.Property);
					});

					repository.GetPaginated(repository.apiData.equipment.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.selectedEquipments = [];

		$scope.save = function () {
			var xRef = [];

			$scope.selectedEquipments.forEach(function (eq) {
				xRef.push({ GuidPeriodicTask: params.guidPeriodicTask, GuidEquipment: eq });
			});

			repository.createMultiple(repository.apiData.periodicTaskXEquipment.url, xRef, 'Prefix=PeriodicTaskXEquipment')
				.then(function (result) {
					repository.growl(translationService.translate('periodicTask-equipment-add-success', 'Anlegg har blitt lagt til'), 'success');
					$modalInstance.close('OK');
				});
		};

		$scope.addEquipment = function (guid) {
			if (!_.contains($scope.selectedEquipments, guid))
				$scope.selectedEquipments.push(guid);
			else {
				for (var i = 0; i < $scope.selectedEquipments.length; i++) {
					if ($scope.selectedEquipments[i] === guid) {
						$scope.selectedEquipments.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.alreadySaved = function (guid) {
			return _.includes(params.selectedGuids, guid);
		};

		$scope.isEquipmentSelected = function (guid) {
			return _.contains($scope.selectedEquipments, guid);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
