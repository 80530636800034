(function () {

	angular.module('Plania').controller('ShortcutController', ['$scope', 'MenuService', '$modal', 'CommonService', 'states', '$localStorage', controller]);

	function controller($scope, service, $modal, commonService, states, $localStorage) {
        $scope.shortcuts = [];
        service.getShortcuts().then(function (webMenus) {
            $scope.shortcuts = webMenus;
        });

        $scope.createDocument = function () {
            $modal.open({
                templateUrl: 'app/document/views/createDocumentModal.html',
                controller: 'CreateDocumentController',
                size:'lg'
            }).result.then(function () {
            }, function () {
            });
		};

		$scope.activeShortcut = null;

		$scope.ChangeView = function (shortcut) {
			if ($scope.isDisabled(shortcut)) {
                return;
			}

			$scope.activeShortcut = shortcut;

            if (shortcut.state) {
                var params = { menuGuid: shortcut.guid };
                if (shortcut.isBuildingDependent && shortcut.state === 'building.edit')
					params.guid = commonService.getFilterData().selectedBuilding.Guid;

				// Reset pagination
				try {
					var splittedState = shortcut.state.split(".");
					if (splittedState[1] && splittedState[1] === "list") {
						var state = _.find(states.list, function (state) {
							return state.name === shortcut.state;
						});
						if (state && state.params && state.params.entityData && state.params.entityData.prefix) {
							var prefix = state.params.entityData.prefix;
							if ($localStorage.listParams && $localStorage.listParams[prefix])
								delete $localStorage.listParams[prefix].page;
						}
					}
				} catch (ex) {}

	            $scope.navigation.go(shortcut.state, params, { reload: true, inherit: false });
            }
            else {
                shortcut.open = !shortcut.open;
            }
        };

        $scope.isDisabled = function (shortcut) {
            if (shortcut.state) {
                if (shortcut.isBuildingDependent) {
                    return commonService.disableWebMenuNavigation();
                }
            }
            return false;
        };

    }
})();
