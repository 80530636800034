(function () {
	var app = angular.module('Plania');
	app.directive('controlListHistoryDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', controller],
			templateUrl: 'app/controlList/views/editControlListViews/controlListHistory.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.controlListXEntitySearch = {
			focus: false,
			toggle: false
		};

		$scope.hasPageLoaded = $scope.currentTab === 'history';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'history' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.dropdownFilters = [
			{ value: 'all', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-all', 'Alle') },
			{ value: 'new', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-new', 'Nye') },
			{ value: 'active', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-active', 'Aktive') },
			{ value: 'completed', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-completed', 'Fullførte') },
			{ value: 'notexecuted', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-notExecuted', 'Ikke utført') }
		];
		$scope.selectedDropdownFilter = $scope.dropdownFilters[0];

		$scope.applyDropdownFilter = function (dropdownFilter, filter) {
			switch (dropdownFilter.value) {
				case 'all':
					break;
				case 'new':
					filter.FilterModel.Rules.push({ Property: 'Status', Operator: '=', Value: '0' });
					break;
				case 'active':
					filter.FilterModel.Rules.push({ Property: 'Status', Operator: '=', Value: '1' });
					break;
				case 'completed':
					filter.FilterModel.Rules.push({ Property: 'Status', Operator: '=', Value: '2' });
					break;
				case 'notexecuted':
					filter.FilterModel.Rules.push({ Property: 'Status', Operator: '=', Value: '3' });
					break;
			}
		};

		$scope.controlListXEntityFilter = {
			FilterModel: {
				Condition: "and",
				Rules: [
					{
						Condition: "or",
						Rules: [
							{
								Property: 'ControlList.GuidMasterRecord',
								Operator: '=',
								Value: $scope.model.Guid,
							},
							{
								Property: 'ControlList.Guid',
								Operator: '=',
								Value: $scope.model.Guid,
							}
						]
					}
				]
			}
		};

		$scope.controlListXEntityColumns = [
			"WorkOrder.Id", "WorkOrder.Description",
			"Area.Id", "Area.Description", "Area.IsHousing",
			"Equipment.Id", "Equipment.Description", 
		];

		$scope.controlListXEntityContext = planiaUtils.contextIds.controlListControlListXEntityHistory;

		$scope.openControlList = function (item) {
			var caption = "";
			if (item.WorkOrder)
				caption = item.WorkOrder.Caption;
			else if (item.PeriodicTask)
				caption = item.PeriodicTask.Caption;

			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							workOrderCaption: caption,
							controlList: $scope.model,
							controlListXEntity: item,
							restrictEdit: true
						};
					}
				}
			});
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="fas' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.controlListXEntityContextMenuOptions = function (item) {
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-openControlList', 'Åpne kontrolliste') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.openControlList(item);
				}
			});

			if ($scope.hasReadAccess("WorkOrder") && item.GuidWorkOrder) {
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-workOrder-view', 'Åpne arbeidsordre')));
			}

			if ($scope.hasReadAccess("PeriodicTask") && item.GuidPeriodicTask) {
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.GuidPeriodicTask }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-periodicTask-view', 'Åpne periodisk rutine')));
			}

			if ($scope.hasReadAccess("Area") && item.GuidArea) {
				if (item.Area.IsHousing)
					options.push(getMenuLink($rootScope.navigation.href('housing.edit', { guid: item.GuidArea }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-housing-view', 'Åpne bolig')));
				else
					options.push(getMenuLink($rootScope.navigation.href('area.edit', { guid: item.GuidArea }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-area-view', 'Åpne rom')));
			}

			if ($scope.hasReadAccess("Equipment") && item.GuidEquipment) {
				options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.GuidEquipment }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-equipment-view', 'Åpne anlegg')));
			}

			return options;
		};
	}
})();
