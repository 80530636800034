(function () {
	angular.module('Plania').controller('CreateDataImportController', ['$scope', 'TranslationService', 'Repository', 'RegisteredFieldService', '$q', '$stateParams', '$rootScope', controller]);

	function controller($scope, translationService, repository, registeredFieldService, $q, $stateParams, $rootScope) {
		$scope.model = [
			{
				id: 'excel',
				enabled: $rootScope.hasModule('DataImport') || $rootScope.hasModule('DataAcquisition'),
				title: translationService.translate('web-dataImport-create-excel-title', 'EXCEL'),
				description: translationService.translate('web-dataImport-create-excel-description', 'Importer data fra Excel ark.'),
				link: function () {
					$scope.navigation.transitionTo('dataImport.edit');
				},
				color: 'mdc-bg-green-400',
				disabledReason: translationService.translate('web-dataImport-create-excel-disabledReason', 'Forutsetter DataImport lisens.')
			},
			{
				id: 'bimSync',
				enabled: $rootScope.hasModule('IFCBimsyncHosting'),
				title: translationService.translate('web-dataImport-create-bim-title', 'BIMSYNC'),
				description: translationService.translate('web-dataImport-create-bim-description', 'Importer data fra BimSync model.'),
				link: function () {
					$scope.navigation.transitionTo('dataImport.bimsync');
				},
				color: 'mdc-bg-blue-100',
				disabledReason: translationService.translate('web-dataImport-create-bim-disabledReason', 'Forutsetter IFCBimsyncHosting lisens.')
			}
		];
	}
})();
