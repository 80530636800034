(function () {
	angular.module('Plania').controller('PeriodicTaskGenerateOrdersConfirmationModalController', ['$scope', 'Repository', 'params', '$modalInstance', 'TranslationService', '$localStorage', '$window', '$rootScope', 'NgTableParams', controller]);
	function controller($scope, repository, params, $modalInstance, translationService, $localStorage, $window, $rootScope, ngTableParams) {
		// params: { guids: string[] }
		$scope.periodicTaskGuids = _.clone(params.guids) || [];
		$scope.isRunningAction = false;
		$scope.currentTab = 'info';
		$scope.estimatedElapsedTime = $scope.periodicTaskGuids.length * 7;
		$scope.showStartDate = $localStorage.generalOptions.AskForStartDateManualWo;

		// List of PeriodicTasks
		$scope.totalFailedPeriodicTasks = 0;
		$scope.periodicTaskResponses = [];
		$scope.errorMessage = null;

		$scope.generateOrders = function (askForStartDateManualWo) {
			if ($scope.periodicTaskGuids.length === 0) return;
			var date = null;
			if (askForStartDateManualWo && $scope.showStartDate)
				date = new Date().toISOString();
			$scope.isRunningAction = true;
			repository.runAction(repository.apiData.periodicTask.endpoint.generateOrders, $scope.periodicTaskGuids, 'startDate=' + date).then(function (result) {
				$scope.isRunningAction = false;
				$scope.errorMessage = null;
				$scope.periodicTaskResponses = result;
				$scope.periodicTaskResponses.forEach(function (periodicTask) {
					if (!periodicTask.ErrorMessages || periodicTask.ErrorMessages.length === 0) {
						var succededPeriodicTaskIndex = $scope.periodicTaskGuids.findIndex(function (guid) { return guid === periodicTask.Guid; });
						if (succededPeriodicTaskIndex !== -1)
							$scope.periodicTaskGuids.splice(succededPeriodicTaskIndex, 1);
					}
				});
				$scope.estimatedElapsedTime = $scope.periodicTaskGuids.length * 7;

				if ($scope.periodicTaskGuids.length === 0) {
					repository.growl(translationService.translate('web-periodicTask-generateOrdersConfirmationModal-success', 'Arbeidsordre er blitt opprettet.'), "success");
					$modalInstance.close();
				} else {
					$scope.errorMessage = translationService.translate('web-periodicTask-generateOrdersConfirmationModal-failed', 'Periodiske rutiner feilet med å opprette AO, sjekk listen for feil.');
					repository.growl($scope.errorMessage, 'warning');

					$scope.currentTab = 'errorMessages';
					$scope.totalFailedPeriodicTasks = $scope.periodicTaskGuids.length;
					$scope.errorMessageTable.reload();
				}
			}, function (error) {
				repository.growl(error, 'danger');
				$scope.isRunningAction = false;
			});
		};

		$scope.errorMessageTable = new ngTableParams({
			page: 1,
			count: 10
		}, {
			total: $scope.periodicTaskResponses.length,
			counts: [5, 10, 20],
			getData: function ($defer, params) {
				$scope.errorMessageTable.settings().total = $scope.periodicTaskResponses.length;
				$defer.resolve($scope.periodicTaskResponses.filter(function (i) { return i.ErrorMessages.length !== 0; }).slice((params.page() - 1) * params.count(), params.page() * params.count()));
			}
		});

		$scope.contextMenuOptions = function (item) {
			var options = [];
			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-calendar-note fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-periodicTask-generateOrdersConfirmationModal-viewPeriodicTask', 'Åpne periodisk rutine i nytt vindu') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$window.open($rootScope.navigation.href('periodicTask.edit', { guid: item.Guid }), '_blank');
				}
			});

			if ($scope.hasReadAccess("WorkOrder") && item.GuidWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-periodicTask-generateOrdersConfirmationModal-viewExistingWorkOrder', 'Åpne eksisterende arbeidsorde i nytt vindu') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$window.open($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), '_blank');
					}
				});
			}
			return options;
		};

		$scope.cancel = function () {
			if ($scope.isRunningAction) {
				swal({
					title: translationService.translate('web-periodicTask-generateOrdersConfirmationModal-swal-title', 'Vil du lukke dette vinduet?'),
					text: translationService.translate('web-periodicTask-generateOrdersConfirmationModal-swal-text', 'Plania vil fortsette å opprette arbeidsordre til fra valgte rutiner, men du vil ikke kunne se potensielle feilmeldinger hvis du lukker dette vinduet.'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-button-yes-continue', 'Ja, fortsett'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					if ($scope.periodicTaskGuids.length !== params.guids.length)
						$modalInstance.close();
					else
						$modalInstance.dismiss('canceled');
				});
			} else {
				if ($scope.periodicTaskGuids.length !== params.guids.length)
					$modalInstance.close();
				else
					$modalInstance.dismiss('canceled');
			}
		};
	}
}) ();
