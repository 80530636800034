(function () {
    angular.module('Plania').controller('ControlListPeriodicTaskModalController', ['$scope', '$modalInstance', 'modalParams', 'Repository', 'ngTableParams', 'TranslationService', 'ListService', '$q', 'EnumService', controller]);

    function controller($scope, $modalInstance, modalParams, repository, ngTableParams, translationService, listService, $q, enumService) {
        $scope.modalParams = modalParams;
        $scope.selectedItems = [];
        $scope.selectedEntityType = modalParams.canConnect.UseWithWorkOrder ? 'WorkOrder' : modalParams.canConnect.UseWithEquipment ? 'Equipment' : modalParams.canConnect.UseWithArea ? 'Area' : null;

        $scope.getPropertyValue = function (item, column) {
            return listService.GetPropertyValue(item, column);
        };

        $scope.setColumnsByEntityType = function () {
            $scope.columns = [
                { Position: 1, Title: translationService.translate('web-periodicTask-Id', 'Periodisk Nr.'), Property: 'Id', PropertyType: 'string' },
                { Position: 2, Title: translationService.translate('web-periodicTask-Description', 'Periodisk Beskrivelse'), Property: 'Description', PropertyType: 'string' },
                { Position: 3, Title: translationService.translate('web-periodicTask-Building-Id', 'Bygg Id'), Property: 'Building.Id', PropertyType: 'string' },
                { Position: 4, Title: translationService.translate('web-periodicTask-Building-Description', 'Byggningsbeskrivelse'), Property: 'Building.Description', PropertyType: 'string' },
            ];

            switch ($scope.selectedEntityType) {
                case 'Equipment':
                    $scope.columns.push({ Position: 5, Title: translationService.translate('web-periodicTask-Equipment-Id', 'Anlegg Id'), Property: 'Equipment.Id', PropertyType: 'string' });
                    $scope.columns.push({ Position: 6, Title: translationService.translate('web-periodicTask-Equipment-Description', 'Anleggsnavn'), Property: 'Equipment.Description', PropertyType: 'string' });
					$scope.columns.push({ Position: 7, Title: translationService.translate('web-periodicTask-EquipmentCategory-Description', 'Anlegg Type'), Property: 'Equipment.EquipmentCategory.Description', PropertyType: 'string' });
					$scope.columns.push({ Position: 8, Title: translationService.translate('web-periodicTask-resourceGroup-id', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' });
                    break;
                case 'Area':
                    $scope.columns.push({ Position: 5, Title: translationService.translate('web-periodicTask-Area-Id', 'Rom Id'), Property: 'Area.Id', PropertyType: 'string' });
                    $scope.columns.push({ Position: 6, Title: translationService.translate('web-periodicTask-Area-Description', 'Rom beskrivelse'), Property: 'Area.Description', PropertyType: 'string' });
					$scope.columns.push({ Position: 7, Title: translationService.translate('web-periodicTask-AreaCategory-Description', 'Rom type'), Property: 'Area.AreaCategory.Description', PropertyType: 'string' });
					$scope.columns.push({ Position: 8, Title: translationService.translate('web-periodicTask-resourceGroup-id', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' });
                    break;
                case 'WorkOrder':
                    $scope.columns.push({ Position: 5, Title: translationService.translate('web-periodicTask-Equipment-Id', 'Anlegg Id'), Property: 'Equipment.Id', PropertyType: 'string' });
                    $scope.columns.push({ Position: 6, Title: translationService.translate('web-periodicTask-Equipment-Description', 'Anleggsnavn'), Property: 'Equipment.Description', PropertyType: 'string' });
					$scope.columns.push({ Position: 7, Title: translationService.translate('web-periodicTask-Area-Id', 'Rom Id'), Property: 'Area.Id', PropertyType: 'string' });
					$scope.columns.push({ Position: 8, Title: translationService.translate('web-periodicTask-resourceGroup-id', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' });
            }
        };

        $scope.itemTable = new ngTableParams(
            {
                page: 1,
                count: 10,
                sorting: { Id: 'asc' },
                filter: { PropertyFilter: [{ Property: 'IsTemplate', Operator: '=', Value: '0' }] }
            },
            {
                total: 0,
                counts: [10, 25, 50],
                paginationMaxBlocks: 8,
                getData: function ($defer, params) {
                    var filter = angular.copy(params.filter());
                    $scope.setColumnsByEntityType();

                    filter.GuidConnectedControlList = modalParams.guid;
                    switch ($scope.selectedEntityType) {
                        case 'WorkOrder':
                            filter.FilterEquipment = false;
                            filter.FilterArea = false;
                            break;
                        case 'Equipment':
                            filter.FilterEquipment = true;
                            filter.FilterArea = false;
                            break;
                        case 'Area':
                            filter.FilterEquipment = false;
                            filter.FilterArea = true;
                            break;
                    }

                    var columns = _.map($scope.columns, 'Property');


                    repository.GetPaginated(repository.apiData.periodicTask.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns)).then(
                        function (result) {
                            params.total(result.TotalCount);

                            $defer.resolve(result.List);
                        },
                        function (error) {
                            repository.growl(error, 'danger');
                        });
                }
            });

        $scope.addSelectedItem = function (object) {
            if (!$scope.isItemSelected(object)) {
                $scope.selectedItems.push(object);
            } else {
                for (var i = 0; i < $scope.selectedItems.length; i++) {
                    if ($scope.selectedItems[i].Guid === object.Guid) {
                        $scope.selectedItems.splice(i, 1);
                        return;
                    }
                }
            }
        };

        $scope.isItemSelected = function (object) {
            return _.find($scope.selectedItems, function (o) { return o.Guid === object.Guid; });
        };

        var getEntityMapping = function (entity) {
            var newEntity = { GuidPeriodicTask: entity.Guid, GuidControlList: modalParams.guid };
            switch ($scope.selectedEntityType) {
                case 'Equipment':
                    newEntity.GuidEquipment = entity.GuidEquipment;
                    break;
                case 'Area':
                    newEntity.GuidArea = entity.GuidArea;
                    break;
            }
            return newEntity;
        };

        $scope.save = function () {
            if (!$scope.selectedEntityType) return;

            if ($scope.selectedItems.length > 0) {
                var model = [];

                $scope.selectedItems.forEach(function (item) {
                    model.push(getEntityMapping(item));
                });

                repository.createMultiple(repository.apiData.controlListXEntity.url, model).then(function () {
                    repository.growl(translationService.translate('web-controlList-addMultiple-success', 'Kontrollistene har blitt lagt til'), "success");
                    $modalInstance.close('OK');
                },
                    function (error) {
                        repository.growl(error, 'danger');
                    });
            }
        };

        $scope.$watch('selectedEntityType', function (newValue, oldValue) {
            if (newValue === oldValue) return;

            if (newValue) {
                $scope.itemTable.reload();
                $scope.selectedItems = [];
            }
        });
    }
})();
