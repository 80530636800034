(function () {
	angular.module('Plania').directive('telerik', ['$injector', '$timeout', function ($injector, $timeout) {
		return {
			restrict: 'E',
			replace: true,
			scope: {
				report: '=',
				reportParams: '=',
				printReport: '=',
				showToolbar: '=',
				control: '='
			},
			link: function link(scope, element, attrs) {
				scope.internalControl = scope.control || {};
				scope.reportId = "reportViewer" + Math.random().toString(32).substring(2, 10);
				var authService = $injector.get('authService');
				var accessToken = authService.getPlaniaToken().access;
				var isFirstRenderingEnd = true;
				var reportViewer;

				var kwidgetElement = function () {
					return $(element[0]).find('.k-widget');
				};

				var commonService = $injector.get('CommonService');
				var localStorage = $injector.get('$localStorage');
				var telerikService = $injector.get('TelerikService');
				var dataOwner = commonService.getFilterData().selectedDataOwner.Guid;

				if (telerikService && telerikService.setTelerikLanguage)
					telerikService.setTelerikLanguage();

				var getParams = function () {
					var params = scope.reportParams || {};
					params.GuidBuildingSelection = commonService.getFilterData().selectedSelection.Guid;
					params.GuidBuilding = commonService.getFilterData().selectedBuilding.Guid;
					params.GuidActiveUser = scope.$root.userInfo.guidPerson;
					params.GuidUser = scope.$root.userInfo.guidUser;
					params.GuidDataOwner = commonService.getFilterData().selectedDataOwner.Guid;
					params.Locale = localStorage.locale || 'nb-no';
					params.GuidEstate = commonService.getFilterData().selectedEstate.Guid;

					return params;
				};

				var alreadyPrinted = false;

				var refreshReport = function () {
					var rs = reportViewer.reportSource();
					rs.parameters = getParams();
					rs.report = scope.report;

					reportViewer.reportSource(rs);
					reportViewer.refreshReport();
				};

				var setFilter = function () {
					accessToken = authService.getPlaniaToken().access;
					if (!accessToken || accessToken.indexOf('NTLM') !== -1) {
						setTimeout(setFilter, 100);
						return;
					}

					jQuery.ajaxPrefilter(function (options, originalOptions, jqXhr) {
						jqXhr.setRequestHeader("Authorization", accessToken);
						if (options.url.indexOf('dataOwner=') === -1)
							options.url += '?dataOwner=' + dataOwner;

					});
				};

				setFilter();

				scope.$on('newDataOwner', function () {
					refreshReport();
				});

				scope.internalControl.refresh = function () {
					refreshReport();
				};

				scope.internalControl.toggleFilters = function () {
					reportViewer.commands.toggleParametersArea.exec();
				};

				scope.internalControl.toggleZoomMode = function () {
					reportViewer.commands.toggleZoomMode.exec();
				};

				scope.internalControl.getReportId = function () {
					return scope.reportId;
				};

				scope.$watch('report', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					refreshReport();
				});

				var printOptions = {
					serviceUrl: "/api/api/reports/",
					templateUrl: '/scripts/telerik/templates/telerikReportViewerTemplate-FA.html',
					reportSource: {
						report: scope.report + ".trdx",
						parameters: getParams()
					},
					persistSession: false,
					parametersAreaVisible: false, // Hide parameters area on load, but re-init when rendering is done.
					ready: function () {
						if (scope.showToolbar === false) {
							$('.trv-nav').css('display', 'none');
							$('.trv-content').css('top', '0');
						}
					},
					renderingEnd: function (e, args) {
						// Do not show parameters area on first load.
						if (isFirstRenderingEnd) {
							this.parametersAreaVisible(true);
							// Parameters area is default visible. Toggle to hide.
							this.commands.toggleParametersArea.exec();
							isFirstRenderingEnd = false;
						}


						if (scope.printReport && !alreadyPrinted) {
							this.commands.print.exec();
							alreadyPrinted = true;
						}
					},
				};

				if (scope.printReport) {
					printOptions.scaleMode = 0;
					printOptions.viewModel = telerikReportViewer.ViewModes.PRINT_PREVIEW;
				} else {
					if (scope.showToolbar !== false)
						printOptions.scaleMode = 1;
				}
				//force view to load reportId before we're attaching telerik to the dom element
				$timeout(function () {
					kwidgetElement().telerik_ReportViewer(printOptions);
					reportViewer = kwidgetElement().data("telerik_ReportViewer");

					element.on('load',
						function() {
							var height = document.getElementsByTagName("html")[0].scrollHeight / 2 + "px ";
							element.height(height);
						});
				}, 10);
			},
			templateUrl: '/app/common/directives/views/telerik.html'
		};
	}]);
})();
