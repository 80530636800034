(function() {
    angular.module('Plania').factory('MenuService', ['Repository', 
        function service(repository) {

            var publicMember = {};

            publicMember.getShortcuts = function() {
	            return repository.getShortcuts();
            };

            return publicMember;
        }
    ]);
})();
