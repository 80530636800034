(function () {
	var app = angular.module('Plania');

	app.directive('nonsReference', function () {
		return {
			restrict: 'E',
			scope: {
				entity: '=',
				entityModel: '=',
			},
			controller: ['$scope', '$modal', 'Repository', 'TranslationService', '$rootScope', '$window', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/planiaNonsReferenceDirective.html'
		};
	});

	function controller($scope, $modal, repository, translationService, $rootScope, $window) {
		$scope.model = {};
		$scope.directiveReady = false; // ready when we can decide if nonsReference is loaded or is missing
		$scope.hasModel = false;
		$scope.hasReadAccess = $rootScope.hasReadAccess('NonsReference');
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess('NonsReference');
		$scope.hasCreateAccess = $rootScope.hasCreateAccess('NonsReference');

		if (!$scope.hasReadAccess) {
			// Make sure we don't attempt to load anything if we don't have access
			$scope.directiveReady = true;
			return;
		}

		var getNonsReference = function (guidNonsReference) {
			repository.getMainModel(repository.apiData.nonsReference.url, guidNonsReference).then(function (response) {
				$scope.model = response.Data;
				$scope.directiveReady = true;
				$scope.hasModel = true;

			}, function (error) {
					repository.growl(error, 'danger');
				$scope.directiveReady = true;
				$scope.hasModel = false;
				});
		};

		$scope.$watch('entityModel', function (newValue, oldValue) {
			if (!newValue) return;
			if (Object.keys(newValue).length === 1) return; // entity is not loaded, only Guid

			if (newValue.GuidNonsReference) {
				getNonsReference(newValue.GuidNonsReference);
			}
			else {
				$scope.directiveReady = true;
				$scope.hasModel = false;
			}
		});

		$scope.addNewReference = function () {
			$scope.model.GuidEntity = $scope.entityModel.Guid;
			$scope.model.EntityType = $scope.entity;

			$modal.open({
				controller: 'NonsReferenceModal',
				templateUrl: 'app/common/directives/views/nonsReferenceModal.html',
				resolve: {
					params: function () {
						return {
							model: $scope.model
						};
					}
				}
			}).result.then(function (savedModel) {
				$scope.hasModel = true;
				$scope.model = angular.copy(savedModel);
				// If 1-1 relationship, update reference directly in UI (avoids reloading the whole entityModel from backend)
				$scope.entityModel.GuidNonsReference = $scope.model.Guid;
			});
		};

		$scope.updateReference = function () {
			$modal.open({
				controller: 'NonsReferenceModal',
				templateUrl: 'app/common/directives/views/nonsReferenceModal.html',
				resolve: {
					params: function () {
						return {
							model: $scope.model
						};
					}
				}
			}).result.then(function (savedModel) {
				$scope.model = angular.copy(savedModel); 
			});
		};

		$scope.deleteReference = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-nonsReference-message', 'Nons referanse vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-nonsReference-button-confirm', 'Ja, fjern referansen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.nonsReference.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-supplier-nonsReference-success', 'Referansen ble fjernet!'), result, "success");
					$scope.hasModel = false;
					$scope.model = {};
					// If 1-1 relationship, update reference directly in UI (avoids reloading the whole entityModel from backend)
					$scope.entityModel.GuidNonsReference = null;
				});
			});
		};
	}

	angular.module('Plania').controller('NonsReferenceModal', ['$scope', 'Repository', '$modalInstance', 'params', 'TranslationService', function ($scope, repository, $modalInstance, params, translationService) {
		
		$scope.model = angular.copy(params.model); 
		$scope.ok = function () {
			if ($scope.model.Guid) {
				repository.updateSingle(repository.apiData.nonsReference.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-nonsReference-updated', 'Nons reference oppdatert'), 'success');
					$modalInstance.close(result);
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingle(repository.apiData.nonsReference.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-nonsReference-created', "Nons reference ble lagt til"), 'success');
					$modalInstance.close(result);
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}]);
})();
