(function () {

	var app = angular.module('Plania');

	app.directive('childEntities', function () {
		return {
			restrict: 'E',
			scope: {
				parent: '=parent', //The entity to filter child entities on
				guidColumn: '=guidColumn', //The name of the guid column on the child entity to filter on
				entity: '=entity', //The entity of child table
				columns: '=columns', //The columns to show of the child element
				sortColumn: "=sortColumn",//Sort column
				sortDirection: "=sortDirection", //sort direction
				onClick: "=onClick", //override click handler
				createEntity: "=createEntity", //optional override of createEntity function (prefill etc)
				disableAdd: "=disableAdd", //optional override of createEntity function (prefill etc)
				totalCount: "=totalCount", //optional, will be set with number of items in order to enable/disable based on data (used in periodic task disabling equipment when a child WO exists)
				addEntity: '=addEntity', //optional method to allow adding existing objects, there is no default implementation yet and the button is hidden when this is null/undefined.
				reloadTable: '=reloadTable', //optional boolean to allow fetching all data for each object.
				useContextMenu: '=useContextMenu', //optional boolean to allow for contextmenu to be used for normal listview
				contextMenuOptions: '=contextMenuOptions', //optional override for contextMenuOptions
			},
			controller: ['$scope', 'Repository', 'TranslationService', 'NgTableParams', 'ListService', '$rootScope', controller],
			templateUrl: 'app/common/directives/views/childEntities.html'
		};
	});

	function controller(scope, repository, translationService, ngTableParams, listService, $rootScope) {
		scope.model = {};

		scope.titleSystemName = 'web-childEntities-' + scope.entity + '-title';
		scope.titleDefaultName = translationService.translate('web-' + scope.entity, scope.entity).replace(':', '');

		scope.search = {};
		scope.collapse = { isEntitiesCollapsed: false };
		scope.hasCreatePermission = repository.authService.hasCreateAccess(scope.entity);
		scope.translatedEntity = translationService.translate('web-' + scope.entity, scope.entity).toLowerCase();

		var filter = { searchString: '' };
		scope.totalCount = {
			total: 0
		};

		scope.bools = {
			showTreeView: false
		};

		if (scope.entity === 'Equipment') scope.bools.showTreeView = true;

		var apiData = _.find(repository.apiData, function (o) { return o.prefix === scope.entity; });

		if (!scope.columns) {
			scope.columns = [
				{ Title: 'Id', Property: 'Id' },
				{ Title: 'Beskrivelse', Property: 'Description' }
			];
		}

		var sorting = {};
		if (!scope.sortDirection && (scope.sortDirection !== 'asc' || scope.sortDirection !== 'desc'))
			scope.sortDirection = 'asc';

		if (!scope.sortColumn) {
			sorting = { Id: scope.sortDirection };
		} else {
			sorting[scope.sortColumn] = scope.sortDirection;
		}


		scope.getPropertyValue = function (column, row) {
			return listService.GetPropertyValue(column, row);
		};

		scope.goToEdit = function (entity, event) {
			if (scope.onClick)
				scope.onClick(entity, event);
			else
				scope.$root.go(apiData.navigationState, { guid: entity.Guid }, event);
		};

		scope.createNewEntity = function () {
			if (scope.createEntity && typeof (scope.createEntity) === 'function') {
				scope.createEntity();
			} else {
				var persistedData = {};

				persistedData[scope.guidColumn] = scope.parent.Guid;
				persistedData[scope.guidColumn.replace('Guid', '')] =
					scope.parent.Id + ' - ' + scope.parent.Description;

				if (scope.parent.Building && scope.parent.GuidBuilding) {
					persistedData.GuidBuilding = scope.parent.GuidBuilding;
					persistedData.Building = scope.parent.Building.Id + ' - ' + scope.parent.Building.Description;
				}

				repository.persistedData.setPersistedData(scope.entity.toLowerCase() + '.create', persistedData);

				scope.$parent.navigation.go(apiData.navigationState.replace('.edit', '.create'),
					{ showPersistantData: true },
					{ reload: true });
			}
		};

		scope.addExistingEntity = function () {
			if (scope.addEntity && typeof (scope.addEntity) === 'function')
				scope.addEntity(scope.entityTable.data);
		};

		filter.PropertyFilter = [{ Property: scope.guidColumn, Operator: '=', Value: scope.parent.Guid }];
		if (scope.entity === repository.commonService.prefix.WorkOrder)
			filter.ActivityStatus = 'Undefined';

		scope.entityTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: sorting,
			filter: filter
		}, {
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 50,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					scope.entitiesAreLoading = true;

					var columns = [];
					scope.columns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(apiData.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns))
						.then(function (result) {
							scope.entityTable.settings().total = result.TotalCount;
							scope.entityTable.settings().filterDelay = 500;
							scope.totalCount = result.TotalCount;
							if (!scope.entities)
								scope.entities = [];

							$defer.resolve(result.List);
							scope.entitiesAreLoading = false;

						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		if (scope.entity === repository.commonService.prefix.WorkOrder) {
			scope.showFilterDropdown = true;
			scope.filterTypes = [
				{ value: '-1', name: translationService.translate('web-workorder-filterType-all', 'Alle') },
				{ value: '0', name: translationService.translate('web-workorder-filterType-project', 'Prosjekt') },
				{ value: '1', name: translationService.translate('web-workorder-filterType-open', 'Aktive') },
				{ value: '2', name: translationService.translate('web-workorder-filterType-closed', 'Historiske') }
			];

			scope.setFilter = function (filter) {
				scope.selectedFilter = filter;
				var propertyFilter = scope.entityTable.filter().PropertyFilter;

				if (propertyFilter) {
					_.remove(propertyFilter, function (obj) { return obj.Property === 'Stage'; });
				} else {
					propertyFilter = [];
				}

				if (filter.value >= 0) {
					propertyFilter.push({ Property: 'Stage', Operator: '=', Value: filter.value });
				}
				scope.entityTable.filter().PropertyFilter = propertyFilter;
			};

			scope.setFilter(scope.filterTypes[0]);
		}

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="fas' + icon + ' fa-hc-fw fa-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		scope.childContextMenuOptions = function (entity) {
			var options = [];
			if (scope.contextMenuOptions)
				return scope.contextMenuOptions(entity);

			options.push(getMenuLink($rootScope.navigation.href(apiData.navigationState, { guid: entity.Guid }), 'fa-eye', translationService.translate('web-childEntities-contextMenu-open', 'Åpne')));

			return options;
		};

		scope.$watch('reloadTable', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			scope.entityTable.reload();
		});
	}
})();
