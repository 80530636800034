(function () {
	angular.module('Plania').controller('ContractController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', '$localStorage', 'ListService', '$sce', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, $localStorage, listService) {
		$scope.isUpdate = RegExp("edit$").test($scope.navigation.current.name);
		$scope.showCustomer = true;
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;
		$scope.validCharacters = '^(?!.*<[a-zA-ZæøåÆØÅ]+).*';

		$scope.isDocumentsCollapsed = true;
		$scope.reloadDocumentTable = false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Contract, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Contract, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Contract);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Contract);
			}
		};

		updateAccess();

		var contractColumns = [
			'Invoicing.Id',
			'Department.Id', 'Department.Description'
		];

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.contractItemColumns = [
			{ Position: 1, Title: translationService.translate('web-contractItem-Id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-contractItem-Description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-contractItem-Quantity'), Property: 'Quantity', PropertyType: 'number' },
			{ Position: 4, Title: translationService.translate('web-contractItem-UnitPrice'), Property: 'UnitPrice', PropertyType: 'number' },
			{ Position: 4, Title: translationService.translate('web-contractItem-article-type'), Property: 'Article.Type', PropertyType: 'enum' }
		];

		var getCardTables = function () {
			$scope.contractItemTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { 'Id': 'asc' },
					filter: { PropertyFilter: [{ Property: 'GuidContract', Operator: '=', Value: $scope.model.Guid }] }
				},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.contractItemColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.contractItem.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.contractItemTable.settings().total = result.TotalCount;
								$scope.contractItemTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});

		};

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.contract.url, $stateParams.guid, JSON.stringify(contractColumns)).then(
				function (response) {
					var responseData = response.Data;
					$scope.model = responseData;
					//$scope.showDynamicProperties = $scope.model.DynamicProperty && $scope.model.DynamicProperty.Groups.length > 0;
					$scope.reloadDocumentTable = true;
					updateAccess();
					getCardTables();

					$scope.reload = true;
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		}
		else {
			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('contract.create');

				if (persistentData) {
					$scope.model = persistentData.model || {};

				}
				$scope.model.RentalType = 2;
			}
			$scope.activateAutocomplete = true;
			$scope.model.RentalType = 2;
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? "Kontrakt ble oppdatert" : 'Kontrakt ble lagret', 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				if (destination === 'reinvoicingContract.create') {
					$scope.navigation.go(destination, { reload: true });
				} else {
					repository.persistedData.clearPersistedData();
					$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.contract.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingle(repository.apiData.contract.url, $scope.model).then(success).catch(error);
			}
		};

		$scope.cancel = function () {
			$scope.goBack('reinvoicingContract.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.addContractItem = function () {

			$modal.open({
				templateUrl: 'app/contract/views/contractItemModal.html',
				controller: 'ContractItemModalController',
				size: 'md',
				resolve: {
					params: function () {
						return {
							contractItem: {
								GuidContract: $scope.model.Guid,
								GuidDataOwner: repository.commonService.getFilterData().selectedDataOwner.Guid,
								Quantity: 1
							}
						};
					}
				}
			}).result.then(function () {
				$scope.contractItemTable.reload();
			});
		};

		$scope.removeConctractItem = function (item) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-contract-contract-item-remove',
					"Kontraktsobjektet vil bli fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-contract-contract-item-confirm',
					'Ja, fjern kontraktsobjektet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			},
				function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.contractItem.url, item.Guid)
						.then(function (result) {
							swal('Suksess!',
								translationService.translate('web-swal-contract-contract-item-success',
									'Kontraktsobjektet ble fjernet!'),
								"success");
							$scope.contractItemTable.reload();
						});
				});
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "contractCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
		};

		$scope.updateContractItem = function (guid) {
			$modal.open({
				templateUrl: 'app/contract/views/contractItemModal.html',
				controller: 'ContractItemModalController',
				size: 'md',
				resolve: {
					params: function () {
						return {
							Guid: guid
						};
					}
				}
			}).result.then(function () {
				$scope.contractItemTable.reload();
			});
		};
	}

})();
